import React from 'react';
import { v4 } from 'uuid';

// Components
import Title from '../shared/Title';
import Image from '../shared/Image';
import Video from '../shared/Video';

// Icon 
import ellipseIcon from '../../assets/icons/ellipse.svg';

const DesignService = ({data, ...rest}) => {
    return (
        <div className='flex flex-col md:flex-row justify-center'>
            <section className='h-fit md:sticky md:top-28 space-y-4 mb-6'>
                <Title type='normal' size="sm">
                    {data.title}
                </Title>
                <p className='text-base sx:text-lg text-neutral-light-600'>
                    {data.description}
                </p>
                <ul className='mb-8 space-y-4'>
                {
                    data.items.map(item => {
                        return (
                            <li key={v4()} className="flex items-center gap-x-2">
                                <img src={ellipseIcon} alt='ellipse' />
                                <span className='text-base text-white xs:text-lg font-semibold'>{item}</span>
                            </li>
                            )
                        })
                }
                </ul>
            </section>
            <section className='w-screen flex flex-col items-end'>
                {
                    data.medias.map((media, key) => {
                        if ( key % 2 === 0 ) {
                            return (
                                media.type === 'img' ?
                                <Image className='' src={media.file} alt={media.alt} key={key}/> :
                                <div className='md:min-h-[462px] md:min-w-[600px]' key={key}>
                                    <Video videoSrc={media.file}/>
                                </div>
                                )
                            }else {
                                return (
                                media.type === 'img' ?
                                <Image className='ml-8' src={media.file} alt={media.alt} key={key}/> :
                                <div className='md:min-h-[462px] md:min-w-[600px]' key={key}>
                                    <Video videoSrc={media.file}/>
                                </div>
                                
                            )
                        }
                    })
                }
            </section>
        </div>
    );
};

export default DesignService;