import React from "react";
import styled from "styled-components";

// Components
import AccordionButton from "./AccordionButton";

const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    width: 100%;
    height: auto;
    padding: 1rem 0;
    background-color: transparent;
`;

const AccordionHeader = ({ children, className, state, ...other }) => {

    return (
        <Div className={className ? className : ""} {...other}>
            <span onClick={state.setIsExpanded} className="max-w-[600px]">
                {children}
            </span>
            <AccordionButton isExpanded={state.isExpanded} toggleExpand={state.setIsExpanded} />
        </Div>
    )
};

export default AccordionHeader;