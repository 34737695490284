import React from 'react';
import Title from '../shared/Title';
import YearsAnimation from './YearsAnimation';
import Image from '../shared/Image';

import blurEllipse from '../../assets/imgs/blurEllipseRight.svg';
import personImg from '../../assets/imgs/about-us/person.webp';

const AboutBackground = () => {
    return (
        <div className='content-section'>
            <img className='absolute -right-0 -top-1/2 opacity-60' src={blurEllipse} alt='ellipse' />
            <div className='flex items-start justify-between w-full max-w-5xl mt-20'>
                <section>
                    <Title type='normal' size='md' headTagType='h2'>
                        since
                    </Title>
                    <Title className='leading-6 md:leading-[4.5rem] lg:leading-10' type='stroke' size='lg' headTagType='h2'>
                        2019
                    </Title>
                    <section className='w-full max-w-lg mt-16'>
                        <Title type='normal' size='sm' headTagType='h2'>
                            New Office, More Team Members with Same Approach
                        </Title>
                        <article className='text-neutral-light-600 text-base'>
                            After tirelessly working for 10 Years we have grown bigger with our clients, the way we work and the way we execute projects for our clients.
                        </article>
                    </section>
                </section>
                <section className='hidden md:block'>
                    <YearsAnimation year='5' />
                </section>
            </div>
            <div className='w-full max-w-5xl mt-12'>
                <Title type='golden' size='xs' headTagType='h3'>
                    What we do
                </Title>
                <Title type='normal' size='sm' headTagType='h3'>
                    brain behind FLLTECH
                </Title>
                <section className='flex flex-col md:flex-row items-start md:items-center flex-wrap mt-4'>
                    <Image className='w-full max-w-[350px]' src={personImg} alt='person' />
                    <article className='flex-1 mt-8 md:mt-0 md:ml-32 text-neutral-light-600 max-w-md'>
                        <Title type='normal' size='sm' headTagType='h3'>
                            Haybert shahbazian
                        </Title>
                        <h6 className='my-4'>
                            Founder & Director of FLLTECH
                        </h6>
                        <p>
                            At FLLTECH, we believe in enjoying each and every moment of life. We are enjoying freedom of speech, expressions, emotions, thoughts and most importantly sharing fun. Therefore, our life at MI is full of hustle-bustle, collisions and recreation at all level of personal and professional aspects.
                        </p>
                    </article>
                </section>
            </div>
        </div>
    );
};

export default AboutBackground;