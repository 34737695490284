import pythonIcon from '../assets/icons/technologies/python.svg';
import phpIcon from '../assets/icons/technologies/php.svg';
import reactIcon from '../assets/icons/technologies/react.svg';
import firebaseIcon from '../assets/icons/technologies/firebase.svg';
import solidityIcon from '../assets/icons/technologies/solidity.svg';
import goIcon from '../assets/icons/technologies/go.svg';

export const trendTechData = () => {
    const data = [
        {
            "label": "python",
            "icon": pythonIcon,
        },
        {
            "label": "php",
            "icon": phpIcon
        },
        {
            "label": "react",
            "icon": reactIcon,
        },
        {
            "label": "firebase",
            "icon": firebaseIcon,
        },
        {
            "label": "solidity",
            "icon": solidityIcon,
        },
        {
            "label": "go",
            "icon": goIcon,
        },
    ]
    return data

}