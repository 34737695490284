// Images
import timematerialImg from '../assets/imgs/business-model/time-&-material.svg';
import hireTeamImg from '../assets/imgs/business-model/hire-team.svg';
import fixScopeImg from '../assets/imgs/business-model/fix-scope-model.svg';

export const tabData = () => {
    const data = [
        {
            'id': 't1',
            'title': 'Time & Material',
            'img': timematerialImg,
            'description': 'We welcome the obscure vision through Time and Material Model that supports the Agile Development Process. This is a model where the client only pays for the time and resources spent on the project.'
        },
        {
            'id': 't2',
            'title': 'Fix Scope Model',
            'img': fixScopeImg,
            'description': 'We welcome the crystal clear vision of our clients and provide the best web and mobile app solutions through Fix Scope Model. Fix Scope Model is the best model to get the complete solution under a fixed budget.'
        },
        {
            'id': 't3',
            'title': 'Hire Team',
            'img': hireTeamImg,
            'description': 'The Extended Team is a model where the client can remotely control the entire development team. It is cost-effective as the whole team is hired remotely and does not require investment in the infrastructure; still, they have control and coordination with the team. The best part is the client hires them on contract only after all the due diligence and can relieve them upon completion of the project or modules.'
        },
    ]
    return data
}