import React from 'react';
import Title from '../shared/Title';
import setupImg from '../../assets/imgs/about-us/desk-setup.webp'
import Button from '../shared/Button';
import arrowRight from '../../assets/icons/arrow-right.svg';

const Culture = () => {
    return (
        <div className='content-section mt-40'>
            <div className='w-full max-w-5xl'>
                <Title className='leading-[4rem] md:leading-[6rem]' type='stroke' size='lg' headTagType='h3'>
                    LIFE & CULTURE
                </Title>
                <Title className='text-secondary-500 font-bold' size='md' headTagType='h3'>
                    @FLLTECH
                </Title>
                <div className='w-full flex justify-end mt-12 sm:mt-48 lg:mt-24'>
                    <div className='relative w-full max-w-md h-[250px] border border-white pl-16 pr-6'>
                    <img className='hidden sm:block absolute -top-2/3 -left-1/3' src={setupImg} alt='setup' loading='lazy' />
                        <section className='flex flex-col items-start justify-center gap-y-3 h-full'>
                                <Title type='normal' size='sm' headTagType='h4'>
                                    Join Our Team Today
                                </Title>
                                <p className='text-neutral-light-600'>Send us your CV and Grab The Opportunity</p>
                                <Button Btype='basic' size='lg'>
                                    <span>Explore Vacancies</span>
                                    <img className='w-5 ml-2' src={arrowRight} alt='arrow' />
                                </Button>
                        </section>
                    </div>
                </div>
            </div>
            <Title className='w-full max-w-lg text-center mt-12' type='normal' size='sm' headTagType='h4'>
                Meet our people, and join our celebrations, Festivals, exhibitions & More!!!
                <br />
                Want To Be A Part Of All?
                <br />
                Subscribe!
            </Title>
        </div>
    );
};

export default Culture;