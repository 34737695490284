import React from 'react';

// Components
import ContactForm from '../forms/ContactForm';
import Title from '../shared/Title';

// Icons
import callIcon from '../../assets/icons/contact/call.svg';
import directIcon from '../../assets/icons/contact/direct.svg';

const ContactSection = () => {
    return (
        <div className='content-section'>
            <div className='w-full max-w-7xl'>
                <div className='flex flex-col md:flex-row md:items-center md:justify-between'>
                    <section className='relative w-max mr-auto md:mr-0'>
                        <Title className='absolute -top-3 sm:-top-4 md:-top-7 -translate-y-1/2' type='normal' size='md' headTagType='h3'>
                            say
                        </Title>
                        <Title type='stroke' size='xl' headTagType='h3'>
                            hello!
                        </Title>
                    </section>
                    <ContactForm />
                </div>
                <ul className='flex my-4 py-3 gap-8 flex-wrap items-start'>
                    <li className='space-y-4'>
                        <a href='mailto:info@flltech.io' className='contact-section--navlink' target='_blank' rel='noreferrer'>
                            <img src={directIcon} alt='direct' />
                            info@flltech.io
                        </a>
                        <a href='mailto:sales@flltech.io' className='contact-section--navlink' target='_blank' rel='noreferrer'>
                            <img src={directIcon} alt='direct' />
                            sales@flltech.io
                        </a>
                    </li>
                    <li className='space-y-4'>
                        <a href="tel:0037493687737" className='contact-section--navlink'>
                            <img src={callIcon} alt='call' />
                            +374-93 687 737
                        </a>
                        <a href="tel:0037441144143" className='contact-section--navlink'>
                            <img src={callIcon} alt='call' />
                            +374-41 144 143
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ContactSection;