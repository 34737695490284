// Images
import arvrImg from '../../assets/imgs/game/ar-vr.webp';
import joystick3dImg from '../../assets/imgs/game/3D-joystick.webp';
import firstProjectGameImg from '../../assets/imgs/game/project-game1.webp';
import secondProjectGameImg from '../../assets/imgs/game/project-game2.webp';

export const gameCards = () => {
    const data = [
        {
            'title': 'AR/VR Game Development',
            'subtitle': '',
            'img': arvrImg,
            'description': 'Augmented Reality (AR) and Virtual Reality (VR) hold a immense potential to make awestruck experiences in the world of gaming and entertainment. FLLTech offers unique and creative AR/VR game development services for multiple platforms and in various genres of games to bring your creative thoughts and visions to life.'
        },
        {
            'title': 'Unity 3D Game Developent',
            'img': joystick3dImg,
            'subtitle': '',
            'description': 'If you are seeking a professional unity game development company to work on your project, you are at the right place! Our expert team of Unity 3D game developers build games that are not only high-performing but also provide a great user experience.'
        },
    ]
    return data
}

export const projectGame = () => {
    const data = [
        {
            'title': '',
            'subtitle': 'Arcade Games',
            'img': firstProjectGameImg,
            'description': 'UI/UX Design, Game Development'
        },
        {
            'title': '',
            'subtitle': 'Retro Games',
            'img': secondProjectGameImg,
            'description': 'UI/UX Design, Game Development'
        }
    ]
    return data;
}