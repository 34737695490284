export const getDigitalApproachData = () => {
    const data = [
        {
            title: "Public Relations (PR)",
            description: "FLLTech PR team helps set techniques and strategies related to managing how information about the company is disseminated to the public on the media. We consult you on how to share important company news or events, maintain a brand image, and put a positive spin on adverse events to minimize their fallout. We protect the company’s image in press releases, news conferences, interviews with journalists, social media postings, or promotions by influencers. We bring trust to your brand by showing loyalty and support of older users to the new ones. We also set up an automatic answering system to increase the responding pace to all users on 7/24.",
        },
        {
            title: "UI/UX, Theme, Logo & Character Design",
            description: "Owning a unique character and logo and designing a customized theme are the first steps of successful branding. It helps you offer an unforgettable experience to your users by showing them a professional and unique image of your brand. Also, FLLTech UI/UX designers make sure that the components in a user interface are consistent and uniform in all aspects, such as content design, readability, typography, mobile-friendly website or Apps, optimized load time, etc. to ensure there are fewer chances that the clients will find any issues with your website or mobile app.",
        },
        {
            title: "Advertising & E-Mail Marketing",
            description: "There are various ways of effective advertising which FLLTech can help you with. Still, it’s essential to choose the most reputable and trusted sources to promote your company, product, or service to obtain the best result. FLLTech links you with the most popular websites for advertising your product. Also, we help you with email marketing using the most-related E-mail sources for advertising, announcements, and event notifying.",
        },
        {
            title: "Analytics & Reports",
            description: "All marketing efforts need a final report to make us sure about the integrity of the way we came through. It helps us determine if our method is right or wrong or if any changes are needed in our strategies. Marketing strategies need to be flexible due to market analytics and results to carry us to success eventually. They help us find our weaknesses to be fixed. FLLTech uses modern and practical methods to obtain the most accurate analytics and elevate your brand to the next level.",
        },
        {
            title: "Social Media Marketing (SMM)",
            description: "Recently, social media has proved itself as the best way to increase brand awareness and user interaction. No one can ignore the critical role of presence in this expansive space as a brand. In addition, we should consider the impact that influencers have on the majority of society to benefit from this opportunity to get the best results in branding. FLLTech has gathered a team of social media experts and analysts to find the best-suited method for your presence in the best related social media platform",
        },
        {
            title: "Community Growing",
            description: "We help you build a loyal real social media community in the first place. If you already have it, we offer you some methods to reach it bigger by identifying most related accounts, producing high-quality content, developing a clear brand voice on multiple platforms to drive traffic, and other kinds of social marketing strategies such as contests to bring up more followers, views & shares. FLLTech social media experts help you stay consistent to boost engagement. Also, it’s essential to listen to the audience’s feedback. Accordingly, we set up a fully automated method to respond to your audience quickly and bring more satisfaction and trust to the community.",
        },
    ]
    return data;
}