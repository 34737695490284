import gng from '../../assets/imgs/portfolio/web/gng.webp';
import metabix from '../../assets/imgs/portfolio/web/metabix.webp';
import Cryptizi from '../../assets/imgs/portfolio/web/cryptizi.webp';
import shahsaray from '../../assets/imgs/portfolio/web/shahsaray.webp';
import gbstorage from '../../assets/imgs/portfolio/web/gbstorage.webp';
import bempire from '../../assets/imgs/portfolio/web/bempire.webp';
import nfc from '../../assets/imgs/portfolio/web/nfc.webp';
import bemgrid from '../../assets/imgs/portfolio/web/bemgrid.webp';
import vexpo from '../../assets/imgs/portfolio/web/vexpo.webp';
import trainet from '../../assets/imgs/portfolio/web/trainet.webp';
import resturant from '../../assets/imgs/portfolio/web/resturant.webp';
import easychange from '../../assets/imgs/portfolio/web/easychange.webp';
import bestchanger from '../../assets/imgs/portfolio/web/bestchanger.webp';
import snakerider from '../../assets/imgs/portfolio/design/snakerider.webp';
import balapain from '../../assets/imgs/portfolio/web/balapain.webp';
import shahapolymer from '../../assets/imgs/portfolio/web/shahpolymer.webp';

import unityIcon from '../../assets/icons/services/unity.svg';
import figmaIcon from '../../assets/icons/services/figma-2.svg';
import htmlIcon from '../../assets/icons/services/html.svg';
import jsIcon from '../../assets/icons/services/js.svg';
import phpIcon from '../../assets/icons/services/php.png';
import reactIcon from '../../assets/icons/services/react.svg';
import laravelIcon from '../../assets/icons/services/laravel.svg';
import androidIcon from '../../assets/icons/services/android-logo.svg';
import appleIcon from '../../assets/icons/services/apple-logo.svg';
import djangoIcon from '../../assets/icons/technologies/django.png';
import pythonIcon from '../../assets/icons/technologies/python.svg';

export const getWebPorfolioData = () => {
    const data = [
        {
            title: "Gamengain (GNG)",
            tags: ["Design", "Frontend", "Backend",],
            description: "This project has been done by FLLTech developers for client who was looking for a Gaming cryptocurrency landing page with gamengain name this crypto token  provide many P2E  blockchain base games",
            icons: [
                { "icon": figmaIcon, },
                { "icon": htmlIcon, },
                { "icon": jsIcon, },
                { "icon": phpIcon, },
            ],
            image: gng,
            link: "https://gamengain.io/"
        },
        {
            title: "Metabix",
            tags: ["Design", "Frontend", "Backend",],
            description: "This project has been done by FLLTECH developers for client who was looking unique Website for Metavers, this project name is Metabix. this web is for Metaverse project anyone can buy avatars,lands,assets  in this project and play P2E in this project",
            icons: [
                { "icon": figmaIcon, },
                { "icon": reactIcon, },
                { "icon": htmlIcon, },
                { "icon": jsIcon, },
            ],
            image: metabix,
            link: "https://metabix.io/"
        },
        {
            title: "Cryptizi",
            tags: ["Design", "Frontend", "Backend",],
            description: "Cryptizi is a Exchange for crypto currency. It is easy to use and safe for users. It gives the users opportunity to keep their crypto in the wallet on the exchange too. Cryptizi exchange is a platform for buying and selling cryptocurrencies. In addition to trading services, Cryptizi exchanges also offer price discovery through trading activity, as well as storage for crypto.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": reactIcon, },
                { "icon": jsIcon, },
                { "icon": phpIcon, },
                { "icon": pythonIcon, },
                { "icon": djangoIcon, },
            ],
            image: Cryptizi,
            link: "https://www.Cryptizi.com/"
        },
        {
            title: "Shahsaray",
            tags: ["Design", "Frontend", "Backend",],
            description: "This project has been done by FLLTECH developers for client who was looking Real estate websites,Real estate websites bring convenience to buyers and exposure to sellers. Buyers can view hundreds of property images, videos, and features before they ever need to schedule a live tour. Sellers who post their homes on these sites benefit for the same reason; hundreds or thousands of prospective buyers can view their home online without needing to walk through.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": jsIcon, },
                { "icon": htmlIcon, },
                { "icon": phpIcon, },
            ],
            image: shahsaray,
            link: "https://shahsaray.com/"
        },
        {
            title: "GB Storage",
            tags: ["Backend", "Frontend", "Design",],
            description: "GB storage is a platform that you can save and keep your digital belongings such as music, photos, files. This platform is very useful for users nowadays, because as technology develops users needs more place to keep their files.In the same time this storage platform is very safe and friendly to use. The landing page contain 3D animation and it's easy to sign up and start using your own storage and send it and access it anytime.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": htmlIcon, },
                { "icon": jsIcon, },
                { "icon": phpIcon, },
            ],
            image: gbstorage,
            link: "https://gbstorage.co/"
        },
        {
            title: "Bempire",
            tags: ["Frontend", "Design",],
            description: "Bempire is a cryptocurrency  network. The website is easy to use and safe for transferring crypto on different networks. The platform is giving the users easy access to their network. ",
            icons: [
                { "icon": figmaIcon, },
                { "icon": reactIcon, },
            ],
            image: bempire,
            link: "https://bempire.xyz/"
        },
        {
            title: "NFC Payment",
            tags: ["Design", "Frontend", "Backend",],
            description: " NFC platform is a method of wireless data transfer that allows smartphones, laptops, tablets and other devices to share data when in close proximity. NFC technology powers contactless payments via mobile wallets like Apple Pay and Google Pay, as well as contactless cards.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": reactIcon, },
            ],
            image: nfc,
            link: "https://bixbnfc.io"
        },
        {
            title: "Bemgrid",
            tags: ["Frontend"],
            description: " This project has been done by FLLTech developers for client who was looking for a platform to provide Blockchain Node document . The website is very friendly for developers ,BemGrid offers an easy to use hosted API, load balanced full nodes, secure and reliable developer tools.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": laravelIcon, },
            ],
            image: bemgrid,
            link: "https://bemgrid.xyz/"
        },
        {
            title: "Vexpo",
            tags: ["Design", "Frontend", "Backend",],
            description: "VExpo is a company which focuses on holding expos for many years. In the new technological world, our customers decided to lunch new website and social media to make access to their expos easier for people all around Armenia and even other countries. To achieve this goal we provided the idea of a virtual expo so there could attract more customers all around the world and provide easy access wherever they are.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": laravelIcon, },
            ],
            image: vexpo,
            link: "https://Vexpo.am"
        },
        {
            title: "trainet",
            tags: ["Design", "Frontend", "Backend",],
            description: "We have delivered an online learning platform for selling trading tutorials that supports crypto payments. The website is designed based on our client’s needs, so it provides learning videos that users can watch online or download. All the academic websites we develop include a blog for categorized educational content. It also creates a profile for each student and enables them to receive a certification after accomplishing a course.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": laravelIcon, },
            ],
            image: trainet,
            link: "https://trainet.co"
        },
        {
            title: "Food ordering platform",
            tags: ["Design", "Frontend", "Backend",],
            description: "We have designed an eye-catching website for a popular restaurant that not only presents its products in a way that gives the customer an easy-to-find category of foods to order online but also facilitates payments and customer service. The owner can analyze users’ feedback over a particular food according to their comments or given stars in the review section. The website refers users to the restaurant’s social media accounts or Google Play & App Store to install the App. However, the web version is mobile-responsive as well. Furthermore, it collects limited information from users for marketing purposes, such as recognizing major customers or running email marketing campaigns.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": laravelIcon, },
                { "icon": androidIcon, },
                { "icon": appleIcon, },
            ],
            image: resturant,
            link: "https://food.byfll.com"
        },
        {
            title: "Easychange",
            tags: ["Design", "Frontend", "Backend",],
            description: "EasyChange revolutionizes cryptocurrency trading with its full-node online exchange. Featuring personalized wallets and user-friendly tools, we make trading digital assets effortless. Our commitment to security and innovation ensures a reliable and convenient platform for enthusiasts and professionals alike. Join EasyChange to experience the future of cryptocurrency trading, where accessibility and performance converge seamlessly.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": laravelIcon, },
            ],
            image: easychange,
            link: "https://easychange.io/"
        },
        {
            title: "BestChanger",
            tags: ["Design", "Frontend", "Backend",],
            description: "BestChangeris your all-in-one online exchange platform, simplifying the conversion of fiat and cryptocurrencies. With user-friendly features and top-notch security, we make exchanging digital and traditional currencies seamless. Whether you're a seasoned trader or a novice, BestChanger provides a safe and efficient environment for your currency exchange needs, ensuring quick and hassle-free transactions.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": laravelIcon, },
            ],
            image: bestchanger,
            link: "https://bestchanger.com/"
        },
        {
            title: "Balapain",
            tags: ["Design", "Frontend", "Backend",],
            description: "FLLTECH proudly powers BalaPain, a leading online platform offering real-time cryptocurrency, fiat currency, and gold spot rates. Our skilled team spearheaded the development of this essential website, delivering up-to-the-minute financial data to empower users in their decision-making. BalaPain is your trusted source for accurate, timely market information, expertly crafted by FLLTECH.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": phpIcon, },
            ],
            image: balapain,
            link: "https://balapain.com/"
        },
        {
            title: "Shahapolymer",
            tags: ["Design", "Frontend"],
            description: "We are proud to collaborate with Shahapolymer, a pioneering company established in Armenia since 1996. Specializing in the import of raw materials for plastic and polymeric goods, they've expanded into wholesale and retail markets with a unique specialized store, marking the first of its kind in Armenia. FLLTECH is privileged to develop Shahapolymer's website, supporting their growth and innovation.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": phpIcon, },
            ],
            image: shahapolymer,
            link: "https://shahapolymer.com"
        },
        {
            title: "Snakerider",
            tags: ["Design", "Unity", "Frontend",],
            description: "Snakerider is a game developed by FLLTech team which is a easy and in the same time very exited game. In this online game users are playing all together on a land which has different kind of obstacles which users should avoid them when they are riding their snakes.There are lots of rewards too for users to take and make their snake rid faster or longer. This game is very popular among players because it's easy to learn and it needs focus in the same time  and they are compete with all online users in the same time, which makes it very exciting to participate.",
            icons: [
                { "icon": unityIcon, },
                { "icon": figmaIcon, },
                { "icon": htmlIcon, },
                { "icon": jsIcon, },
            ],
            image: snakerider,
            link: "https://snakerider.io"
        },
    ];
    return data;
}