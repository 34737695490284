import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Title from '../components/shared/Title';
import ServiceToDo from '../components/common/service-todo/ServiceToDo';
import Logos from '../components/common/Logos';
import ProductServiceItems from '../components/common/ProductServiceItems';
import GetInTouch from '../components/common/GetInTouch';
import SolutionsSection from '../components/home/SolutionsSection';
import ServicesExplore from '../components/common/services-explore/ServicesExplore';
import ContactSection from '../components/common/ContactSection';
import ServiceBenefits from '../components/common/ServiceBenefits';
import DescriptionCard from '../components/common/DescriptionCard';
import WebDevTools from '../components/web-dev/webDevTools';
import HelpToChoose from '../components/common/help-to-choose/HelpToChoose';
import Categories from '../components/common/help-to-choose/Categories';
import ServiceApproach from '../components/common/ServiceApproach';

// Constant
import { getWebToDoData } from '../constant/web-dev/webToDoData';
import { getWebLogos } from '../constant/web-dev/webDevLogos';
import { getProductServiceData } from '../constant/web-dev/webProductServiceData';
import { getWebPlatformLogos } from '../constant/web-dev/webPlatformLogos';
import { getWebBenefitsData } from '../constant/web-dev/webBenefitsData';
import { getWebToChooseData } from '../constant/web-dev/webToChooseOptions';
import { getWebApproachData } from '../constant/web-dev/webApproachData';
import { getWebSolutionData } from '../constant/web-dev/webSolutionData';
import { getWebToChooseCategoriesData } from '../constant/web-dev/webToChooseCategories';


const WebDev = () => {

    const webTodoData = getWebToDoData();
    const webLogos = getWebLogos();
    const webProductService = getProductServiceData();
    const webPlatformLogos = getWebPlatformLogos();
    const [webBenefitIntro, webBenefitData] = getWebBenefitsData();
    const { intro, data } = getWebToChooseData();
    const webToChooseCategoriesData = getWebToChooseCategoriesData();
    const webApproach = getWebApproachData();
    const solutionData = getWebSolutionData();

    return (
        <>
            <Helmet>
                <title>FLLTECH | Top Web Development Company</title>
                <meta name='description' content='FLLTECH is a top-rated web development company in Armenia and UK, offering custom web application development services worldwide.' />
                <meta name='keywords' content='web development , website development , Web Development Company' />
            </Helmet>
            <main className='overflow-hidden md:overflow-visible'>
                <div className='content-section mt-0'>
                    <div className="w-full max-w-fit mb-20 mt-44">
                        <Title type="stroke" size="xl">
                            web
                        </Title>
                        <Title className="-mt-4 text-xl" type="normal" size="md">
                            development services
                        </Title>
                    </div>
                    <div className="w-full max-w-4xl mb-14">
                        <Title type="golden" size="xs">
                            what we do
                        </Title>
                        <Title className='relative z-0' type="normal" size="sm">
                            Top Web Development Company Specialized to Create Robust Website and Applications.
                        </Title>
                        <Title className='relative z-0 my-10' type="normal" size="sm" headTagType='h2'>
                            Trusted Web App Development Company
                        </Title>
                        <p className='text-neutral-light-600 text-lg w-full max-w-lg'>
                            As a leading web development company, we always create robust, secure, custom web application with high scalability. According to the need of product, we use open source technology to develop a product with high quality standard. We help you stand out in this digital world by providing bespoke web development solutions.
                            <br />
                            <br />
                            Since 2011, FllTech has been developing futuristic website and web applications that take businesses to greater heights. Our highly skilled web developers adopted a customer focused approach by upgrading their self to latest web technology like ReactJS, Angular, Node.js, Laravel, Golang and many others.
                        </p>
                    </div>
                </div>
                <ServiceToDo data={webTodoData} />
                <div className='content-section my-0 -mt-20'>
                    <Logos logosList={webLogos} logoSize='w-16' />
                </div>
                <ProductServiceItems productServices={webProductService} />
                <GetInTouch />
                <SolutionsSection data={solutionData} />
                <div className='content-section'>
                    <div className='w-full max-w-5xl'>
                        <Title className='mb-16' type='golden' size='xs'>
                            trend languages
                        </Title>
                    </div>
                    <Logos logosList={webPlatformLogos} logoSize='w-16' isBoxed hasBgImage />
                </div>
                <div className='content-section'>
                    <div className='w-full max-w-5xl'>
                        <Title type='normal' size='md'>
                            Key Benefits of Having a
                            <Title className='-mb-8 -mt-6 md:-mb-10 md:-mt-8' type='stroke' size='lg' isHeadTag={false}>WEBSITE</Title>
                            For Your Business
                        </Title>
                        <div className='flex flex-col items-start md:flex-row justify-between gap-6'>
                            <section className='min-w-[300px] w-48% sticky top-28'>
                                <DescriptionCard title={webBenefitIntro.title} description={webBenefitIntro.description} />
                            </section>
                            <section className='min-w-[300px] w-48%'>
                                <ServiceBenefits data={webBenefitData} />
                            </section>
                        </div>
                    </div>
                </div>
                <WebDevTools />
                <HelpToChoose data={{ intro: intro, data: data }} serviceTitle="web development" />
                <Categories data={webToChooseCategoriesData} categoryTitle='coding standard' />
                <ServicesExplore />
                <ServiceApproach data={webApproach} />
                <ContactSection />
            </main>
        </>
    );
};

export default WebDev;