import { TitleType, TitleSize } from "../../theme/theme";

export default function Title({ className, type, size, children, isHeadTag = true, headTagType = 'h1' }) {
    const classNames = TitleType[type] + " " + TitleSize[size] + " " + className;
    const CustomHead = headTagType;

    return (
        isHeadTag ?
            <CustomHead className={classNames}>
                {children}
            </CustomHead> :
            <p className={classNames}>
                {children}
            </p>
    )
}