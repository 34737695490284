import React from 'react';
import { Link } from 'react-router-dom';

// Components
import Button from '../shared/Button';
import Title from '../shared/Title';

const GetInTouch = () => {
    return (
        <div className='content-section z-[21]'>
            <section className='w-full max-w-5xl py-16 px-6 flex flex-col items-center gap-y-8 rounded-lg bg-secondary-500 bg-pattern'>
                <Title type='normal' size='sm' headTagType='h3'>
                    Tell us what you need
                </Title>
                <p className='text-white max-w-md text-center'>
                    Get a free Cost Estimation & consultation from our experts to turn your idea into a fascinating digital product.
                </p>
                <Link to='/contact'>
                    <Button Btype='secondary' size='lg'>
                        Let’s talk!
                    </Button>
                </Link>
            </section>
        </div>
    );
};

export default GetInTouch;