import React from "react";
import styled from "styled-components";
import style from './accordionBody.module.css';

const Div = styled.div`
    width: 100%;
    max-height: auto;
    padding: 1rem;
    transition: 700ms max-height linear;
`;

const Article = styled.article`
    overflow-y: auto;
    transition: 400ms all ease-in-out;
    transition-delay: 200ms;
`;


const AccordionBody = ({ children, className, state, ...other }) => {

    return (
        <Div className={`${state.isExpanded ? style.expand : style.collapse} ${className ? className : ""}`} {...other}>
            <Article className={state.isExpanded ? style.translateIn : style.translateOut}>
                {children}
            </Article>
        </Div>
    )
};

export default AccordionBody;