import React from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';

// Components
import Title from '../shared/Title';
import Image from '../shared/Image';

// Images
import blurEllipse from '../../assets/imgs/blurEllipseLeft.svg';

const Div = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    column-gap: 3rem;
    width: 100%;
    max-width: 1024px;
    
    @media (min-width: 768px) {
        flex-direction: row;
    }

    section {
        &:first-child {
            margin-right: auto;
        }
        &:nth-child(2) {
            margin-left: auto;
            @media (min-width: 768px) {
                margin-top: 18rem;
            }
        }
    }

`;

const DoubleImageCard = ({ data }) => {
    return (
        <div className='content-section mt-0'>
            <img className='absolute -bottom-1/2 -left-0 hidden lg:block' src={blurEllipse} alt='ellipse' />
            <Div>
                {
                    data.map((content) => {
                        return (
                            <section key={v4()} className="flex flex-col items-start">
                                <Title type="normal" size="md" headTagType='h2'>
                                    {content.title}
                                </Title>
                                <div>
                                    <Image className="w-full max-w-[375px] my-4" src={content.img} alt="FLLTECH-coin" />
                                </div>
                                <article className="w-full max-w-[375px] font-normal text-lg text-neutral-light-600">
                                    <Title className='mb-4' type="normal" size='sm'>
                                        {content.subtitle}
                                    </Title>
                                    {content.description}
                                </article>
                            </section>
                        )
                    })
                }
            </Div>
        </div>
    );
};

export default DoubleImageCard;