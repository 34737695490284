import React from 'react';

const Loading = () => {
    return (
        <div className='flex justify-center items-center w-full h-full'>
            <span className="animate-ping absolute inline-flex h-14 w-14 rounded-full bg-secondary-500 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-7 w-7 bg-secondary-500"></span>
        </div>
    );
};

export default Loading;