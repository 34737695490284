import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Title from '../components/shared/Title';
import ProductServiceItems from '../components/common/ProductServiceItems';
import GetInTouch from '../components/common/GetInTouch';
import SolutionsSection from '../components/home/SolutionsSection';
import ServicesExplore from '../components/common/services-explore/ServicesExplore';
import ContactSection from '../components/common/ContactSection';
import FAQ from '../components/common/FAQ';
import DoubleImageCard from '../components/common/DoubleImageCard';
import HelpToChoose from '../components/common/help-to-choose/HelpToChoose';
import AppDevTools from '../components/app-dev/AppDevTools';
import ServiceToDo from '../components/common/service-todo/ServiceToDo';
import Logos from '../components/common/Logos';
import ServiceApproach from '../components/common/ServiceApproach';
import ServiceBenefits from '../components/common/ServiceBenefits';

// Constant
import { getProductServiceData } from '../constant/app-dev/appProductServicesData';
import { getAppFAQData } from '../constant/app-dev/appFAQData';
import { getAppImageCards } from '../constant/app-dev/appDoubleImageCardData';
import { getAppToChooseData } from '../constant/app-dev/appToChooseOptions';
import { getAppToDoData } from '../constant/app-dev/appToDoData';
import { getAppLogos } from '../constant/app-dev/appLogos';
import { getAppApproachData } from '../constant/app-dev/appApproachData';
import { getAppPlatformLogos } from '../constant/app-dev/appPlatformLogos';
import { getAppDevBenefitsData } from '../constant/app-dev/appDevBenefitsData';
import { getAppSolutionData } from '../constant/app-dev/appSolutionData';
import DescriptionCard from '../components/common/DescriptionCard';

const AppDev = () => {

    const productServiceData = getProductServiceData();
    const appFAQData = getAppFAQData();
    const appDoubleImageCard = getAppImageCards();
    const { intro, data } = getAppToChooseData();
    const appToDoData = getAppToDoData();
    const appLogos = getAppLogos();
    const appApproach = getAppApproachData();
    const appPlatformLogos = getAppPlatformLogos();
    const [appBenefitIntro, appBenefitData] = getAppDevBenefitsData();
    const solutionData = getAppSolutionData();

    return (
        <>
            <Helmet>
                <title>FLLTECH | Our App development Team Making Your Dreams a Reality</title>
                <meta name='description' content='Our app development team are expert in creating Apps and dApps for multiple platforms such as iOS, Android and cross-platform solutions in Flutter and React Native' />
                <meta name='keywords' content='Mobile app development , Mobile app development company , apple developers' />
            </Helmet>
            <main className='overflow-hidden md:overflow-visible'>
                <div className='content-section mt-0'>
                    <div className="w-full max-w-fit mb-20 mt-44">
                        <Title type="stroke" size="xl">
                            mobile app
                        </Title>
                        <Title className="-mt-4 text-xl" type="normal" size="md">
                            development services
                        </Title>
                    </div>
                    <div className="w-full max-w-4xl mb-14">
                        <Title type="golden" size="xs">
                            WHO WE ARE
                        </Title>
                        <Title className='relative z-0' type="normal" size="sm">
                            We are Mobile App Development Company to Design & Develop Your Custom Mobile Application.
                        </Title>
                        <Title className='relative z-0 my-10' type="normal" size="sm">
                            Trusted Mobile Application Development Company
                        </Title>
                        <p className='text-neutral-light-600 text-lg w-full max-w-lg'>
                            Mobile app development is indeed nowadays necessary for all the business. We, as a mobile application development company can provide high quality standard mobile app of any category. We have worked with diverse brands, organizations, startups and individuals to create powerful apps from excellent idea.
                            <br />
                            <br />
                            Our team of mobile app developers is capable of creating apps for multiple-platforms like iOS, Android and cross-platform solutions in Flutter, React Native & Ionic. Being one of the top-rated mobile application development companies in USA and India, we have expertise in developing mobile app development solutions to meet your business requirements.
                        </p>
                    </div>
                </div>
                <ServiceToDo data={appToDoData} />
                <div className='content-section -mt-20'>
                    <Logos logosList={appLogos} logoSize='w-16' />
                </div>
                <ProductServiceItems productServices={productServiceData} />
                <GetInTouch />
                <DoubleImageCard data={appDoubleImageCard} />
                <SolutionsSection data={solutionData} />
                <div className='content-section'>
                    <div className='w-full max-w-5xl'>
                        <Title className='mb-6' type='golden' size='xs'>
                            what we do
                        </Title>
                    </div>
                    <Logos logosList={appPlatformLogos} isBoxed hasBgImage logoSize='w-16' />
                </div>
                <div className='content-section'>
                    <div className='w-full max-w-5xl'>
                        <Title type='normal' size='md'>
                            Key Benefits of Having a
                            <Title className='-mb-8 -mt-6 md:-mb-10 md:-mt-8' type='stroke' size='lg' isHeadTag={false}>MOBILE APP</Title>
                            For Your Business
                        </Title>
                        <div className='flex flex-col items-start md:flex-row justify-between gap-6'>
                            <section className='min-w-[300px] w-48% sticky top-28'>
                                <DescriptionCard title={appBenefitIntro.title} description={appBenefitIntro.description} />
                            </section>
                            <section className='min-w-[300px] w-48%'>
                                <ServiceBenefits data={appBenefitData} />
                            </section>
                        </div>
                    </div>
                </div>
                <AppDevTools />
                <HelpToChoose data={{ intro: intro, data: data }} serviceTitle="mobile app development" />
                <FAQ FAQData={appFAQData} />
                <ServicesExplore />
                <ServiceApproach data={appApproach} />
                <ContactSection />
            </main>
        </>
    );
};

export default AppDev;