import React from 'react';
import AllRoutes from './AllRoutes';
import Cursor from './components/cursor/Cursor';

// Components
import Header from './components/common/Header';
import Footer from './components/common/Footer';

const App = () => {
  return (
    <main className='relative main-bg w-full min-h-screen font-poppins selection:bg-secondary-500'>
      <Cursor />
      <Header />
      <AllRoutes />
      <Footer />
    </main>
  );
};

export default App;