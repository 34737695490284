import reactIcon from '../../assets/icons/services/react.svg';
import vueIcon from '../../assets/icons/services/vue.svg';
import angularIcon from '../../assets/icons/services/angular.svg';
import nodeIcon from '../../assets/icons/services/nodejs.svg';
import laravelIcon from '../../assets/icons/services/laravel.svg';
import goIcon from '../../assets/icons/services/Go-logo.svg';

export const getWebLogos = () => {
    const logos = [
        {
            "icon": reactIcon,
            "label": "ReacrJS  Development",
        },
        {
            "icon": vueIcon,
            "label": "VueJS  Development",
        },
        {
            "icon": angularIcon,
            "label": "angular  Development"
        },
        {
            "icon": nodeIcon,
            "label": "node.js  Development",
        },
        {
            "icon": laravelIcon,
            "label": "Laravel  Development",
        },
        {
            "icon": goIcon,
            "label": "Golang  Development"
        }
    ]
    return logos;
}