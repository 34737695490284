import React from 'react';
import { v4 } from 'uuid';

// Components
import Title from '../shared/Title';

// Images
import pattern from '../../assets/imgs/pattern.png';
import ellipseIcon from '../../assets/icons/ellipse.svg';

const ProductServiceItems = ({ productServices }) => {
    return (
        <div className='content-section'>
            <img className='absolute w-full object-cover bg-[#111111] hidden lg:block' src={pattern} alt='pattern' />
            <div className='relative w-full flex flex-col items-center max-w-7xl mt-16 z-[2] text-neutral-light-600'>
                <Title className='text-center' type='normal' size='md' headTagType='h2'>
                    {productServices.title}
                </Title>
                <article className='text-left w-full max-w-2xl text-lg'>
                    {productServices.description}:
                    <ul className='mt-3 space-y-3'>
                        {
                            productServices.items.map((item) => {
                                return (
                                    <li key={v4()} className='flex gap-x-2 items-center text-lg'>
                                        <img src={ellipseIcon} alt='list style' />
                                        <span className=''>
                                            {item}
                                        </span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </article>
            </div>
        </div>
    );
};

export default ProductServiceItems;