import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Title from '../components/shared/Title';
import DoubleImageCard from '../components/common/DoubleImageCard';
import GetInTouch from '../components/common/GetInTouch';
import ProductServiceItems from '../components/common/ProductServiceItems';
import GenresGame from '../components/game/GenresGame';
import GameDevTools from '../components/game/GameDevTools';
import HelpToChoose from '../components/common/help-to-choose/HelpToChoose';
import ContactSection from '../components/common/ContactSection';
import ServicesExplore from '../components/common/services-explore/ServicesExplore';
import Video from '../components/shared/Video';

// Constant
import { gameCards, projectGame } from '../constant/game/doubleImageCardData';
import { getProductServiceData } from '../constant/game/gameProductServiceData';
import { getGameToChooseData } from '../constant/game/gameToChooseOptionsData';

// Images
import joystickImg from '../assets/imgs/game/joystick.webp';
import videoThumb from '../assets/video/FLLtech_game_thumb.png';

// Video
import gameVideo from '../assets/video/FLLtech_game.mp4';

const Game = () => {

    const gameCardsData = gameCards();
    const projectGameData = projectGame();
    const productServiceItem = getProductServiceData()
    const { intro, data } = getGameToChooseData();

    return (
        <>
            <Helmet>
                <title>Crafting Next-Generation Games with our Expert game development team</title>
                <meta name='description' content='FLLTech provides full-cycle game development services that include all phases, from designing a game, and creating animations to 2D/3D modeling, coding, and release…' />
                <meta name='keywords' content='Game, Game Development' />
            </Helmet>
            <main className='overflow-hidden'>
                <div className="content-section mb-80 mt-0">
                    <div className="w-full max-w-fit mb-20 mt-44">
                        <Title type="stroke" size="xl">
                            game
                        </Title>
                        <Title className="-mt-4 text-xl" type="normal" size="md">
                            design & development
                        </Title>
                    </div>
                    <div className="w-full max-w-4xl mb-14">
                        <Title type="golden" size="xs">
                            WHO WE ARE
                        </Title>
                        <Title className='relative z-0' type="normal" size="sm">
                            A Leading Mobile Game Development Company That Builds Outstanding 2D and 3D Games.
                        </Title>
                        <Title className='relative z-0 my-10' type="normal" size="sm">
                            Bespoke Mobile Game Design and Development Company
                        </Title>
                        <p className='text-neutral-light-600 text-lg w-full max-w-lg'>
                            FLLTech creates either a crypto coin or a token and assists you in developing your own cryptocurrency. We define customized properties for your token, develop smart contracts, run QA on a test chain and deploy to the blockchain. Our technical experts support you continuously from ideation to development.
                        </p>
                    </div>
                    <img className='w-[300px] sm:w-[400px] lg:w-[500px] absolute right-0 top-full lg:top-3/4' src={joystickImg} alt='joystick' />
                </div>
                <div className="content-section">
                    <div className="w-full max-w-5xl">
                        <Title className="ml-auto" type="golden" size="xs">
                            what we do
                        </Title>
                    </div>
                </div>
                <DoubleImageCard data={gameCardsData} />
                <div className='ml-auto w-full h-full sm:h-[calc(100vh-6rem)]'>
                    <Video videoSrc={gameVideo} videoThumbSrc={videoThumb} cover />
                </div>
                <ProductServiceItems productServices={productServiceItem} />
                <GetInTouch />
                <DoubleImageCard data={projectGameData} />
                <GenresGame />
                <GameDevTools />
                <HelpToChoose data={{ intro: intro, data: data }} serviceTitle='Game Development' />
                <ServicesExplore />
                <ContactSection />
            </main>
        </>
    );
};

export default Game;