export const getAppFAQData = () => {
    const data = [
        {
            headerTitle: "What are the different types of mobile apps?",
            content: {
                title: 'There are mainly three types of mobile apps if we categorize them by the technology used to code them: native apps, hybrid apps and web-based mobile apps like progressive web app (PWA).',
                items: []
            }
        },
        {
            headerTitle: "What programming languages and technologies are used for mobile app development?",
            content: {
                title: 'We use Objective-C and Swift for iOS app development and Java and Kotlin for Android app development. For cross-platform app development, we use Flutter, React Native and Ionic.',
                items: []
            }
        },
        {
            headerTitle: "What are the benefits of outsourcing mobile app development?",
            content: {
                title: 'Here are some of the key benefits of outsourcing your mobile app development in the country like India:',
                items: ['Low development and operational cost', 'Flexible to work according to your time-zone', 'Access to experienced developers',
                    'Reduced development times', 'Assured quality services', 'State-of-the-art technology', 'Better understanding of different platforms']
            }
        },
        {
            headerTitle: "How much does it cost to develop a mobile app?",
            content: {
                title: 'The cost of mobile app development depends on many factors such as the development platform, app categories, app complexity, country of developer and number of features you need in your app. Contact us with your requirement to get the app development cost.',
                items: []
            }
        },
        {
            headerTitle: "How long does it take to build a mobile app?",
            content: {
                title: 'Figuring out how long does it take to develop a mobile app will depend on various factors like development platform, complexity of the design, experience level of developer, features and functionality, testing and many more. Contact us with your requirement to know the estimated time.',
                items: []
            }
        },
    ]
    return data;
}