// UI design images
import uiImg1 from '../../assets/imgs/design/ui-design/img1.webp'
import uiImg2 from '../../assets/imgs/design/ui-design/img2.webp'
import uiImg3 from '../../assets/imgs/design/ui-design/img3.webp'
import uiImg4 from '../../assets/imgs/design/ui-design/img4.webp'
import uiImg5 from '../../assets/imgs/design/ui-design/img5.webp'
import uiImg6 from '../../assets/imgs/design/ui-design/img6.webp'
import coinGif from '../../assets/imgs/design/ui-design/1600-1200 L.webm';

import aioCoin from '../../assets/imgs/design/ui-design/io-coin.webm';

// Graphic design images
import graphicImg1 from '../../assets/imgs/design/graphic-design/img1.webp';
import graphicImg2 from '../../assets/imgs/design/graphic-design/img2.webp';
import graphicImg3 from '../../assets/imgs/design/graphic-design/img3.webp';
import graphicImg4 from '../../assets/imgs/design/graphic-design/img4.webp';
import graphicImg5 from '../../assets/imgs/design/graphic-design/img5.webp';
import graphicImg6 from '../../assets/imgs/design/graphic-design/img6.webp';
import graphicImg7 from '../../assets/imgs/design/graphic-design/img7.webp';
import graphicImg8 from '../../assets/imgs/design/graphic-design/img8.webp';
import graphicImg9 from '../../assets/imgs/design/graphic-design/img9.webp';
import graphicImg10 from '../../assets/imgs/design/graphic-design/img10.webp';
import graphicImg11 from '../../assets/imgs/design/graphic-design/img11.webp';
import graphicImg12 from '../../assets/imgs/design/graphic-design/img12.webp';

export const getUiServicesData = () => {
    const data = {
        "title": 'UI/UX for Mobile & Web',
        'description': 'We follow industry standards and create UI/UX based on design guidelines. UI/UX design services we offer include',
        "items": [
            'UI Design',
            'UX Design',
            'Interaction Design',
            'AR / VR Game Development',
            'Prototyping'
        ],
        "medias": [
            { type: 'vid', file: aioCoin, },
            { type: 'img', file: uiImg1, alt: 'FLLTech-cryptizi' },
            { type: 'img', file: uiImg2, alt: 'FLLTech-gameNgain' },
            { type: 'vid', file: coinGif, },
            { type: 'img', file: uiImg3, alt: 'FLLTech-bempire' },
            { type: 'img', file: uiImg4, alt: 'FLLTech-metabix' },
            { type: 'img', file: uiImg5, alt: 'FLLTech-NFC' },
            { type: 'img', file: uiImg6, alt: 'FLLTech-cryptiziMobile' },
        ]
    }
    return data;
}

export const getGraphicServicesData = () => {
    const data = {
        "title": 'Graphic Design',
        'description': 'We create engaging content and meaningful experience for your target audience. Graphic design services we offer include',
        "items": [
            'Illustrations',
            'Logo Design',
            'Icon Design',
            'Motion Graphics & Animations',
        ],
        "medias": [
            { type: 'img', file: graphicImg1, alt: 'FLLTECH-GNG' },
            { type: 'img', file: graphicImg2, alt: 'FLLTECH-woodlock' },
            { type: 'img', file: graphicImg3, alt: 'FLLTECH-nairyan' },
            { type: 'img', file: graphicImg11, alt: 'Aghajanyan Hair studio' },
            { type: 'img', file: graphicImg4, alt: 'FLLTECH-woodlock design' },
            { type: 'img', file: graphicImg5, alt: 'FLLTECH-illustration' },
            { type: 'img', file: graphicImg6, alt: 'FLLTECH-cryptocurrencies' },
            { type: 'img', file: graphicImg7, alt: 'FLLTECH-snakerider' },
            { type: 'img', file: graphicImg8, alt: 'FLLTECH-einor' },
            { type: 'img', file: graphicImg9, alt: 'FLLTECH-defipot' },
            { type: 'img', file: graphicImg10, alt: 'FLLTECH-admarmara' },
            { type: 'img', file: graphicImg12, alt: 'Drone hub' },
        ]

    }
    return data;
}
