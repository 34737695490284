import React from 'react';
import { v4 } from 'uuid';

// Components
import Title from '../../shared/Title';
import DescriptionCard from '../DescriptionCard';

const ServiceToDo = ({ data }) => {

    return (
        <div className='content-section'>
            <div className='w-full max-w-5xl'>
                <Title type='golden' size='xs'>
                    what we do
                </Title>
                <div className='flex flex-col md:flex-row items-start justify-between gap-8 mb-8'>
                    {
                        data.map(data => {
                            return (
                                <DescriptionCard key={v4()} title={data.title} description={data.description} />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default ServiceToDo;