import React from 'react';
import { Link } from 'react-router-dom';

// Components
import Title from '../../shared/Title';
import Button from '../../shared/Button';
import Card from './Card';

// Icons
import arrowRight from '../../../assets/icons/arrow-right.svg';
import shapes from '../../../assets/imgs/services-explore/shapes.svg';

// Constant
import { getServiceExploreData } from './data';

const ServicesExplore = () => {

    const data = getServiceExploreData();

    return (
        <div className='content-section'>
            <div className='w-full max-w-5xl flex flex-col lg:flex-row-reverse items-center lg:justify-between p-12 gap-10 bg-[#111111] border border-[#1A1A1A]'>
                <div className='relative w-full max-w-[370px]'>
                    <img className='hidden md:block' src={shapes} alt='shapes' />
                    <section className='md:absolute top-1/2 md:-translate-y-1/2'>
                        <Title className='text-4xl' type='normal' headTagType='h3'>
                            looking for other <br />
                            <h3 className='text-secondary-500'>
                                services?
                            </h3>
                        </Title>
                        <p className='text-neutral-light-600 my-6'>
                            Explore our other related services to enhance the performance of your digital product.
                        </p>
                        <Link to='/services'>
                            <Button className='whitespace-nowrap' Btype='basic' size='lg'>
                                explore all services
                                <img className='ml-2' src={arrowRight} alt='arrow' />
                            </Button>
                        </Link>
                    </section>
                </div>
                <div className='flex flex-col md:flex-row'>
                    <div className='flex flex-col gap-6 md:flex-row'>
                        <section>
                            <Card icon={data[0].icon} title={data[0].title} link={data[0].link} className={`gradient-1 md:bg-none md:hover:gradient-1 mb-6`} />
                            <Card icon={data[1].icon} title={data[1].title} link={data[1].link} className={`gradient-2 md:bg-none md:hover:gradient-2`} />
                        </section>
                        <section className='md:mt-10'>
                            <Card icon={data[2].icon} title={data[2].title} link={data[2].link} className={`gradient-3 md:bg-none md:hover:gradient-3 mb-6`} />
                            <Card icon={data[3].icon} title={data[3].title} link={data[3].link} className={`gradient-6 md:bg-none md:hover:gradient-6`} />
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesExplore;