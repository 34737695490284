import React from 'react';
import { v4 } from 'uuid';

// Components
import Title from '../shared/Title';

// Icons
import checkIcon from '../../assets/icons/check.svg';
import closeIcon from '../../assets/icons/close.svg';

const ProvidedItemsToHelp = ({title, items, isProvided, ...rest}) => {

    const backgroundColor = isProvided ? "bg-secondary-500" : "bg-[#111111]";
    const border = !isProvided ? "border border-white" : "";

    const classNames = backgroundColor + " " + border + " " + rest.className;

    const icon = isProvided ? checkIcon : closeIcon;

    return (
        <ul className={`${classNames} p-12 w-full max-w-lg h-[512px] space-y-4`}>
            <Title className="text-2xl md:text-4xl mb-6" type="normal" headTagType='h3'>{title}</Title>
            {
                items.map(item => {
                    return (
                        <li key={v4()} className="flex items-center gap-x-2">
                            <img src={icon} alt='icon' />
                            <span className='text-base text-white xs:text-lg'>{item}</span>
                        </li>
                    )
                })
            }
        </ul>
    );
};

export default ProvidedItemsToHelp;