export const getAppDevBenefitsData = () => {
    const intro = {
        title: "Enhance Your Business Growth with Our Superior Mobile App Development Services",
        description: "Mobile applications are becoming very popular among businesses and service providers. Mobile apps have been proved to be very effective for communicating with target customers. Here are some major reasons why you should have a mobile app for your business:"
    }
    const data = [
        {
            title: "Increase sales",
            description: "It has been found that people search for products or services on their smartphones rather than using a laptop. The availability of your business app will probably increase the chance to get more sales."
        },
        {
            title: "Better Customer Engagement",
            description: "Customers always expect instant responses to their concerns and complaints. And, mobile apps help businesses to interact with them and resolve complaints with minimal lag time."
        },
        {
            title: "Stand Out From the Crowd",
            description: "Having a mobile app for your business helps you grab the attention of potential customers and capture a large part of the market share. It can help you build strong customer loyalty and remain ahead of the competition."
        },
        {
            title: "Business Analytics",
            description: "Businesses can easily collect user-specific data and feedbacks by having a mobile app. These data can help you offer better services to the customers."
        },
        {
            title: "Reduce Marketing Cost",
            description: "Compare to traditional marketing, mobile marketing is very easy. Mobile apps easily help you send notifications of your new products or services to your customers without burning a hole in your pocket."
        },
    ]
    return [intro, data];
}