import React from 'react';
import { v4 } from 'uuid';

// Components
import DescriptionCard from './DescriptionCard';

const ServiceBenefits = ({ data }) => {
    return (
        <div className='w-full flex flex-col'>
            {
                data.map(data => {
                    return (
                        <DescriptionCard key={v4()} title={data.title} description={data.description} cardSize='w-full' />
                    )
                })
            }
        </div>
    );
};

export default ServiceBenefits;