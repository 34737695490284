import React from 'react';

// Components
import Title from '../shared/Title';
import Logos from '../common/Logos';

// Constants
import { getGameDevTools } from '../../constant/game/gameDevToolsData';

const GameDevTools = () => {

    const gameDevTools = getGameDevTools();

    return (
        <div className='content-section'>
            <div className='w-full max-w-5xl mb-10'>
                <Title type='golden' size='xs'>
                    tools
                </Title>
                <Title type='normal' size='md'>
                    Game Design &
                    Development Tools
                </Title>
                <p className='text-neutral-light-600 text-lg w-full max-w-lg'>
                    Apart from engaging features and high-quality graphics, we use state-of-the-art tools and technologies to turn your concept into an interesting game.
                </p>
            </div>
            <Logos logosList={gameDevTools} logoSize='w-20' />
        </div>
    );
};

export default GameDevTools;