import { ButtonType, ButtonSize } from "../../theme/theme";

export default function Button({ className, size, Btype, children, ...other }) {
    const classNames = ButtonType[Btype] + " " + ButtonSize[size] + " " + className;

    return (
        <button className={classNames} {...other}>
            {children}
        </button>
    )
}