import gng from '../../assets/imgs/portfolio/design/gng.webp';
import Cryptizi from '../../assets/imgs/portfolio/design/cryptizi.webp';
import metabix from '../../assets/imgs/portfolio/design/metabix.webp';
import metabixMarketplace from '../../assets/imgs/portfolio/design/metabix-marketplace.webp';
import snakerider from '../../assets/imgs/portfolio/design/snakerider.webp';
import nfc from '../../assets/imgs/portfolio/design/nfc.webp';
import tahla from '../../assets/imgs/portfolio/design/tahla.webp';

import figmaIcon from '../../assets/icons/services/figma-2.svg';
import htmlIcon from '../../assets/icons/services/html.svg';
import jsIcon from '../../assets/icons/services/js.svg';
import phpIcon from '../../assets/icons/services/php.png';
import unityIcon from '../../assets/icons/services/unity.svg';
import androidIcon from '../../assets/icons/services/android-logo.svg';
import reactIcon from '../../assets/icons/services/react.svg';
import kotlinIcon from '../../assets/icons/services/kotlin.png';
import laravelIcon from '../../assets/icons/services/laravel.svg';

export const getDesignPorfolioData = () => {
    const data = [
        {
            title: "Gamengain (GNG)",
            tags: ["Design", "Frontend", "Backend",],
            description: "This project has been done by FLLTech developers for client who was looking for a Gaming cryptocurrency landing page with gamengain name this crypto token  provide many P2E  blockchain base games, this project designed by Figma",
            icons: [
                { "icon": figmaIcon, },
                { "icon": htmlIcon, },
                { "icon": jsIcon, },
                { "icon": phpIcon, },
            ],
            image: gng,
            link: "https://gamengain.io/"
        },
        {
            title: "Cryptizi",
            tags: ["Design", "Frontend", "Backend",],
            description: "Cryptizi is a Exchange for crypto currency. It is easy to use and safe for users. It gives the users opportunity to keep their crypto in the wallet on the exchange too. Cryptizi exchange is a platform for buying and selling cryptocurrencies. In addition to trading services, Cryptizi exchanges also offer price discovery through trading activity, as well as storage for crypto.",
            icons: [
                { "icon": figmaIcon, },
                
            ],
            image: Cryptizi,
            link: "https://www.Cryptizi.com/"
        },
        {
            title: "Metabix",
            tags: ["Design", "Frontend", "Backend",],
            description: "This project has been done by FLLTECH developers for client who was looking unique Website for Metavers, this project name is Metabix. this web is for Metaverse project anyone can buy avatars,lands,assets  in this project and play P2E in this project",
            icons: [
                { "icon": figmaIcon, },
                { "icon": reactIcon, },
                { "icon": htmlIcon, },
                { "icon": jsIcon, },
            ],
            image: metabix,
            link: "https://metabix.io/"
        },
        {
            title: "Metabix Marketplace",
            tags: ["Design", "Frontend", "Backend",],
            description: "This project has been done by FLLTECH developers for client who was looking for Metaverse land marketplace , They allow users to access different details related to Metabix metaverse lands & assets or buy & sell in virtual reality world, this platform is blockchain base.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": reactIcon, },
                { "icon": jsIcon, },
                { "icon": htmlIcon, },
                { "icon": phpIcon, },
            ],
            image: metabixMarketplace,
            link: ""
        },
        {
            title: "Snakerider",
            tags: ["Design", "Unity", "Frontend",],
            description: "Snakerider is a game developed by FLLTech team which is a easy and in the same time very exited game. In this online game users are playing all together on a land which has different kind of obstacles which users should avoid them when they are riding their snakes.There are lots of rewards too for users to take and make their snake rid faster or longer. This game is very popular among players because it's easy to learn and it needs focus in the same time  and they are compete with all online users in the same time, which makes it very exciting to participate.",
            icons: [
                { "icon": unityIcon, },
                { "icon": figmaIcon, },
                { "icon": htmlIcon, },
                { "icon": jsIcon, },
            ],
            image: snakerider,
            link: "https://snakerider.io"
        },
        {
            title: "NFC Payment",
            tags: ["Design", "Mobile Development", "Backend",],
            description: "NFC platform is a method of wireless data transfer that allows smartphones, laptops, tablets and other devices to share data when in close proximity. NFC technology powers contactless payments via mobile wallets like Apple Pay and Google Pay, as well as contactless cards.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": androidIcon, },
                { "icon": phpIcon, },
                { "icon": kotlinIcon, },
                { "icon": laravelIcon, },
            ],
            image: nfc,
            link: "https://bixbnfc.io"
        },
        {
            title: "Tahla",
            tags: ["Design", "Frontend", "Backend",],
            description: "Tahla offers a curated selection of exclusive gold jewelry, where artistry and elegance unite. Our collection showcases meticulously crafted pieces designed to elevate your style and celebrate life's special moments. With a commitment to quality and craftsmanship, Tahla is your destination for timeless and exquisite gold jewelry that captures the essence of sophistication and luxury.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": reactIcon, },
                { "icon": phpIcon, },
                { "icon": laravelIcon, },
            ],
            image: tahla,
            link: "#"
        },
    ];
    return data;
}