import Cryptizi from '../../assets/imgs/portfolio/app-dev/criptizi.webp';
import nfc from '../../assets/imgs/portfolio/app-dev/nfc.webp';
import onlineAppointment from '../../assets/imgs/portfolio/app-dev/online-ap.webp';
import fitnessTracker from '../../assets/imgs/portfolio/app-dev/fitness-tracker.webp';
import currencyConvertor from '../../assets/imgs/portfolio/app-dev/currency-convertor.webp';
import manageD from '../../assets/imgs/portfolio/app-dev/md.webp';

import figmaIcon from '../../assets/icons/services/figma-2.svg';
import androidIcon from '../../assets/icons/services/android-logo.svg';
import jsIcon from '../../assets/icons/services/js.svg';
import phpIcon from '../../assets/icons/services/php.png';
import reactIcon from '../../assets/icons/services/react.svg';
import appleIcon from '../../assets/icons/services/apple-logo.svg';
import flutterIcon from '../../assets/icons/services/flutter.svg';

export const getAppPorfolioData = () => {
    const data = [
        {
            title: "Cryptizi App",
            tags: ["Design", "Frontend", "Backend",],
            description: "Cryptizi is a Exchange for crypto currency for Android and iOS. It is easy to use and safe for users. It gives the users opportunity to keep their crypto in the wallet on the exchange too. Cryptizi exchange is a platform for buying and selling cryptocurrencies. In addition to trading services, Cryptizi exchanges also offer price discovery through trading activity, as well as storage for crypto.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": reactIcon, },
                { "icon": jsIcon, },
                { "icon": androidIcon, },
                { "icon": appleIcon, },
                { "icon": flutterIcon, },
            ],
            image: Cryptizi,
            link: "https://www.Cryptizi.com/"
        },
        {
            title: "NFC Payment",
            tags: ["Design", "Mobile Development", "Backend",],
            description: " NFC platform is a method of wireless data transfer that allows smartphones, laptops, tablets and other devices to share data when in close proximity. NFC technology powers contactless payments via mobile wallets like Apple Pay and Google Pay, as well as contactless cards.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": androidIcon, },
                { "icon": phpIcon, },
            ],
            image: nfc,
            link: ""
        },
        {
            title: "Online Appointment",
            tags: ["Mobile Development", "Design",],
            description: "Appointment scheduler is a  tool that allow clients to book, reschedule, and cancel appointments through a web interface and mobile application.  When clients want to make an appointment, they go to the website , application and choose an available date and time.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": androidIcon, },
            ],
            image: onlineAppointment,
            link: ""
        },
        {
            title: "Fitness Tracker",
            tags: ["Mobile Development", "Design",],
            description: "Fitness apps can be a convenient way to do workouts and track users  progress, from the details of users reps to the overall weekly miles. Tracking users activity can help them maintain their motivation and encourage you to keep working toward their personal fitness and health goals.The application is user friendly and gives users opportunities to track their personal fitness goals. ",
            icons: [
                { "icon": figmaIcon, },
                { "icon": androidIcon, },
            ],
            image: fitnessTracker,
            link: ""
        },
        {
            title: "Flight Booking App",
            tags: ["Design", "Mobile Development"],
            description: "get flight is a searching platform to find you the flight that you want at best prices and best times by only writing your destination and date of your flight this platform finds the most suitable flight for you",
            icons: [
                { "icon": figmaIcon, },
                { "icon": androidIcon, },
            ],
            image: currencyConvertor,
            link: ""
        },
        {
            title: "Cryptocurrency wallet",
            tags: ["Design", "Mobile Development"],
            description: "This project has been done by FLLTech developers for client who was looking for a cryptocurrency wallet mobile app to provide different token & coin,This Wallet mobile app is the easiest and safest way to buy any cryptocurrency token & coins, earn yield on crypto with staking or decentralized finance (DeFi), and access thousands of decentralized applications (dapps).",
            icons: [
                { "icon": figmaIcon, },
                { "icon": androidIcon, },
            ],
            image: manageD,
            link: ""
        },
    ];
    return data;
}