import React from 'react';
import { Link } from 'react-router-dom';
import SocialLinks from './SocialLinks';

// Logo
import logo from '../../assets/imgs/logo.png';

// Images
import ellipseDown from '../../assets/imgs/blurEllipseDown.svg';
import pattern from '../../assets/imgs/pattern.png';

// Icons
import locationIcon from '../../assets/icons/location.svg'
import CompanyRating from './CompanyRating';

// Compnany Ratings
import clutch from '../../assets/imgs/company-ratings/clutch.png'
import goodFirms from '../../assets/imgs/company-ratings/goodfirms.png'
import topDevelopers from '../../assets/imgs/company-ratings/topdevelopers.png'
import designRush from '../../assets/imgs/company-ratings/designrush.png'
import google from '../../assets/imgs/company-ratings/google.png'

const Footer = () => {

    const currentDate = new Date().getFullYear();

    return (
        <footer className='fll-tech--footer  bg-[#111111]'>
            <img className='absolute top-0 w-full h-full object-cover bg-[#111111] hidden lg:block' src={pattern} alt='pattern' />
            <img className='absolute bottom-0 hidden lg:block w-full' src={ellipseDown} alt='ellipse' />
            <div className='fll-tech--footer-row'>
                <img src={logo} className='w-44' alt='logo' />
                <ul className='flex flex-col md:flex-row gap-8'>
                    <li className='inline-flex items-start gap-3'>
                        <img src={locationIcon} alt='location' />
                        <p className='max-w-[16rem] fll-tech--footer-text'>
                            United Kingdom
                            <br />
                            64a cumberland street edinburgh
                            United Kingdom EH3 6RE
                        </p>
                    </li>
                    <li className='inline-flex items-start gap-3'>
                        <img src={locationIcon} alt='location' />
                        <a href='https://www.google.com/maps/dir//FLLTECH,+65+Orbeli,+Yerevan+0039/@40.1929957,44.4878839,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x406abd5c65f442ad:0xca25225f2795e9ff!2m2!1d44.4878839!2d40.1929957' className='max-w-[16rem] fll-tech--footer-text' target='_blank' rel="noreferrer">
                            Armenia
                            <br />
                            65 Orbeli, Yerevan 0039
                            Yerevan,Armenia
                        </a>
                    </li>
                </ul>
            </div>
            <div className='fll-tech--footer-row w-full'>
                <ul className='flex items-center justify-between w-full overflow-x-auto'>
                    <CompanyRating 
                        companyImage={clutch}
                        companyRating={"5"}
                        target={"https://clutch.co/profile/flltech#highlights"}
                        companyName="Clutch"/>
                    <CompanyRating 
                        companyImage={goodFirms}
                        companyRating={"5"}
                        target={"https://www.goodfirms.co/company/flltech"}
                        companyName="Good Firms"/>
                    <CompanyRating 
                        companyImage={topDevelopers}
                        companyRating={"5"}
                        target={"https://www.topdevelopers.co/profile/flltech"}
                        companyName="Top developers"/>
                    <CompanyRating 
                        companyImage={designRush}
                        companyRating={"5"}
                        target={"https://www.designrush.com/agency/profile/flltech"}
                        companyName="Design Rush"/>
                    <CompanyRating 
                        companyImage={google}
                        companyRating={"5"}
                        target={"https://g.page/r/Cf_plSdfIiXKEBM/review"}
                        companyName="Google"/>
                </ul>
            </div>
            <div className='fll-tech--footer-row'>
                <section className='flex-1'>
                    <p className='font-normal text-neutral-light-600 mb-8'>
                        © 2020-{currentDate} FLLTech. All rights reserved.
                    </p>
                    <ul className='flex flex-col md:flex-row gap-6'>
                        <li className='fll-tech--footer-text'>
                            <Link className='py-2' to='/services'>
                                services
                            </Link>
                        </li>
                        <li className='fll-tech--footer-text'>
                            <Link className='py-2' to='/portfolio'>
                                portfolio
                            </Link>
                        </li>
                        <li className='fll-tech--footer-text'>
                            <Link className='py-2' to='/aboutus'>
                                about us
                            </Link>
                        </li>
                        <li className='fll-tech--footer-text'>
                            <Link className='py-2' to='/faq'>
                                FAQ
                            </Link>
                        </li>
                        <li className='fll-tech--footer-text'>
                            <Link className='py-2' to='/sitemap'>
                                Sitemap
                            </Link>
                        </li>
                    </ul>
                </section>
                <section className='flex-1 md:ml-14'>
                    <p className='fll-tech--footer-text'>
                        socials
                    </p>
                    <SocialLinks />
                </section>
            </div>
        </footer>
    );
};

export default Footer;