// Icons
import pentoolIcon from '../assets/icons/pen-tool.svg'
import blockchaingIcon from '../assets/icons/blockchain.svg'
import codeIcon from '../assets/icons/code.svg'
import gameIcon from '../assets/icons/game.svg'
import mobileIcon from '../assets/icons/mobile.svg'
import statusupIcon from '../assets/icons/status-up.svg'
import figmaIcon from '../assets/icons/services/figma.svg';

import aftereffectIcon from '../assets/icons/services/Adobe-after-effects.svg';
import illustratorIcon from '../assets/icons/services/adobe-illustrator.svg';
import psIcon from '../assets/icons/services/adobe-photoshop.svg';
import xdIcon from '../assets/icons/services/adobe-xd.svg';
import androidIcon from '../assets/icons/services/android-logo.svg';
import appleIcon from '../assets/icons/services/apple-logo.svg';
import blenderIcon from '../assets/icons/services/blender.svg';
import diamondIcon from '../assets/icons/services/diamond.svg';
import flutterIcon from '../assets/icons/services/flutter.svg';
import goIcon from '../assets/icons/services/flutter.svg';
import jsonIcon from '../assets/icons/services/json.svg';
import laravelIcon from '../assets/icons/services/laravel.svg';
import nodeIcon from '../assets/icons/services/nodejs.svg';
import pythonIcon from '../assets/icons/services/python.svg';
import reactIcon from '../assets/icons/services/react.svg';
import rustIcon from '../assets/icons/services/rust.svg';
import solidityIcon from '../assets/icons/services/solidity.svg';
import unityIcon from '../assets/icons/services/unity.svg';
import unrealIcon from '../assets/icons/services/unrealengine.svg';
import yulIcon from '../assets/icons/services/yul.svg';

import googleads from '../assets/icons/services/google-ads.png';
import googleanalytics from '../assets/icons/services/google-analytics.png';
import moz from '../assets/icons/services/moz.png';
import googletrends from '../assets/icons/services/google-trends.png';
import semrush from '../assets/icons/services/semrush.png';

export const serviceData = () => {
    const data = [
        {
            "id": "1",
            "title": "UI/UX & Graphics Design",
            "icon": pentoolIcon,
            "bgGradient": "__design-gradient",
            "link": "/ui-design",
            "content": {
                "icons": [figmaIcon, diamondIcon, xdIcon, illustratorIcon, psIcon, aftereffectIcon],
                "description": "With a decade-long experience in UI/UX designing, our creative team of designers offers a full range of web and mobile app design services, from prototyping and graphic design to pixel-perfect UI."
            }
        },
        {
            "id": "2",
            "title": "App & dApp Development",
            "icon": mobileIcon,
            "bgGradient": "__app-dev-gradient",
            "link": "/app-development",
            "content": {
                "icons": [appleIcon, androidIcon, flutterIcon, reactIcon],
                "description": "With so many ways to build mobile apps, we have expertise in choosing what the best solution is for your mobile app development strategy, whether it's in Flutter, React Native, or native iOS and Android."
            }
        },
        {
            "id": "3",
            "title": "Web Development",
            "icon": codeIcon,
            "bgGradient": "__web-dev-gradient",
            "link": "/web-development",
            "content": {
                "icons": [nodeIcon, laravelIcon, goIcon, pythonIcon],
                "description": "Whether you want to develop a CMS, eCommerce or web application, we have a large web development team that creates secure and scalable web solutions tailored exclusively for your business goals."
            }
        },
        {
            "id": "4",
            "title": "Game Development",
            "icon": gameIcon,
            "bgGradient": "__game-dev-gradient",
            "link": "/game",
            "content": {
                "icons": [unityIcon, blenderIcon, unrealIcon],
                "description": "We are a creative team of mobile game developers and designers offering end-to-end game development solutions. Let's start our partnership to build exciting mobile games that meet user expectations across all platforms."
            }
        },
        {
            "id": "5",
            "title": "Digital Marketing Development",
            "icon": statusupIcon,
            "bgGradient": "__digital-gradient",
            "link": "digital-marketing",
            "content": {
                "icons": [googleads, googleanalytics, moz, googletrends, semrush],
                "description": "To become a successful business, trending token, or popular App, we help you with a solid marketing strategy, robust advertising, eye-catching web design, engaging content, and media coverage to build a great loyal community."
            }
        },
        {
            "id": "6",
            "title": "Blockchain & Web3.0",
            "icon": blockchaingIcon,
            "bgGradient": "__blockchain-gradient",
            "link": "/blockchain",
            "content": {
                "icons": [solidityIcon, rustIcon, yulIcon, jsonIcon],
                "description": "To have your own coin, token, or NFT, the FLLTech Blockchain team assist you in the best way. Our technical experts support you continuously from ideation to development in all Web 3.0 projects, smart contracts, dApps, metaverse, NFT marketplace, and DeFi platforms."
            }
        },
    ]
    return data
}
