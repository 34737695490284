import React, { Children } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import TabView from './TabView';
import ListView from './ListView';
import ServiceImage from './ServiceImage';
import Logos from '../common/Logos';

const Div = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    padding: 2.5rem;
    border-radius: 15px;
    gap: 12px;
    overflow: hidden;
`;

const Section = styled.section`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    gap: 40px;
    
    @media (min-width: 650px) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;

const getChildrenOnDisplayName = (children, displayName) => {
    return Children.map(children, (child) => {
        return child.type.displayName === displayName ?
            child :
            null
    });
}

const ServiceLgCard = ({ children, bgGradient, className, ...rest }) => {

    const classNames = bgGradient + ' ' + className + ' ';

    const listView = getChildrenOnDisplayName(children, "ListView");
    const tabView = getChildrenOnDisplayName(children, "TabView");
    const serviceImage = getChildrenOnDisplayName(children, "ServiceImage");
    const serviceLogos = getChildrenOnDisplayName(children, "ServiceLogos")

    return (
        <Div className={classNames} {...rest}>
            <Section>
                {listView}
                {tabView}
                {serviceImage}
            </Section>
            {serviceLogos}
        </Div>
    );
};


ListView.displayName = "ListView";
ServiceLgCard.ListView = ListView;

TabView.displayName = "TabView";
ServiceLgCard.TabView = TabView;

ServiceImage.displayName = "ServiceImage";
ServiceLgCard.ServiceImage = ServiceImage;

Logos.displayName = "ServiceLogos";
ServiceLgCard.ServiceLogos = Logos;

export default ServiceLgCard;

ServiceLgCard.propTypes = {
    bgGradient: PropTypes.string.isRequired,
    classNames: PropTypes.string,
}