import React from 'react';
import { v4 } from 'uuid';

import OptionCard from './OptionCard';

const Container = ({optionsData}) => {
    return (
        <div className='w-full flex flex-row justify-between flex-wrap gap-x-6 gap-y-14'>
            {
                optionsData.map(option => {
                    return (
                        <OptionCard key={v4()} title={option.title} description={option.description} />
                    )
                })
            }
        </div>
    );
};

export default Container;