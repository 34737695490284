import React from 'react';
import { v4 } from 'uuid';

// Components
import Title from '../shared/Title';
import DescriptionCard from './DescriptionCard';

// Images
import pattern from '../../assets/imgs/pattern.png';

const ServiceApproach = ({ data }) => {
    return (
        <div className='content-section'>
            <img className='absolute w-full object-cover bg-[#111111] hidden lg:block' src={pattern} alt='pattern' />
            <div className='w-full max-w-5xl relative z-10'>
                <Title className='mb-8' type='golden' size='xs' headTagType='h2'>
                    our approach
                </Title>
                <section className='grid grid-cols-1 sm:grid-cols-2 gap-8'>
                    {
                        data.map(data => {
                            return (
                                <DescriptionCard key={v4()} title={data.title} description={data.description} icon={data.icon} />
                            )
                        })
                    }
                </section>
            </div>
        </div>
    );
};

export default ServiceApproach;