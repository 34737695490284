export const getWebToDoData = () => {
    const data = [
        {
            title: "Front End Web Development",
            description: "Front end or client-side development is the practice of managing everything that users visually see first in their application or browser.",
        },
        {
            title: "Back End Web Development",
            description: "Backend or server-side development is responsible for managing data to ensure everything on the client-side actually works.",
        },
    ]
    return data;
}