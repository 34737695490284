import React from 'react';

// Components
import Title from '../shared/Title';
import ServiceToolsItem from '../common/service-tools/ServiceToolItem';

// Constants
import { getWebToolsItems } from '../../constant/web-dev/webToolsItemData';

const WebDevTools = () => {

    const webToolsItems = getWebToolsItems();
    const {
        frontend,
        backend,
        database,
        designTools,
    } = webToolsItems;

    return (
        <div className='content-section'>
            <div className='w-full max-w-5xl'>
                <Title type='golden' size='xs'>
                    technologies
                </Title>
                <section className='w-full flex flex-col sm:flex-row sm:flex-wrap sm:justify-between gap-8 gap-x-12 my-10'>
                    <ServiceToolsItem data={frontend} />
                    <ServiceToolsItem data={backend} />
                    <ServiceToolsItem data={database} />
                    <ServiceToolsItem data={designTools} />
                </section>
            </div>
        </div>
    );
};

export default WebDevTools;