import visionImg from '../../assets/imgs/about-us/vision.webp'
import missionImg from '../../assets/imgs/about-us/mission.webp'

export const getAboutApproachData = () => {
    const data = [
        {
            title: "Our Vision",
            description: "We paint the bright future for the businesses working on digital landscapes and firmly believe in providing productive long-term business relationships.",
            icon: visionImg
        },
        {
            title: "Our Mission",
            description: "Handover contemporary and futuristic web and mobile software solutions to the businesses ranging from startups to enterprises with affordability and satisfaction.",
            icon: missionImg
        },
    ]
    return data;
}