import React from 'react';

// Components
import Title from '../../shared/Title';

const OptionCard = ({title, description}) => {
    return (
        <div className='w-full max-w-md flex flex-col'>
            <Title className='mb-3 md:mb-5' type='normal' size='sm' headTagType='h3'>
                {title}
            </Title>
            <p className='w-full text-neutral-light-600'>
                {description}
            </p>
        </div>
    );
};

export default OptionCard;