import React from 'react';
import { v4 } from 'uuid';
import PropTypes from 'prop-types';

// Helpers
import { addNewLine } from '../../helpers/functions';

const Logos = ({ logosList, logoSize = 'w-fit', isBoxed = false, isFlex = false, boldText = false, hasBgImage = false }) => {
    return (
        <ul className={`w-full max-w-5xl ${isFlex ? 'flex items-center flex-wrap' : `grid ${isBoxed ? 'grid-cols-1 xs:grid-cols-2 md:grid-cols-4' : 'grid-cols-2 sm:grid-cols-3 md:grid-cols-6'}`} gap-8`}>
            {
                logosList.map(logo => {
                    return (
                        <li key={v4()} className={`overflow-hidden relative ${isBoxed ? 'bg-neutral-dark-600 py-12' : ''} ${hasBgImage ? 'hover:__hover-gradient __line-gradient' : ''} `}>
                            {
                                hasBgImage &&
                                <img className='absolute -right-12 top-1/2 w-4/5 h-full -translate-y-1/2 opacity-10' src={logo.icon} alt={logo.label ? logo.label : 'logo'} />
                            }
                            <section className={`relative flex flex-col items-center capitalize z-10 h-full`}>
                                <img className={`my-auto ${logoSize}`} src={logo.icon} alt={logo.label ? logo.label : 'logo'} />
                                {logo.subtitle &&
                                    <p className='text-white font-bold mt-4'>{logo.subtitle}</p>}
                                {logo.label &&
                                    <p className={`${boldText ? 'font-bold' : 'font-normal'} text-white whitespace-pre-line text-center mt-2`}>{addNewLine(logo.label)}</p>}
                            </section>
                        </li>
                    )
                })
            }
        </ul>
    );
};

export default Logos;

Logos.propTypes = {
    logosList: PropTypes.array.isRequired,
    logoSize: PropTypes.string
}
