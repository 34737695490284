import img1 from '../../assets/imgs/about-us/gallery/1.webp';
import img2 from '../../assets/imgs/about-us/gallery/2.webp';
import img3 from '../../assets/imgs/about-us/gallery/3.webp';
import img4 from '../../assets/imgs/about-us/gallery/4.webp';
import img5 from '../../assets/imgs/about-us/gallery/5.webp';
import img6 from '../../assets/imgs/about-us/gallery/6.webp';
import img7 from '../../assets/imgs/about-us/gallery/7.webp';
import img8 from '../../assets/imgs/about-us/gallery/8.webp';
import img9 from '../../assets/imgs/about-us/gallery/9.webp';
import img10 from '../../assets/imgs/about-us/gallery/10.webp';
import img11 from '../../assets/imgs/about-us/gallery/11.webp';
import img12 from '../../assets/imgs/about-us/gallery/12.webp';

export const aboutGalleryData = () => {
    const data = [
        {
            src: img1,
            src2: img2,
            alt: "",
            alt2: "",
            size: 'small'
        },
        {
            src: img3,
            alt: "",
            size: 'big'
        },
        {
            src: img4,
            src2: img5,
            alt: "",
            alt2: "",
            size: 'small'
        },
        {
            src: img6,
            alt: "",
            size: 'big'
        },
        {
            src: img7,
            src2: img8,
            alt: "",
            alt2: "",
            size: 'small'
        },
        {
            src: img9,
            alt: "",
            size: 'big'
        },
        {
            src: img10,
            src2: img11,
            alt: "",
            alt2: "",
            size: 'small'
        },
        {
            src: img12,
            alt: "",
            size: 'big'
        },
    ]
    return data;
}