import React, { useState } from 'react';
import { v4 } from 'uuid';

// Component
import TabContent from './TabContent';
import TabItem from './TabItem';
import Title from '../shared/Title';
import Image from '../shared/Image';

const Tabs = ({ contentsList }) => {

    const [activeTab, setActiveTab] = useState("t1");

    return (
        <div>
            <ul className='flex border-b border-neutral-dark-600 overflow-x-auto'>
                {
                    contentsList.map(content => {
                        return (
                            <TabItem
                                key={v4()}
                                id={content.id}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                className='nav--tab-item'
                                activeClass='nav--tab-active'>
                                {content.title}
                            </TabItem>
                        )
                    })
                }
            </ul>
            <div>
                {
                    contentsList.map(content => {
                        return (
                            <TabContent
                                key={v4()}
                                id={content.id}
                                activeTab={activeTab}
                                className='nav--tab-content-container'>
                                <Image className='mb-6 w-[300px]' src={content.img} alt={content.title} />
                                <section className='w-full sm:w-1/2'>
                                    <Title type='normal' size='sm' className='mb-2' headTagType='h4'>
                                        {content.title}
                                    </Title>
                                    <p className='text-[18px] font-semibold sm:font-normal text-neutral-dark-100'>
                                        {content.description}
                                    </p>
                                </section>
                            </TabContent>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default Tabs;