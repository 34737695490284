import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Title from '../components/shared/Title';
import Help from '../components/design/Help';
import DesignServices from '../components/design/DesignServices';
import GetInTouch from '../components/common/GetInTouch';
import DesignTools from '../components/design/DesignTools';
import HelpToChoose from '../components/common/help-to-choose/HelpToChoose';
import FAQ from '../components/common/FAQ';
import ContactSection from '../components/common/ContactSection';
import ServicesExplore from '../components/common/services-explore/ServicesExplore';
import Video from '../components/shared/Video';

// Images
import videoThumb from '../assets/video/FLLtech_design_thumb.png';

// Constant
import { getDesignToChooseData } from '../constant/design/designToChooseOptionsData';
import { getDesignFAQData } from '../constant/design/designFAQData';

// Video 
import designVideo from '../assets/video/FLLtech_design.mp4';

const Design = () => {

    const { intro, data } = getDesignToChooseData();
    const designFAQData = getDesignFAQData();

    return (
        <>
            <Helmet>
                <title>Helping You Connect with Your Users Through Intuitive and User-Friendly UI/UX Design</title>
                <meta name='description' content='Our UI/UX designers, with more than a decade of experience, can give you world-class digital product designing for any website or app and research challenges you might have.' />
                <meta name='keywords' content='UI/UX, Branding, UI/UX Design' />
            </Helmet>
            <main className='overflow-hidden md:overflow-visible'>
                <div className='content-section mt-0'>
                    <div className='w-full max-w-fit mt-44'>
                        <Title className='' type="normal" size="md">
                            UI / UX & graphic
                        </Title>
                        <Title type="stroke" size="xl">
                            design
                        </Title>
                    </div>
                    <div className="w-full max-w-5xl mb-14">
                        <Title type="golden" size="xs">
                            WHO WE ARE
                        </Title>
                        <Title className='relative z-0 w-full max-w-xl' type="normal" size="sm">
                            We Design Visually Stunning & User-Friendly Customer Experience.
                        </Title>
                        <p className='text-neutral-light-600 text-lg w-full max-w-xl'>
                            The Design division at FLLTECH works as an independent wing with a dedicated team of User interface(UI) Designers, User Experience (UX) Designers, Graphic Designers and Animators.
                        </p>
                    </div>
                </div>
                <div className='w-full flex overflow-hidden'>
                    <div className='w-full max-w-[82rem] ml-auto flex flex-col-reverse lg:flex-row'>
                        <div className='relative flex lg:flex-col items-center lg:items-start justify-between p-4 lg:mr-8'>
                            <ul className='space-y-5 lg:space-y-10'>
                                <li>
                                    <Title className='text-secondary-500 text-[40px] lg:text-7xl font-bold'>2500+</Title>
                                    <Title type='normal' className='text-base md:text-lg'>Projects delivered</Title>
                                </li>
                                <li>
                                    <Title className='text-secondary-500 text-[40px] lg:text-7xl font-bold'>1600+</Title>
                                    <Title type='normal' className='text-base md:text-lg'>Happy clients</Title>
                                </li>
                            </ul>
                            <section className='flex flex-col'>
                                <Title size='xs' className='text-neutral-light-600 first-letter:capitalize'>
                                    more work on
                                </Title>
                                <a href='https://dribbble.com/FLLTECH' className='text-stroke hover:text-white font-black text-3xl lg:text-4xl'>
                                    dribbble
                                </a>
                                <a href='https://www.behance.net/flltech' className='text-stroke hover:text-white font-black text-3xl lg:text-4xl'>
                                    behance
                                </a>
                                <a href='https://github.com/FLLTECH' className='text-stroke hover:text-white font-black text-3xl lg:text-4xl'>
                                    github
                                </a>
                            </section>
                        </div>
                        <div className='ml-auto w-full h-full'>
                            <Video videoSrc={designVideo} videoThumbSrc={videoThumb} />
                        </div>
                    </div>
                </div>
                <Help />
                <DesignServices />
                <div className='content-section'>
                    <GetInTouch />
                </div>
                <DesignTools />
                <HelpToChoose data={{ intro: intro, data: data }} serviceTitle="UI/UX Design" />
                <FAQ FAQData={designFAQData} />
                <ServicesExplore />
                <ContactSection />
            </main>
        </>
    );
};

export default Design;