import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Component
import Title from '../components/shared/Title';
import ContactSection from '../components/common/ContactSection';
import DoubleServicesBox from '../components/common/DoubleServicesBox';
import ServiceLgCard from '../components/services-components/ServiceLgCard';

// Constant
import { getServicesData } from '../constant/services_constant/data';

// Icons 
import arrowRightIcon from '../assets/icons/arrow-right.svg';
import Logos from '../components/common/Logos';

// Images
import uiDesignImage from '../assets/imgs/services/design/ui-design-images.png';
import mobileAppImage from '../assets/imgs/services/app-dev/mobile-app-image.webp';
import webDevImage from '../assets/imgs/services/web-dev/web-dev-image.png';
import joystickImage from '../assets/imgs/game/full-joystick.webp';
import blockahinImage from '../assets/imgs/services/blockchain/blockchain-network.webp';
import digitalMkImage from '../assets/imgs/services/digital-marketing/digitalmt.webp';

const Services = () => {

    const { designData, gameDevData, appDevData, blockchaninData, webDevData, marketingData } = getServicesData();

    return (
        <>
            <Helmet>
                <title>Our services ( Web , App , dApp , Web3 & Blockchain Development )</title>
                <meta name='description' content='FLLTECH is an IT company in the field of App, DApp, web, gaming, blockchain , web 3.0 and DeFi platform development, and digital marketing.' />
                <meta name='keywords' content='Web Development , App development , Blockchain' />
            </Helmet>
            <main className='overflow-hidden'>
                <div className='content-section px-6 md:px-10 lg:px-14 mt-0'>
                    <div className='w-full mt-44'>
                        <Title className='-mb-2' type='normal' size='md'>
                            our
                        </Title>
                        <Title type='stroke' size='xl'>
                            services
                        </Title>
                        <div className='space-y-14'>
                            {/* ------------------------UX/UI Design Service------------------------ */}
                            <ServiceLgCard bgGradient={designData.bgGradient}>
                                <ServiceLgCard.ListView data={designData.ui}>
                                    <Link to='/ui-design'>
                                        <img className='w-10 p-2 rounded-full border border-white' src={arrowRightIcon} alt='arrow' />
                                    </Link>
                                </ServiceLgCard.ListView>
                                <ServiceLgCard.ListView data={designData.graphic}>
                                    <Link to='/ui-design'>
                                        <img className='w-10 p-2 rounded-full border border-white' src={arrowRightIcon} alt='arrow' />
                                    </Link>
                                </ServiceLgCard.ListView>
                                <ServiceLgCard.ServiceImage imageSrc={uiDesignImage} scrollable />
                            </ServiceLgCard>
                            {/* ------------------------Game Development Service------------------------ */}
                            <ServiceLgCard bgGradient={gameDevData.bgGradient}>
                                <ServiceLgCard.ListView data={gameDevData.gameDev}>
                                    <Link to='/game'>
                                        <img className='w-10 p-2 rounded-full border border-white' src={arrowRightIcon} alt='arrow' />
                                    </Link>
                                </ServiceLgCard.ListView>
                                <ServiceLgCard.ListView data={gameDevData.unityDev}>
                                    <Link to='/game'>
                                        <img className='w-10 p-2 rounded-full border border-white' src={arrowRightIcon} alt='arrow' />
                                    </Link>
                                </ServiceLgCard.ListView>
                                <ServiceLgCard.ServiceImage imageSrc={joystickImage} rotatable />
                            </ServiceLgCard>
                            {/* ------------------------Mobile app Development Service------------------------ */}
                            <ServiceLgCard bgGradient={appDevData.bgGradient}>
                                <ServiceLgCard.TabView data={appDevData} />
                                <ServiceLgCard.ServiceImage imageSrc={mobileAppImage} scrollable scrollToLeft />
                            </ServiceLgCard>
                            {/* ------------------------Web Development Service------------------------ */}
                            <ServiceLgCard bgGradient={webDevData.bgGradient}>
                                <ServiceLgCard.TabView data={webDevData} />
                                <ServiceLgCard.ServiceImage imageSrc={webDevImage} scrollable />
                            </ServiceLgCard>
                            {/* ------------------------Blockchain Service------------------------ */}
                            <ServiceLgCard bgGradient={blockchaninData.bgGradient}>
                                <ServiceLgCard.ListView data={blockchaninData.blockchain}>
                                    <Link to='/blockchain'>
                                        <img className='w-10 p-2 rounded-full border border-white' src={arrowRightIcon} alt='arrow' />
                                    </Link>
                                </ServiceLgCard.ListView>
                                <ServiceLgCard.ListView data={blockchaninData.explore} hasExploreLink>
                                    <Link to='/blockchain'>
                                        <img className='w-10 p-2 rounded-full border border-white' src={arrowRightIcon} alt='arrow' />
                                    </Link>
                                </ServiceLgCard.ListView>
                                <ServiceLgCard.ServiceImage imageSrc={blockahinImage} />
                                <Logos logosList={blockchaninData.icons} />
                            </ServiceLgCard>
                            {/* ------------------------Digital Marketing Service------------------------ */}
                            <ServiceLgCard bgGradient={marketingData.bgGradient}>
                                <ServiceLgCard.ListView data={marketingData.digital}>
                                </ServiceLgCard.ListView>
                                <ServiceLgCard.ListView data={marketingData.explore} hasExploreLink>
                                </ServiceLgCard.ListView>
                                <ServiceLgCard.ServiceImage imageSrc={digitalMkImage} />
                            </ServiceLgCard>
                        </div>
                    </div>
                    <DoubleServicesBox />
                    <ContactSection />
                </div>
            </main>
        </>
    );
};

export default Services;