import React from 'react';

// Component
import Logos from '../common/Logos';
import Title from '../shared/Title';

// Constant
import { trendTechData } from '../../constant/trendTechData';

const TrendTech = () => {

    const trendTech = trendTechData();

    return (
        <div className='content-section'>
            <span className='text-center'>
                <Title className='mb-12' type='golden' size='xs' headTagType='h3'>
                    What we use
                </Title>
                <Title className='mb-0 leading-3' type='stroke' size='lg' headTagType='h3'>
                    trending
                </Title>
                <Title type='normal' size='md' headTagType='h3'>
                    technologies
                </Title>
            </span>
            <Logos logosList={trendTech} />
        </div>
    );
};

export default TrendTech;