import React from 'react';

// Components
import Title from '../../components/shared/Title';
import Image from '../shared/Image';

const DescriptionCard = ({icon, title, description, cardSize}) => {
    return (
        <div className={`min-w-[300px] ${cardSize ? cardSize : 'w-48%'} flex flex-col h-full justify-between`}>
            <div>
                {
                    icon && 
                    <Image className='mb-4' height={''} src={icon} alt='icon' />
                }
                <Title className='mb-4' type='normal' size='sm' headTagType='h2'>
                    {title}
                </Title>
                <p className='text-neutral-light-600 text-base mb-6'>
                    {description}
                </p>
            </div>
        </div>
    );
};

export default DescriptionCard;