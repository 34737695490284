import { Helmet } from "react-helmet";
import Title from "../components/shared/Title";
import ellipseIcon from '../assets/icons/ellipse.svg';
import { Link } from "react-router-dom";
import ContactSection from "../components/common/ContactSection";

export default function Sitemap() {

    const data = [
        {
            title: "Web Development",
            to: '/web-development',
            data: []
        },{
            title: "Mobile App Development",
            to: '/app-development',
            data: []
        },{
            title: "Blockchain Development",
            to: '/blockchain',
            data: []
        },{
            title: "Game Development",
            to: '/game',
            data: []
        },{
            title: "UI/UX & Design",
            to: '/ui-design',
            data: []
        },{
            title: "Digital Marketing",
            to: '/digital-marketing',
            data: []
        },{
            title: "Services",
            to: '/services',
            data: []
        },{
            title: "Portfolio",
            to: '/portfolio',
            data: []
        },{
            title: "About FLLTECH",
            to: '/aboutus',
            data: [
                {
                    url: <Link className="fll-tech--sitemap-subitem text-white font-semibold" to={'/contact'}>Contact Us</Link>
                },
                {
                    url: <span className='fll-tech--sitemap-subitem text-white font-semibold'>Blog</span>
                },
                {
                    url: <Link className="fll-tech--sitemap-subitem text-white font-semibold" to={'/faq'}>FAQ</Link>
                }
            ]
        }
    ]
    return <>
        <Helmet>
            <title>Sitemap at FLLTECH</title>
            <meta name="description" content="Sitemap at FLLTECH" />
            <meta name="keywords" content="web Development , mobile app Development, Blockchain development" />
        </Helmet>
        <main className='overflow-hidden'>
            <div className='content-section'>
                <div className="w-full max-w-6xl px-6 text-start mb-10 md:mb-20 mt-8">
                    <Title className="-mt-4 mb-2 md:mb-8 text-xl" type="normal" size="fs" headTagType='h1'>
                        Sitemap
                    </Title>
                    <Title type="stroke" size="xl" headTagType='h1'>
                        FLLTECH
                    </Title>
                </div>
                <div className="w-full max-w-6xl px-6 text-start mb-10 md:mb-20 mt-2 space-y-4">
                    {data.map((v,i) => {
                        return <div className="fll-tech--sitemap-item" key={i}>
                            <Link className="fll-tech--sitemap-item-title" to={v.to}>{v.title}</Link>
                            <ul className="space-y-4">
                                {v.data.map((v2, i2) => {
                                    return <li className="flex items-center gap-x-2" key={i2}>
                                        <img src={ellipseIcon} alt='ellipse' />
                                        {v2.url}
                                    </li>
                                })}
                            </ul>
                        </div>
                    })}
                </div>
            </div>
            <ContactSection />
        </main>
    </>
}