export const getWebToolsItems = () => {
    const data = {
        frontend: {
            title: 'front end',
            items: [
                'JavaScript',
                'type script',
                'angular',
                'react js',
                'vue js',
                'backbone js',
                'ember.js',
                'bootstrap',
                'HTML5',
            ]
        },
        backend: {
            title: 'back end',
            items: [
                'laravel',
                'node.js',
                'GOLang',
                'python',
                'expressJs',
                'ruby on rails',
                'magento',
                'PHP',
            ]
        },
        database: {
            title: 'database',
            items: [
                'firebase',
                'redis',
                'postgreSQL',
                'mongoDB',
                'mySQL',
                'dynamocDB',
                'oracle',
                'SQLite',
            ]
        },
        designTools: {
            title: 'design tools',
            items: [
                'sketch',
                'adobe XD',
                'photoshop',
                'illustrator',
                'figma',
                'after effects',
                'inVision',
                'zeplin',
            ]
        },
    }
    return data;
}