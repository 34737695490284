import React from 'react';

// Components
import Title from '../shared/Title';
import GamesType from './GamesType';

// Images
import ellipseImg from '../../assets/imgs/blurEllipseRight.svg'
import pattern from '../../assets/imgs/pattern.png';

const GenresGame = () => {
    return (
        <>
            <div className='content-section'>
                <img className='absolute w-full object-cover bg-[#111111] hidden lg:block' src={pattern} alt='pattern' />
                <img className='absolute -top-full -right-0 hidden lg:block' src={ellipseImg} alt='ellipse' />
                <div className='relative w-full max-w-2xl z-10 mt-16'>
                    <Title type='golden' size='xs'>
                        GENRES
                    </Title>
                    <Title type='normal' size='md'>
                        Genres of Games
                        We Serve
                    </Title>
                    <p className='text-lg text-neutral-light-600'>
                        Our game development team puts in great effort to develop best-in-class mobile games of all genres that run seamlessly across Android and iOS devices.
                    </p>
                </div>
            </div>
            <div className='content-section'>
                <GamesType />
            </div>
        </>
    );
};

export default GenresGame;