import React from 'react';
import { v4 } from 'uuid';

// Components
import Title from '../shared/Title';
import Accordion from '../accordion/Accordion';

// Icon
import ellipseIcon from '../../assets/icons/ellipse.svg';

const FAQ = ({ FAQData }) => {

    return (
        <div className='content-section'>
            <div className='w-full max-w-xl'>
                <Title className='mb-12' type='golden' size='xs'>
                    FAQ
                </Title>
                {
                    FAQData.map(data => {
                        return (
                            <Accordion key={v4()}>
                                <Accordion.AccordionHeader>
                                    <h2 className='text-white text-xl lg:text-2xl font-medium'>
                                        {data.headerTitle}
                                    </h2>
                                </Accordion.AccordionHeader>
                                <Accordion.AccordionBody>
                                    <ul>
                                        <h4 className='text-base sm:text-lg font-normal text-neutral-light-600 mb-3'>{data.content.title}</h4>
                                        {data.content.items.map(item => {
                                            return (
                                                <li key={v4()} className='flex items-center gap-3'>
                                                    <img src={ellipseIcon} alt='ellipse' />
                                                    <span className='text-base sm:text-lg font-normal text-neutral-light-600 capitalize'>
                                                        {item}
                                                    </span>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </Accordion.AccordionBody>
                            </Accordion>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default FAQ;