import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';


const Div = styled.div`
    width: 230px;
    height: 230px;
    display: flex;
    padding: 0 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 30px;
    border: 1px solid #1A1A1A;
`;

const Span = styled.span`
    font-size: 24px;
    font-weight: bold;
    color: white;
    white-space: pre-wrap;
    text-align: center;
    text-transform: capitalize;
`;

const Img = styled.img`
    width: 50px;
    margin-bottom: 8px;
`;

const Card = ({ link, icon, title, ...rest }) => {

    return (
        <Link to={link}>
            <Div className={`bg-[#111111] ${rest.className}`}>
                <Img src={icon} alt='' />
                <Span>
                    {title}
                </Span>
            </Div>
        </Link>
    );
};

export default Card;