// Fintech Images
import fintechOne from '../../assets/imgs/web/solutions/fintech/fintech-one.webp';
import fintechTwo from '../../assets/imgs/web/solutions/fintech/fintech-two.webp';
import fintechThree from '../../assets/imgs/web/solutions/fintech/fintech-three.webp';

// ٍE-Commerce Images
import eOne from '../../assets/imgs/web/solutions/e-commerce/e-commerce-one.webp';
import eTwo from '../../assets/imgs/web/solutions/e-commerce/e-commerce-two.webp';
import eThree from '../../assets/imgs/web/solutions/e-commerce/e-commerce-three.webp';


// Healthcare Images
import healthcareOne from '../../assets/imgs/web/solutions/healthcare/healthcare-one.webp';
import healthcareTwo from '../../assets/imgs/web/solutions/healthcare/healthcare-two.webp';
import healthcareThree from '../../assets/imgs/web/solutions/healthcare/healthcare-three.webp';

// Fitness Images
import fitnessOne from '../../assets/imgs/web/solutions/finess/fitness-one.webp';
import fitnessTwo from '../../assets/imgs/web/solutions/finess/fitness-two.webp';
import fitnessThree from '../../assets/imgs/web/solutions/finess/fitness-three.webp';

// Food images
import foodOne from '../../assets/imgs/web/solutions/food/food-one.webp';
import foodTwo from '../../assets/imgs/web/solutions/food/food-two.webp';
import foodThree from '../../assets/imgs/web/solutions/food/food-three.webp';

// Real Estate Images
import estateOne from '../../assets/imgs/web/solutions/real-estate/real-estate-one.webp';
import estateTwo from '../../assets/imgs/web/solutions/real-estate/real-estate-two.webp';
import estateThree from '../../assets/imgs/web/solutions/real-estate/real-estate-three.webp';

// Travel Image
import travelOne from '../../assets/imgs/web/solutions/travel/travel-one.webp';
import travelTwo from '../../assets/imgs/web/solutions/travel/travel-two.webp';
import travelThree from '../../assets/imgs/web/solutions/travel/travel-three.webp';

// Social Netwroking Image
import socialOne from '../../assets/imgs/web/solutions/social-networking/social-networking-one.webp';
import socialTwo from '../../assets/imgs/web/solutions/social-networking/social-networking-two.webp';
import socialThree from '../../assets/imgs/web/solutions/social-networking/social-networking-three.webp';

// Business Image
import businessOne from '../../assets/imgs/web/solutions/business/business-one.webp';
import businessTwo from '../../assets/imgs/web/solutions/business/business-two.webp';
import businessThree from '../../assets/imgs/web/solutions/business/business-three.webp';

// Education Image
import educationOne from '../../assets/imgs/web/solutions/education/education-one.webp';
import educationTwo from '../../assets/imgs/web/solutions/education/education-two.webp';
import educationThree from '../../assets/imgs/web/solutions/education/education-three.webp';

// Exchange Image
import exchangeOne from '../../assets/imgs/web/solutions/exchange/exchange-one.webp';
import exchangeTwo from '../../assets/imgs/web/solutions/exchange/exchange-two.webp';
import exchangeThree from '../../assets/imgs/web/solutions/exchange/exchange-three.webp';

export const getWebSolutionData = () => {
    const data = [
        {
            id: 't1',
            title: 'Exchange',
            images: [exchangeOne, exchangeTwo, exchangeThree],
            description: 'Your users will trust you with secure and intelligent platforms, we provide multiple solutions for exchange and blockchain platforms.',
            transferMethod: ['Exchanges', 'Trade, Futures', 'Wallets',],
        },
        {
            id: 't12',
            title: 'Fintech',
            images: [fintechOne, fintechTwo, fintechThree],
            description: 'Make your customers digital banking and investing experience smooth without irritation like banking long queue lines.',
            transferMethod: ['Easy Money Transfer', 'Easy Investment & Guides', 'Manage Daily Expences And Offers',],
        },
        {
            id: 't2',
            title: 'E-commerce',
            images: [eOne, eTwo, eThree,],
            description: 'Your customers not only want great products but also highly personalised shopping experience. we will help you optimise efficiency across the value chain by making your business more responsive.',
            transferMethod: ['Manage Inventory', 'Sales Analytics', 'POS Solution',],
        },
        {
            id: 't3',
            title: 'Healthcare',
            images: [healthcareOne, healthcareTwo, healthcareThree],
            description: "let's take healthcare to the next level by using effective technologies, Drive-in more patients’ engagement with customer-centric apps with indomitable features to simplify their lives and become their health instructor.",
            transferMethod: ['Online Appointment', 'Track Daily Routine', 'Order Medicines Online',],
        },
        {
            id: 't4',
            title: 'fitness',
            images: [fitnessOne, fitnessTwo, fitnessThree],
            description: "All fitness freaks want a great instructor who can guide them better and track their daily activities. let's make fitness enthusiast's experience more energetic by taking your business online.",
            transferMethod: ['Online Training Sessions', 'Fitness Tracker', 'Smart Watch App',],
        },
        {
            id: 't5',
            title: 'Food & Drink',
            images: [foodOne, foodTwo, foodThree],
            description: 'Drive your potential customers into a comfort zone where ordering online food becomes a cakewalk with complete digital synced solutions in food delivery.',
            transferMethod: ['Order Tracking', 'In-App Payments', 'Realtime Tracking',],
        },
        {
            id: 't6',
            title: 'Real Estate',
            images: [estateOne, estateTwo, estateThree],
            description: 'When it comes to give convenient services to your customers to sell, buy or rent properties, with Real Estate App Development Solutions, you can accomplish all purposes to amplify your business in a contemporary way.',
            transferMethod: ['Easy Properties Listing', 'Overview Statistics', 'Advanced Search Criteria',],
        },
        {
            id: 't7',
            title: 'Travel',
            images: [travelOne, travelTwo, travelThree],
            description: 'Give your customers the best experience at their finger touch to make their travel experience more memorable. Customers like to use apps in which they can do everything from starting to the ending point.',
            transferMethod: ['Online Ticket / Hotel Booking', 'Tour Guide', 'Currency Converter',],
        },
        {
            id: 't9',
            title: 'Social Networking',
            images: [socialOne, socialTwo, socialThree],
            description: 'Social media app helps you to expand your business network, enhance brand loyalty and credibility, boost conversion rate and revenue as well as improve customer service and satisfaction.',
            transferMethod: ['Messaging, Calling App', 'Communities App', 'Job Posting App',],
        },
        {
            id: 't10',
            title: 'Business',
            images: [businessOne, businessTwo, businessThree],
            description: 'Using enterprise mobility solutions, businesses have tremendous opportunities to modernize their operations to defeat challenges, boost productivity, enrich customer services and earn more revenue.',
            transferMethod: ['Sales & Marketing', 'Employee Management', 'HR Portals',],
        },
        {
            id: 't11',
            title: 'Education',
            images: [educationOne, educationTwo, educationThree],
            description: 'After the covid pandemic, we all can see the raise in the online education platform. parents nowadays, allow even their children to use smart devices to download an educational-based app to enhance their learning habits.',
            transferMethod: ['Live Classes', 'E-Library for Managing Notes & Tutorials', 'Online Examination',],
        },
    ]
    return data
}