import React from 'react';
import PropTypes from 'prop-types';

const TabItem = ({ id, activeTab, setActiveTab, className, activeClass, children }) => {

    const clickHandler = () => {
        setActiveTab(id);
    }

    return (
        <button onClick={clickHandler} className={`${className} ${activeTab === id ? activeClass : ""}`}>
            <span className='text-[13px] sm:text-[16px] whitespace-nowrap'>
                {children}
            </span>
        </button>
    );
};

export default TabItem;

TabItem.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.string.isRequired,
    setActiveTab: PropTypes.func.isRequired,
    className: PropTypes.string,
    activeClass: PropTypes.string,
}