import React from 'react';
import lineImg from '../../assets/imgs/about-us/line.webp';
import Image from '../shared/Image';

const Box = ({ count, label, ...rest }) => {
    return (
        <div className='flex flex-col p-4 text-center border border-neutral-light-600 rounded-lg w-44 h-40' {...rest}>
            <h2 className='font-semibold text-3xl text-white mt-2'>
                {count}
            </h2>
            <p className='text-neutral-light-600  mt-3'>
                {label}
            </p>
        </div>
    )
}

const Statistics = () => {
    return (
        <div className='content-section'>
            <div className='w-full max-w-5xl'>
                <div className='flex md:flex-nowrap flex-wrap justify-start md:justify-end items-center w-full gap-8'>
                    <section className='md:mr-auto'>
                        <Image className='w-44 p-4' src={lineImg} alt='line' />
                    </section>
                    <Box count='56+' label='People Inhouse Team' />
                    <Box count='237+' label='Project Delivered' />
                    <Box count='150M+' label='Worth of Bonus Given to Team' />
                </div>
            </div>
        </div>
    );
};

export default Statistics;