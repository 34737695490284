import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import IsDevice from '../../helpers/isDevice'


function CursorCore({
    innerStyle,
    innerSize = 20,
    innerScale = 0.6,
    clickables = [
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link'
    ]
}) {
    const cursorInnerRef = useRef()
    const [isVisible, setIsVisible] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [isActiveClickable, setIsActiveClickable] = useState(false)
    const [cursorColor, setCursorColor] = useState('178, 158, 114')
    let endX = useRef(0)
    let endY = useRef(0)

    /**
     * Primary Mouse move event
     * @param {number} clientX - MouseEvent.clientx
     * @param {number} clientY - MouseEvent.clienty
     */
    const onMouseMove = useCallback(({ clientX, clientY }) => {
        cursorInnerRef.current.style.top = `${clientY}px`
        cursorInnerRef.current.style.left = `${clientX}px`
        endX.current = clientX
        endY.current = clientY
    }, [])


    // Mouse Events State updates
    const onMouseDown = useCallback(() => setIsActive(true), [])
    const onMouseUp = useCallback(() => setIsActive(false), [])
    const onMouseEnterViewport = useCallback((e) => {
        if (getComputedStyle(e.target).getPropertyValue('background-color') === 'rgb(178, 158, 114)') {
            setCursorColor('255, 255, 255');
        }
        setIsVisible(true)
    }, [])
    const onMouseLeaveViewport = useCallback(() => {
        setCursorColor('178, 158, 114');
        setIsVisible(false);
    }, [])

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mousedown', onMouseDown)
    document.addEventListener('mouseup', onMouseUp)
    document.addEventListener('mouseover', onMouseEnterViewport)
    document.addEventListener('mouseout', onMouseLeaveViewport)

    // Cursors Hover/Active State
    useEffect(() => {
        if (isActive) {
            cursorInnerRef.current.style.transform = `translate(-50%, -50%) scale(2.5)`;
        } else {
            cursorInnerRef.current.style.transform = 'translate(-50%, -50%) scale(1)'
        }
    }, [innerScale, isActive])

    // Cursors Click States
    useEffect(() => {
        if (isActiveClickable) {
            cursorInnerRef.current.style.transform = `scale(${innerScale * 10})`
        }
    }, [innerScale, isActiveClickable])

    // Cursor Visibility State
    useEffect(() => {
        if (isVisible) {
            cursorInnerRef.current.style.opacity = 1
        } else {
            cursorInnerRef.current.style.opacity = 0
        }
    }, [isVisible])

    useEffect(() => {
        const clickableEls = document.querySelectorAll(clickables.join(','))

        clickableEls.forEach((el) => {
            el.style.cursor = 'none'

            el.addEventListener('mouseover', (e) => {
                setIsActive(true)
            })
            el.addEventListener('click', () => {
                setIsActive(true)
                setIsActiveClickable(false)
            })
            el.addEventListener('mousedown', () => {
                setIsActiveClickable(true)
            })
            el.addEventListener('mouseup', () => {
                setIsActive(true)
            })
            el.addEventListener('mouseout', () => {
                setIsActive(false)
                setIsActiveClickable(false)
            })
        })

        return () => {
            clickableEls.forEach((el) => {
                el.removeEventListener('mouseover', () => {
                    setIsActive(true)
                })
                el.removeEventListener('click', () => {
                    setIsActive(true)
                    setIsActiveClickable(false)
                })
                el.removeEventListener('mousedown', () => {
                    setIsActiveClickable(true)
                })
                el.removeEventListener('mouseup', () => {
                    setIsActive(true)
                })
                el.removeEventListener('mouseout', () => {
                    setIsActive(false)
                    setIsActiveClickable(false)
                })
            })
        }
    }, [isActive, clickables])

    // Cursor Styles
    const styles = {
        cursorInner: {
            zIndex: 999,
            display: 'block',
            position: 'fixed',
            borderRadius: '50%',
            width: innerSize,
            height: innerSize,
            pointerEvents: 'none',
            backgroundColor: `rgba(${cursorColor}, 1)`,
            ...(innerStyle && innerStyle),
            transition: 'opacity 0.15s ease-in-out, transform 0.25s ease-in-out'
        },
    }

    // Hide / Show global cursor
    document.body.style.cursor = 'none'

    return (
        <React.Fragment>
            <div ref={cursorInnerRef} style={styles.cursorInner} />
        </React.Fragment>
    )
}

/**
 * AnimatedCursor
 * Calls and passes props to CursorCore if not a touch/mobile device.
 */
function AnimatedCursor({
    innerStyle,
    color,
    innerSize,
    innerScale,
    trailingSpeed,
    clickables
}) {

    if (typeof navigator !== 'undefined' && IsDevice.any()) {
        return <></>
    }

    return (
        <CursorCore
            innerStyle={innerStyle}
            color={color}
            innerSize={innerSize}
            innerScale={innerScale}
            trailingSpeed={trailingSpeed}
            clickables={clickables}
        />
    )
}

AnimatedCursor.propTypes = {
    color: PropTypes.string,
    innerSize: PropTypes.number,
    innerScale: PropTypes.number,
    innerStyle: PropTypes.object,
    trailingSpeed: PropTypes.number,
    clickables: PropTypes.array
}

export default AnimatedCursor