// Icons
import illustratorIcon from '../../assets/icons/services/adobe-illustrator.svg';
import blenderIcon from '../../assets/icons/services/blender.svg';
import diamondIcon from '../../assets/icons/services/diamond.svg';
import unityIcon from '../../assets/icons/services/unity.svg';
import unrealIcon from '../../assets/icons/services/unrealengine.svg';
import htmlIcon from '../../assets/icons/services/html.svg';
import pythonIcon from '../../assets/icons/services/python.svg';
import ccosIcon from '../../assets/icons/services/cocos2d-x.png';

export const getGameDevTools = () => {
    const data = [
        {
            "label": "unity",
            "icon": unityIcon,
        },
        {
            "label": "ccos2D-X",
            "icon": ccosIcon
        },
        {
            "label": "unreal engine",
            "icon": unrealIcon,
        },
        {
            "label": "html 5",
            "icon": htmlIcon,
        },
        {
            "label": "blender",
            "icon": blenderIcon,
        },
        {
            "label": "python",
            "icon": pythonIcon,
        },
        {
            "label": "sketch",
            "icon": diamondIcon,
        },
        {
            "label": "illustrator",
            "icon": illustratorIcon,
        },
    ]
    return data;

}