import React from "react";
import { Helmet } from "react-helmet";

// Component
import Title from "../components/shared/Title";
import DoubleImageCard from "../components/common/DoubleImageCard";
import ProductServiceItems from "../components/common/ProductServiceItems";
import Image from "../components/shared/Image";
import ContactSection from "../components/common/ContactSection";
import DoubleServicesBox from "../components/common/DoubleServicesBox";

// Constant
import { cardCoinIdea, cardCoinIdea2, cardDappsListing } from "../constant/blockchain/DoubleImageCardData";
import { getProductServiceData } from "../constant/blockchain/blockchainProductServiceData";

// Images
import ccexpressImg from "../assets/imgs/blockchain/ccexpress.webp";
import roomBgImg from '../assets/imgs/blockchain/room-bg.webp';

const Blockchain = () => {

    const coinIdeaData = cardCoinIdea();
    const coinIdeaData2 = cardCoinIdea2();
    const dAPPsListingData = cardDappsListing();
    const productServiceItem = getProductServiceData()

    return (
        <>
            <Helmet>
                <title>Leading the Way in Blockchain , Web3.0 Platform and Smart Contract</title>
                <meta name='description' content='Our blockchain experts help you develop your own cryptocurrency. We define customized properties for your token or coin and develop smart contracts & any web3 platforms' />
                <meta name="keywords" content="Blockchain , web3 , Smart Contract" />
            </Helmet>
            <main className="overflow-hidden">
                <div className="content-section md:mb-96 mt-0">
                    <div className="w-full max-w-fit mb-20 mt-44">
                        <Title type="stroke" size="xl">
                            blockchain
                        </Title>
                        <Title className="-mt-4 text-xl" type="normal" size="md">
                            solutions & development
                        </Title>
                    </div>
                    <div className="w-full max-w-4xl mb-14">
                        <Title type="golden" size="xs">
                            WHO WE ARE
                        </Title>
                        <Title className='relative z-0 md:mb-20' type="normal" size="sm">
                            Our Blockchain Developers provide Blockchain-Based Services Globally
                            Through Token Building, DeFi Platforms, Swap, NFT Marketplace, NFT
                            Games & All Services Related to Decentralized Projects
                        </Title>
                    </div>
                    <img className='w-[300px] sm:w-[400px] md:w-[500px] md:absolute -right-20 top-3/4' src={ccexpressImg} alt='express' />
                </div>
                <div className="content-section mb-0">
                    <div className="w-full max-w-5xl">
                        <Title type="golden" size="xs">
                            what we do
                        </Title>
                    </div>
                </div>
                <DoubleImageCard data={coinIdeaData} />
                <DoubleImageCard data={coinIdeaData2} />
                <ProductServiceItems productServices={productServiceItem} />
                <div className="content-section">
                    <div className="w-full max-w-5xl flex flex-col md:flex-row gap-6">
                        <article className="flex-1">
                            <Title type='normal' size='md' headTagType="h2">
                                roadmap
                            </Title>
                            <p className="text-lg text-neutral-light-600 mt-6">
                                Providing a long-term development strategy with targets and timelines is essential to attract major investors and absorb a significant fund for your project. In addition, the community's loyalty is strongly tied to providing short-term and mid-term goals that are achievable shortly to prove that you’re able to hit the bottom line of your targets. A well-designed roadmap with proper timelines is necessary to show your plans and strong will to the crypto community. FLLTech’s experienced team provides a professional roadmap that helps your project have a solid fundamental backbone to win the HODLers’ trust.
                            </p>
                        </article>
                        <article className="flex-1">
                            <Title type='normal' size='md' headTagType="h2">
                                Whitepaper
                            </Title>
                            <p className="text-lg text-neutral-light-600 mt-6">
                                The main task of a whitepaper is to achieve the audience’s trust and present accurate information about your project to be understandable by both a newbie user and a pro client. Nothing could be worst for your project than an unprofessionally designed whitepaper with a poor translation to English. Simultaneously, we can’t ignore the whitepaper role as a legal document, which is there to protect your organization in any judicial system and situation. A professional whitepaper helps you present your project in the best way and prevent any serious objections in the future. FLLTech has an experienced team of content creators, lawyers, and designers to prepare a professional whitepaper in any format, including WP layout.
                            </p>
                        </article>
                    </div>
                </div>
                <Image className='w-screen max-w-[1920px] object-contain mb-20' src={roomBgImg} alt="room bg" />
                <div className="content-section">
                    <article className="w-full max-w-5xl">
                        <Title className='w-full text-center' type='normal' size='md' headTagType="h2">
                            NFT Games & NFT Marketplace
                        </Title>
                        <p className="text-lg text-neutral-light-600 mt-6">
                            Our team unites skillful developers with solid experience in NFT game development services like creating tokens and building NFT gaming platforms. We would gladly give your business an exceptional opportunity to create your own marketplace and engage with thousands of users worldwide. NFT Marketplace allows you to create and sell digital products and assets from various categories to sell digital collectibles from a single storefront. FLLTech helps you to have a customized NFT Marketplace without any Blockchain expertise. Whatever your game genre is, we can always help you enrich it with digital features like gaming tools and assets. Characters’ peculiar features and powers, weapons, points – just to name a few. We generate custom tokens so that you can fully control the authentication process and ensure players’ satisfaction
                        </p>
                    </article>
                </div>
                <div className="content-section">
                    <DoubleImageCard data={dAPPsListingData} />
                </div>
                <DoubleServicesBox />
                <ContactSection />
            </main>
        </>
    );
};

export default Blockchain;
