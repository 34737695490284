import React, { useState, useRef } from 'react';
import { v4 } from 'uuid';

// Component
import TabContent from './TabContent';
import TabItem from './TabItem';
import Title from '../shared/Title';
import Image from '../shared/Image';

const SideTabs = ({ contentsList }) => {

    const detailsRef = useRef();
    const [activeTab, setActiveTab] = useState("t1");

    const closeDetailsMenuOnItemSelect = (ref) => {
        if (ref.current["open"]) {
            return ref.current["open"] = false;
        }
        return null
    }

    const tabTitle = contentsList.map(content => {
        return (
            <TabItem
                key={v4()}
                id={content.id}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                className='side--tab-item'
                activeClass='side--tab-active'>
                {content.title}
            </TabItem>
        )
    });

    const tabContent = contentsList.map(content => {
        return (
            <TabContent
                key={v4()}
                id={content.id}
                activeTab={activeTab}
                className='side--tab-content-container'>
                <section className='w-full sm:w-1/2'>
                    <Title type='normal' size='sm' className='mb-4' headTagType='h2'>
                        {content.title}
                    </Title>
                    <p className='side--tab-content'>
                        {content.description}
                    </p>
                    <ul className='flex flex-col gap-y-4'>
                        {
                            content.transferMethod.map(item => {
                                return (
                                    <li key={v4()} className='flex gap-2 text-white'>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 0L12.7577 7.24228L20 10L12.7577 12.7577L10 20L7.24228 12.7577L0 10L7.24228 7.24228L10 0Z" fill="#B29E72" />
                                        </svg>
                                        {item}
                                    </li>
                                )

                            })
                        }
                    </ul>
                </section>
                <div className='flex gap-3'>
                    <section className='flex flex-col basis-[100%]'>
                        <Image className='w-full rounded-xl' src={content.images[0]} alt='time & material' />
                    </section>
                    <section className='flex flex-col basis-[44.3333333%] gap-3'>
                        <Image className='h-full w-full object-cover rounded-xl' src={content.images[1]} alt='fix scope modal' />
                        <Image className='h-full w-full object-cover rounded-xl' src={content.images[2]} alt='hire team' />
                    </section>
                </div>
            </TabContent>
        )
    });

    return (
        <div className='md:flex block'>
            <div>
                <ul className='hidden md:sticky md:top-28 md:flex flex-col gap-y-2'>
                    {tabTitle}
                </ul>
                <details ref={detailsRef} className='relative block md:hidden my-4'>
                    <summary className='flex items-center justify-between text-secondary-500 capitalize appearance-none p-2 font-semibold border border-white rounded-lg'>
                        {
                            contentsList.map(content => {
                                return content.id === activeTab ? content.title : null;
                            })
                        }
                        <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L9 9L17 1" stroke="white" />
                        </svg>
                    </summary>
                    <ul onClick={() => closeDetailsMenuOnItemSelect(detailsRef)} className='absolute flex flex-col bg-neutral-dark-600 w-full p-1 rounded-md top-12 z-10'>
                        {tabTitle}
                    </ul>
                </details>
            </div>
            <div>
                {tabContent}
            </div>
        </div>
    );
};

export default SideTabs;