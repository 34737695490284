export const getWebBenefitsData = () => {
    const intro = {
        title: "Leverage Our Web Development Expertise to Support Business Growth",
        description: "As people spend more than 8 hours a day searching on the Internet, every business should have a web presence. Having a website for any type of business offers plenty of benefits. Here are some crucial reasons to develop a website for your business:"
    }
    const data = [
        {
            title: "Builds Creditability",
            description: "Having a website can help businesses increase flexibility and gain creditability. In the US, 84% of customers think that a business having a website is more credible than one that only has a page on social media."
        },
        {
            title: "Wider Audience Reach",
            description: "Having a website helps you expand your target audience and make deals with customers who are far away from your business location. Also, a website can be accessible 24*7 without any break or holidays."
        },
        {
            title: "Promote Your Business",
            description: "The website has been proved to be the best way to market your business online. As compared to traditional marketing, online marketing through the website of your business is quicker, more efficient, and cost-effective."
        },
        {
            title: "Keep Track of Your Business",
            description: "Another benefit of having a website is that you can keep track of your products or services in real-time by checking web visitor data. You can find which products drawing the most attention."
        },
        {
            title: "Improve Customer Service",
            description: "Customers always love instant feedback from businesses about the issue they face. A business website can help you improve your customer service by immediately processing customer's complaints and feedback."
        },
    ]
    return [intro, data];
}