import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Title from '../components/shared/Title';
import DoubleImageCard from '../components/common/DoubleImageCard';
import GetInTouch from '../components/common/GetInTouch';
import ServiceApproach from '../components/common/ServiceApproach';
import SolutionsSection from '../components/home/SolutionsSection';
import ServicesExplore from '../components/common/services-explore/ServicesExplore';
import ContactSection from '../components/common/ContactSection';

// Constant
import { getDigitalImageCards } from '../constant/digital/digitalDoubleImageCardData';
import { getDigitalApproachData } from '../constant/digital/digitalApproachData';
import { solutionData } from '../constant/solutionData';

const DigitalMarketing = () => {

    const digitalDoubleImageData = getDigitalImageCards();
    const approachData = getDigitalApproachData();
    const solutionsData = solutionData();

    return (
        <>
            <Helmet>
                <title>Driving Business Growth with Effective Digital Marketing Strategies</title>
                <meta name='description' content='Discover top digital marketing services with us! We provide data-driven marketing strategies and creative growth solutions. Contact us now!' />
                <meta name='keywords' content='Digital marketing , SMM , Seo' />
            </Helmet>
            <main className='overflow-hidden'>
                <div className='content-section mt-0'>
                    <div className="w-full max-w-fit mb-20 mt-44">
                        <Title type="stroke" size="xl">
                            digital
                        </Title>
                        <Title className="-mt-4 text-xl" type="normal" size="md">
                            marketing
                        </Title>
                    </div>
                    <div className="w-full max-w-4xl mb-14">
                        <Title type="golden" size="xs">
                            what we do
                        </Title>
                        <Title className='relative z-0' type="normal" size="sm">
                            Our marketing specialists help you with the consultant, market analysis, and advertising in the related industry. We believe that our success is bound with assisting other businesses to grow better every day.
                        </Title>
                    </div>
                </div>
                <DoubleImageCard data={digitalDoubleImageData} />
                <GetInTouch />
                <ServiceApproach data={approachData} />
                <SolutionsSection data={solutionsData} />
                <ServicesExplore />
                <ContactSection />
            </main>
        </>
    );
};

export default DigitalMarketing;