// Web Images
import firstWebImg from '../assets/imgs/solutions/web/web-first.webp';
import secondWebImg from '../assets/imgs/solutions/web/web-second.webp';
import thirdWebImg from '../assets/imgs/solutions/web/web-third.webp';

// App Images
import firstAppImg from '../assets/imgs/solutions/app/app-first.webp';
import secondAppImg from '../assets/imgs/solutions/app/app-second.webp';
import thirdAppImg from '../assets/imgs/solutions/app/app-third.webp';

// Blockchain Images
import firstBlockchainImg from '../assets/imgs/solutions/blockchain/blockchain-first.webp';
import secondBlockchainImg from '../assets/imgs/solutions/blockchain/blockchain-second.webp';
import thirdBlockchainImg from '../assets/imgs/solutions/blockchain/blockchain-third.webp';

// Game Images
import firstGameImg from '../assets/imgs/solutions/game/game-.webp';
import secondGameImg from '../assets/imgs/solutions/game/game-second.webp';
import thirdGameImg from '../assets/imgs/solutions/game/game-third.webp';

// DigitalMarketing Images
import firstDmImg from '../assets/imgs/solutions/digital-marketing/digitalmarketing-first.webp';
import secondDmImg from '../assets/imgs/solutions/digital-marketing/digitalmarketing-second.webp';
import thirdDmImg from '../assets/imgs/solutions/digital-marketing/digitalmarketing-third.webp';

// UI/UX Images
import firstUiImg from '../assets/imgs/solutions/ui-ux/uiux-first.webp';
import secondUiImg from '../assets/imgs/solutions/ui-ux/uiux-second.webp';
import thirdUiImg from '../assets/imgs/solutions/ui-ux/uiux-third.webp';

// Graphic Images 
import firstGraphicImg from '../assets/imgs/solutions/graphic/graphic-first.webp';
import secondGraphicImg from '../assets/imgs/solutions/graphic/graphic-second.webp';
import thirdGraphicImg from '../assets/imgs/solutions/graphic/graphic-third.webp';

export const solutionData = () => {
    const data = [
        {
            id: 't1',
            title: 'web development',
            images: [firstWebImg, secondWebImg, thirdWebImg],
            description: 'FLLTech has gathered an expert team of Front-End and Back-End developers to provide professional web-based services, including web design, web publishing, web programming, and database management.',
            transferMethod: ['Web Applications', 'DeFi Websites', 'Learning Websites',],
        },
        {
            id: 't2',
            title: 'App & dApp Development',
            images: [firstAppImg, secondAppImg, thirdAppImg],
            description: 'Our experienced developers help you own an outstanding mobile application for your business. ',
            transferMethod: ['IOS Apps', 'Android Apps', 'Smart Watch Apps',],
        },
        {
            id: 't3',
            title: 'Blockchain Development',
            images: [firstBlockchainImg, secondBlockchainImg, thirdBlockchainImg],
            description: 'FLLTech’s blockchain department provides all blockchain-based services and customized platforms according to clients’ needs.',
            transferMethod: ['DAPPs', 'Blockchain-Based Games', 'NFT Marketplace',],
        },
        {
            id: 't4',
            title: 'Game Development',
            images: [firstGameImg, secondGameImg, thirdGameImg],
            description: 'FLLTech game development team builds all kinds of 2D, 3D, and VR/AR games for all platforms. From idea to design, we help you develop your experience in the game industry.',
            transferMethod: ['2D Games', '3D Games', 'VR/AR Games',],
        },
        {
            id: 't5',
            title: 'Digital Marketing',
            images: [firstDmImg, secondDmImg, thirdDmImg],
            description: 'To bring your business to the next level, our marketing analysts and advisors find the potential features of your project and utilize them to build up your brand successfully. Our services in marketing include:',
            transferMethod: ['SEO', 'Advertising', 'Branding',],
        },
        {
            id: 't6',
            title: 'UI/UX Design',
            images: [firstUiImg, secondUiImg, thirdUiImg],
            description: 'We implement the most suitable UI/UX design based on research on your users. As a result, you will experience significant growth in the number of your website/application users.',
            transferMethod: ['Mobile App UI/UX Design', 'Website UI/UX Design', 'Games UI/UX Design',],
        },
        {
            id: 't7',
            title: 'Graphic Design',
            images: [firstGraphicImg, secondGraphicImg, thirdGraphicImg],
            description: 'To build a brand successfully, graphic design plays an essential unignoring role. Our graphic designers create a professional artistic theme, logo, etc., for your digital entity/product/ service that best presents your brand.',
            transferMethod: ['Logo & Icon Design', 'Illustration Design', 'Social Media Theme Design',],
        },
    ]
    return data
}