import iosIcon from '../../assets/icons/services/apple-logo.svg';
import androidIcon from '../../assets/icons/services/android-logo.svg';
import flutterIcon from '../../assets/icons/services/flutter.svg';
import reactIcon from '../../assets/icons/services/react.svg';

export const getAppPlatformLogos = () => {
    const logos = [
        {
            "icon": androidIcon,
            "subtitle": "Lassi",
            "label": "android/kotlin",
        },
        {
            "icon": iosIcon,
            "subtitle": "Lassi",
            "label": "ISO/Swift",
        },
        {
            "icon": flutterIcon,
            "subtitle": "Lassi",
            "label": "Flutter",
        },
        {
            "icon": reactIcon,
            "subtitle": "Lassi",
            "label": "React Native",
        },
    ]
    return logos;
}