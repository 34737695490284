import metabixMarketplace from '../../assets/imgs/portfolio/blockchain/metabix-marketplace.webp';
import metabixExplorer from '../../assets/imgs/portfolio/blockchain/metabix-explorer.webp';
import snakeRiderMarketplace from '../../assets/imgs/portfolio/blockchain/snakerider-marketplace.webp';
import gngswap from '../../assets/imgs/portfolio/blockchain/gng-swap.webp';
import metavita from '../../assets/imgs/portfolio/blockchain/metavita.webp';
import bixbpay from '../../assets/imgs/portfolio/blockchain/bixbpay.webp';
import aio from '../../assets/imgs/portfolio/blockchain/aio.webp';
import celestial from '../../assets/imgs/portfolio/blockchain/celestial.webp';


import figmaIcon from '../../assets/icons/services/figma-2.svg';
import htmlIcon from '../../assets/icons/services/html.svg';
import jsIcon from '../../assets/icons/services/js.svg';
import phpIcon from '../../assets/icons/services/php.png';
import unityIcon from '../../assets/icons/services/unity.svg';
import solidityIcon from '../../assets/icons/services/solidity.svg';
import reactIcon from '../../assets/icons/services/react.svg';

export const getBlockchainPorfolioData = () => {
    const data = [
        {
            title: "Metabix Marketplace",
            tags: ["Design", "Frontend", "Backend",],
            description: "This project has been done by FLLTECH developers for client who was looking for Metaverse land marketplace , They allow users to access different details related to Metabix metaverse lands & assets or buy & sell in virtual reality world, this platform is blockchain base.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": reactIcon, },
                { "icon": jsIcon, },
                { "icon": htmlIcon, },
                { "icon": phpIcon, },
            ],
            image: metabixMarketplace,
            link: ""
        },
        {
            title: "Metabix Explorer",
            tags: ["Design", "Frontend", "Backend",],
            description: "This project has been done by FLLTECH developers for client who was looking for cryptocurrency explorer , They allow users to access different details related to transactions on specific wallet addresses and blockchains including amount transacted, sources and destination of funds, and status of the transactions.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": reactIcon, },
                { "icon": jsIcon, },
                { "icon": phpIcon, },
            ],
            image: metabixExplorer,
            link: ""
        },
        {
            title: "Snakerider Marketplace",
            tags: ["Solidity", "Backend", "Frontend",],
            description: "This platform gives opportunity  to SnackRider  users easy and fast access to NFT's different collections which users can benefit from it in the game. Users can buy and trade different NFT's like Snake skins, characters, ...",
            icons: [
                { "icon": unityIcon, },
                { "icon": figmaIcon, },
                { "icon": solidityIcon, },
                { "icon": jsIcon, },
            ],
            image: snakeRiderMarketplace,
            link: "https://market.snakerider.io/"
        },
        {
            title: "Gamengain (GNG) Swap",
            tags: ["Frontend", "Backend",],
            description: "GNG swap is a  facilitate the instant exchange of two non-native tokens between two unique blockchain protocols without the need of commencing the traditional crypto-to-fiat exchange or token migration. It allows users to swap tokens directly from the official private key wallet or the trading account.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": reactIcon, },
                { "icon": jsIcon, },
            ],
            image: gngswap,
            link: "https://swap.gamengain.io/"
        },
        {
            title: "Metavita",
            tags: ["Design", "Frontend", "Backend",],
            description: "Metavita is a digital artist and FLLTECH Company create a Website for him in order to make an easy platform to show his artworks to the world and improve his carrier in the art business.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": htmlIcon, },
                { "icon": jsIcon, },
                { "icon": phpIcon, },
            ],
            image: metavita,
            link: "Metavita.io"
        },
        {
            title: "BIXB Pay",
            tags: ["Design", "Frontend"],
            description: "Our web developers create an API payment gateway For bixb coin so you can purchase online using bixb coin this payment gate way is blockchain based and has a strong security to create a safe platform.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": htmlIcon, },
                { "icon": jsIcon, },
            ],
            image: bixbpay,
            link: "https://bixbpay.com/"
        },
        {
            title: "AIO Token",
            tags: ["Design", "Frontend", "Smart Contracts"],
            description: "Introducing an innovative ERC20 token with custom methods, wallet freezing, phased distribution, a pre-sale contract, and staking for a robust crypto experience. ",
            icons: [
                { "icon": figmaIcon, },
                { "icon": reactIcon, },
                { "icon": jsIcon, },
                { "icon": solidityIcon, },
            ],
            image: aio,
            link: "https://www.allinonetoken.io/"
        },
        {
            title: "Celestial Angels",
            tags: ["Smart Contracts"],
            description: "We designed a dynamic NFT ecosystem, comprising two ERC721-compliant collections developed with Solidity. Seamlessly integrated with React, users can easily interact with the blockchain.",
            icons: [
                { "icon": solidityIcon, },{ "icon": reactIcon, },
            ],
            image: celestial,
            link: "https://celestial-angles.vercel.app/"
        },
    ];
    return data;
}