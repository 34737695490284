import React from 'react';

// Component
import Title from '../shared/Title';
import Tabs from '../tabComponent/Tabs';

// Constant
import { tabData } from '../../constant/businessModelData';

const BusinessSection = () => {

    const tabContent = tabData();

    return (
        <div className='content-section z-[10]'>
            <div className='w-full max-w-5xl'>
                <Title type='golden' size='xs' headTagType='h2'>
                    business model
                </Title>
                <Title type='normal' size='md' className='mb-4' headTagType='h3'>
                    our business models
                </Title>
                <Tabs contentsList={tabContent} />
            </div>
        </div>
    );
};

export default BusinessSection;