import React from 'react';
import PropTypes from 'prop-types';

const TabContent = ({ id, activeTab, className, children }) => {
    return (
        activeTab === id ?
            <div className={className}>
                {children}
            </div> :
            null
    );
};

export default TabContent;

TabContent.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
}