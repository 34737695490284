import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Title from '../components/shared/Title';
import ContactForm from '../components/forms/ContactForm';

// Icons
import callIcon from '../assets/icons/contact/call.svg';
import directIcon from '../assets/icons/contact/direct.svg';

const Contact = () => {
    return (
        <>
            <Helmet>
                <title>Contact FLLTECH for Web development and Mobile App Development</title>
                <meta name="description" content="Contact us for your web development , mobile app development, and UI/UX design needs. We will get back to you as soon as possible." />
                <meta name="keywords" content="web Development , mobile app Development" />
            </Helmet>
            <main className='overflow-hidden'>
                <div className='content-section mt-0'>
                    <div className="w-full max-w-5xl grid place-items-center mt-44">
                        <Title type="stroke" size="xl">
                            Contact
                        </Title>
                        <p className='text-neutral-light-600 text-lg w-full max-w-2xl'>
                            Get in touch with us for all your staffing requirements and hire our optimal services for getting the project developments done with your budget estimate. We believe in building business partnerships that go long-term.
                        </p>
                    </div>
                    <div className='w-full max-w-7xl flex flex-col-reverse md:flex-row md:items-center md:justify-between mt-16'>
                        <div className='flex flex-col min-h-[380px] justify-between mt-10 md:mt-0'>
                            <section className='space-y-9 mb-7'>
                                <Title type='golden' size='xs'>
                                    get in touch
                                </Title>
                                <Title type='normal' size='sm'>
                                    For work inquiry
                                </Title>
                                <ul className='flex my-4 gap-8 flex-wrap items-start'>
                                    <li className='space-y-4'>
                                        <a href='mailto:info@flltech.io' className='contact-section--navlink' target='_blank' rel='noreferrer'>
                                            <img src={directIcon} alt='direct' />
                                            info@flltech.io
                                        </a>
                                        <a href='mailto:sales@flltech.io' className='contact-section--navlink' target='_blank' rel='noreferrer'>
                                            <img src={directIcon} alt='direct' />
                                            sales@flltech.io
                                        </a>
                                    </li>
                                    <li className='space-y-4'>
                                        <a href="tel:0037493687737" className='contact-section--navlink'>
                                            <img src={callIcon} alt='call' />
                                            +374-93 687 737
                                        </a>
                                        <a href="tel:0037441144143" className='contact-section--navlink'>
                                            <img src={callIcon} alt='call' />
                                            +374-41 144 143
                                        </a>
                                    </li>
                                </ul>
                            </section>
                            <section className='space-y-7'>
                                <Title type='golden' size='xs'>
                                    GLOBAL PRESENCE
                                </Title>
                                <ul className='flex flex-wrap gap-10'>
                                    <li className='text-neutral-light-600'>
                                        <Title type='normal' size='xs'>
                                            UK
                                        </Title>
                                        <p>
                                            United Kingdom
                                            <br />
                                            64a cumberland street edinburgh
                                            <br />
                                            United Kingdom EH3 6RE
                                        </p>
                                    </li>
                                    <li className='text-neutral-light-600'>
                                        <Title type='normal' size='xs'>
                                            armenia
                                        </Title>
                                        <a href='https://www.google.com/maps/dir//FLLTECH,+65+Orbeli,+Yerevan+0039/@40.1929957,44.4878839,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x406abd5c65f442ad:0xca25225f2795e9ff!2m2!1d44.4878839!2d40.1929957' target='_blank' rel="noreferrer">
                                            Armenia
                                            <br />
                                            65 Orbeli, Yerevan 0039
                                            <br />
                                            Yerevan,Armenia
                                        </a>
                                    </li>
                                </ul>
                            </section>
                        </div>
                        <ContactForm />
                    </div>
                </div>
            </main>
        </>
    );
};

export default Contact;