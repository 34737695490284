import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

// Pages
import Home from './pages/Home';
import Services from './pages/Services';
import Blockchain from './pages/Blockchain';
import Game from './pages/Game';
import Design from './pages/Design';
import AppDev from './pages/AppDev';
import WebDev from './pages/WebDev';
import DigitalMarketing from './pages/DigitalMarketing';
import Portfolio from './pages/Portfolio';
import About from './pages/About';
import Contact from './pages/Contact';
import PageNotFound404 from './pages/PageNotFound404';

// Components
import ScrollToTop from './components/shared/ScrollToTop';
import FAQ from './pages/FAQ';
import Sitemap from './pages/Sitemap';

const AllRoutes = () => {
    return (
        <ScrollToTop>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/services' element={<Services />} />
                <Route path='/blockchain' element={<Blockchain />} />
                <Route path='/game' element={<Game />} />
                <Route path='/ui-design' element={<Design />} />
                <Route path='/app-development' element={<AppDev />} />
                <Route path='/web-development' element={<WebDev />} />
                <Route path='/digital-marketing' element={<DigitalMarketing />} />
                <Route path='/portfolio' element={<Portfolio />} />
                <Route path='/aboutus' element={<About />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/faq' element={<FAQ />} />
                <Route path='/sitemap' element={<Sitemap />} />
                <Route path='*' element={<PageNotFound404/>} />
                </Routes>
        </ScrollToTop>
    );
};

export default AllRoutes;