// Images
import projectAppImg1 from '../../assets/imgs/about-us/project-app1.webp';
import projectAppImg2 from '../../assets/imgs/about-us/project-app2.webp';

export const getAboutImageCards = () => {
    const data = [
        {
            'title': '',
            'subtitle': 'project app',
            'img': projectAppImg1,
            'description': 'UI/UX Design, Mobile App Development'
        },
        {
            'title': '',
            'img': projectAppImg2,
            'subtitle': 'project app',
            'description': 'UI/UX Design, Mobile App Development'
        },
    ]
    return data
}