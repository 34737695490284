export const getWebToChooseCategoriesData = () => {

    const data = [
        {
            title: 'smart',
            description: 'Smart coding is to avoid all the technical risk right away while writing the code and make it fault resistant. We plan the actions and how users could react before writing the code.'
        },
        {
            title: 'beautiful',
            description: 'Beautiful Code is the code that seems professional and understandable. It is written as per the widely accepted coding standard.'
        },
        {
            title: 'reusable',
            description: 'The reusability of code reduces redundancy and development time. The optimization of code is very important and we do it by having reusability of the code.'
        },
        {
            title: 'Stable',
            description: 'We take utter care of code stability before releasing any of the products. We use various testing tools to check the stability of code as well as products.'
        },

    ]
    return data;
}