import React from 'react';
import { v4 } from 'uuid';
import { Link } from 'react-router-dom';
import Button from '../shared/Button';
import styled from 'styled-components';
import Logos from '../common/Logos';
import Title from '../shared/Title';
import ellipseIcon from '../../assets/icons/ellipseFilled.svg';

const Div = styled.div`
    width: 100%;
    
    @media (min-width: 650px) {
        max-width: 320px;
        &:not(:first-child) {
            margin-left: 18px;
        }
    }
`;

const Ul = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const Li = styled.li`
    display: flex;
    align-items: center;
    gap: 14px;
    text-transform: capitalize;
    color: white;
    white-space: pre-wrap;
`;

const ListView = ({ data, hasExploreLink, children }) => {

    const { title, items, icons, exploreUrl } = data;

    return (
        <Div className='space-y-5'>
            {
                icons &&
                <Logos logosList={icons} logoSize='w-14 h-14' isFlex />
            }
            <section className='min-h-[40px] sm:min-h-[60px]'>
                {
                    hasExploreLink ?
                        <Link to={`/${exploreUrl}`}>
                            <Button Btype='basic' size='lg'>
                                {title}
                            </Button>
                        </Link> :
                        <Title type='normal' className='text-[24px] sm:text-[34px]' headTagType='h2'>
                            {title}
                        </Title>
                }
            </section>
            <Ul>
                {
                    items.map(item => {
                        return (
                            <Li key={v4()}>
                                <img src={ellipseIcon} alt='ellipse' />
                                <span className='text-sm xs:text-base whitespace-pre-wrap'>
                                    {item}
                                </span>
                            </Li>
                        )
                    })
                }
            </Ul>
            <section>
                {children}
            </section>
        </Div>
    );
};

export default ListView;