// Icons
import aftereffectIcon from '../../assets/icons/services/Adobe-after-effects.svg';
import illustratorIcon from '../../assets/icons/services/adobe-illustrator.svg';
import psIcon from '../../assets/icons/services/adobe-photoshop.svg';
import xsIcon from '../../assets/icons/services/adobe-xd.svg';
import androidIcon from '../../assets/icons/services/android-logo.svg';
import appleIcon from '../../assets/icons/services/apple-logo.svg';
import blenderIcon from '../../assets/icons/services/blender.svg';
import diamondIcon from '../../assets/icons/services/diamond.svg';
import flutterIcon from '../../assets/icons/services/flutter.svg';
import goIcon from '../../assets/icons/services/flutter.svg';
import jsonIcon from '../../assets/icons/services/json.svg';
import laravelIcon from '../../assets/icons/services/laravel.svg';
import nodeIcon from '../../assets/icons/services/nodejs.svg';
import pythonIcon from '../../assets/icons/services/python.svg';
import reactIcon from '../../assets/icons/services/react.svg';
import rustIcon from '../../assets/icons/services/rust.svg';
import solidityIcon from '../../assets/icons/services/solidity.svg';
import unityIcon from '../../assets/icons/services/unity.svg';
import unrealIcon from '../../assets/icons/services/unrealengine.svg';
import yulIcon from '../../assets/icons/services/yul.svg';
import figmaIcon from '../../assets/icons/services/figma.svg';
import prototypeIcon from '../../assets/icons/services/prototype-design.svg';
import researchIcon from '../../assets/icons/services/app-research.svg';
import appUiIcon from '../../assets/icons/services/ui-interaction.svg';
import appUxIcon from '../../assets/icons/services/ui-ux.svg';
import webInteractionIcon from '../../assets/icons/services/web-interaction.svg';
import responsiveDesignIcon from '../../assets/icons/services/responsive-design.svg';
import uiUxWebIcon from '../../assets/icons/services/ui-ux-web.svg';
import vueIcon from '../../assets/icons/services/vue.svg';
import jsIcon from '../../assets/icons/services/js.svg'
import angularIcon from '../../assets/icons/services/angular.svg'

export const getServicesData = () => {
    const designData = {
        "ui": {
            "title": "UI/UX Design",
            "icons": [
                { icon: figmaIcon },
                { icon: diamondIcon },
                { icon: xsIcon },
            ],
            "items": ['interface design', 'interaction design', 'research', 'prototyping'],
        },
        "graphic": {
            "title": "Graphic Design",
            "icons": [
                { icon: illustratorIcon },
                { icon: psIcon },
                { icon: aftereffectIcon }],
            "items": ['illustratoin design', 'motion graphics & animations', 'icon design', 'logo design'],
        },
        "bgGradient": "__design-gradient"
    }
    const gameDevData = {
        "gameDev": {
            "title": "game development",
            "icons": [
                { icon: unityIcon },
                { icon: blenderIcon },
                { icon: unrealIcon }
            ],
            "items": ['Mobile Game Development', 'Web Game Development', 'MMORPG', 'First person - Third person shooter', 'Play2Earn Games Development'],
        },
        "unityDev": {
            "title": "game development",
            "icons": [{ icon: unityIcon }],
            "items": ['Unity Development', 'Unity Synthetic Data', 'Unity Computer vision', 'Unity Simulation', 'Industrial augmented reality'],
        },
        "bgGradient": "__game-dev-gradient"
    }
    const appDevData = {
        "title": "App & dApp Development",
        "link": {
            "url": "app-development",
            "text": "explore all mobile services"
        },
        tabData: [
            {
                'tabId': 't1',
                'title': 'development',
                'content': [
                    {
                        'label': 'IOS app development',
                        "icon": appleIcon
                    },
                    {
                        'label': 'android app development',
                        "icon": androidIcon
                    },
                    {
                        'label': 'flutter app development',
                        "icon": flutterIcon
                    },
                    {
                        'label': 'react native app development',
                        "icon": reactIcon
                    },
                ]
            },
            {
                'tabId': 't2',
                'title': 'design',
                'content': [
                    {
                        'label': 'app research',
                        "icon": researchIcon
                    },
                    {
                        'label': 'UI/UX design',
                        "icon": appUxIcon
                    },
                    {
                        'label': 'UI interaction',
                        "icon": appUiIcon
                    },
                    {
                        'label': 'prototype design',
                        "icon": prototypeIcon
                    },
                ]
            },
        ],
        "bgGradient": "__app-dev-gradient"
    }
    const webDevData = {
        "title": "Web development",
        "link": {
            "url": "web-development",
            "text": "explore all web services",
        },
        tabData: [
            {
                'tabId': 't1',
                'title': 'front-end',
                'content': [
                    {
                        'label': 'angularJs development',
                        "icon": angularIcon
                    },
                    {
                        'label': 'reactJs development',
                        "icon": reactIcon
                    },
                    {
                        'label': 'vueJs development',
                        "icon": vueIcon
                    },
                    {
                        'label': 'javascript development',
                        "icon": jsIcon
                    },
                ]
            },
            {
                'tabId': 't2',
                'title': 'back-end',
                'content': [
                    {
                        'label': 'nodeJs development',
                        "icon": nodeIcon
                    },
                    {
                        'label': 'laravel development',
                        "icon": laravelIcon
                    },
                    {
                        'label': 'golang development',
                        "icon": goIcon
                    },
                    {
                        'label': 'python development',
                        "icon": pythonIcon
                    },
                ]
            },
            {
                'tabId': 't3',
                'title': 'design',
                'content': [
                    {
                        'label': 'UI/UX design',
                        "icon": uiUxWebIcon
                    },
                    {
                        'label': 'web interaction',
                        "icon": webInteractionIcon
                    },
                    {
                        'label': 'responsive design',
                        "icon": responsiveDesignIcon
                    },
                ]
            },
        ],
        "bgGradient": "__web-dev-gradient",
    }
    const blockchaninData = {
        "blockchain": {
            "title": "Blockchain & Web3.0",
            'items': ["Blockchain Development", "Defi Platforms Develoment", "P2P cryptocurrency Exchange", "Dapp Development", "Metaverse Development", "Crypto Payment Gateway"],
        },
        "explore": {
            "title": "Explore all Blockchain/DeFi/Dapps",
            "exploreUrl": 'blockchain',
            'items': ["NFT MarketPlace Development", "Swap Development", "NFT Games Development", "SmartContract", "Create your Own token", "Web 3.0 Platform Development", "Cryptocurrency Wallet Development"],
        },
        "bgGradient": "__blockchain-gradient",
        "icons": [
            {
                'icon': solidityIcon,
                'label': "solidity"
            },
            {
                'icon': rustIcon,
                'label': "rust"
            },
            {
                'icon': yulIcon,
                'label': "Yul"
            },
            {
                'icon': jsonIcon,
                'label': "json"
            },
        ],
    }
    const marketingData = {
        "digital": {
            "title": "digital marketing",
            'items': ["Search Engine Optimization (SEO)", "Franchise SEO", "Local SEO", "branding", "Link Building", "Social Media Marketing (SMM)"],
        },
        "explore": {
            "title": "Explore all Digital Marketing",
            "exploreUrl": 'digital-marketing',
            'items': ["Pay Per Click (PPC) Management", "Content Writing", "Video Production", "Conversion Rate Optimization (CRO)", "eCommerce Marketing", "Email Marketing"],
        },
        "bgGradient": "__digital-gradient",
    };
    return {
        designData: designData,
        gameDevData: gameDevData,
        appDevData: appDevData,
        webDevData: webDevData,
        blockchaninData: blockchaninData,
        marketingData: marketingData
    };
}