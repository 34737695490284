import React from 'react';
// Icon
import arrowLeft from '../../assets/icons/arrow-left.svg';

const PrevSlideBtn = (props) => {
    const { onClick } = props;
    return (
        <button onClick={onClick} className='max-h-fit max-w-fit absolute top-1/2 z-10 left-2 -translate-y-1/2 bg-[#111111] border border-secondary-500 hover:bg-secondary-500 rounded-full p-1 md:p-2 lg:translate-y-44 lg:left-10'>
            <img src={arrowLeft} alt='arrowleft' />
        </button >
    );
};

export default PrevSlideBtn;