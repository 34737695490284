import React from 'react';
import Button from '../shared/Button';
import { Link } from 'react-router-dom';

const DoubleServicesBox = () => {
    return (
        <div className='content-section'>
            <div className='w-full max-w-4xl flex flex-col sm:flex-row items-center'>
                <section className='w-full max-w-lg p-8 sm:p-16 bg-neutral-dark-600'>
                    <p className='text-white text-lg mb-6'>
                        Want to
                        <span className='font-bold mx-2'>
                            hire resources
                        </span>
                        to work with you?
                    </p>
                    <Button Btype='secondary' size='lg'>
                        Explore Hiring Services
                    </Button>
                </section>
                <section className='w-full max-w-lg p-8 sm:p-16 gradient-4-to-r'>
                    <p className='text-white text-lg mb-6'>
                        Looking for
                        <span className='font-bold mx-2'>
                            UI/UX & Graphic Design
                        </span>
                        Solutions?
                    </p>
                    <Link to='/ui-design'>
                        <Button Btype='secondary' size='lg'>
                            Explore Design Services
                        </Button>
                    </Link>
                </section>
            </div>
        </div>
    );
};

export default DoubleServicesBox;