import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/imgs/logo.png';

const Navbar = ({ open, toggler }) => {

    return (
        <nav className={`nav-overlay ${open ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0`}>
            <button onClick={toggler} className='z-10 md:hidden'>
                <svg xmlns="http://www.w3.org/2000/svg" className={`h-8 w-8 stroke-white absolute right-4 top-8`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>

            <ul className='h-full flex flex-col items-center justify-center gap-6 md:flex-row md:gap-2 lg:gap-4'>
                <NavLink to={'/'}>
                    <img className='mx-auto md:hidden w-32' src={logo} alt="FLLTECH-logo" />
                </NavLink>
                <NavLink to={'/'}>
                    <li className='nav-item'>Home</li>
                </NavLink>
                <NavLink to={'/services'}>
                    <li className='nav-item'>Services</li>
                </NavLink>
                <NavLink to='/portfolio'>
                    <li className='nav-item'>Portfolio</li>
                </NavLink>
                <NavLink to={'/faq'}>
                    <li className='nav-item'>FAQ</li>
                </NavLink>
                <NavLink to={'/aboutus'}>
                    <li className='nav-item'>About us</li>
                </NavLink>
                <NavLink to={'/contact'}>
                    <li className='nav-item md:hidden'>Contact us</li>
                </NavLink>
            </ul>
        </nav>
    );
};

export default Navbar;