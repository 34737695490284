import { useEffect, useState } from "react";

export const useScrollDirection = () => {

    const [scrollDirection, setScrollDirection] = useState(null);

    useEffect(()=>{
        let lastScrollY = window.scrollY;

        const updateScrollDirection = () => {
            const scrollY = window.scrollY;
            const direction = scrollY > lastScrollY ? "down" : "up";
            if (direction !== scrollDirection && (scrollY - lastScrollY > 15 || scrollY - lastScrollY < -15)) {
                setScrollDirection(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };

        window.addEventListener('scroll', updateScrollDirection);
        return (()=>{
            window.removeEventListener('scroll', updateScrollDirection);
        })

    },[scrollDirection])

    return scrollDirection;

};