export const getAppApproachData = () => {
    const data = [
        {
            title: "Agile Methodologies",
            description: "Agile methodology is a project management approach widely used for the software development process. It is an approach where requirements and solutions evolve throughout the process by the collaborative effort of self-organizing cross-functional teams and their customers.",
        },
        {
            title: "Cost Efficient",
            description: "Our team analyzes business requirements and shares the latest and most compatible solution in a cost-effective way. We suggest the technology stake as per the requirement and provide the best solution at an optimum cost.",
        },
        {
            title: "Transparency",
            description: "We believe in transparency and that’s the reason our clients trust us the most. All the processes and codes are visible (On GIT) to the client whenever they wish and nothing is hidden from them.",
        },
        {
            title: "Flexibility",
            description: "We offer flexibility in many ways. Flexible in terms of coding, flexible in terms of working hours, flexible in terms of methodology to follow and 24X7 support.",
        },
    ]
    return data;
}