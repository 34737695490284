export const getProductServiceData = () => {
    const data = {
        title: "Power Up with Our High Performing Game Development Services",
        description: "With a successful record of stunning game development for both iOS and Android platforms, FLLTech has grown to be a leading mobile game development company. Our experienced team of game developers creates incredible user interfaces that run smoothly on all platforms.",
        items: [
            "Mobile Game Design",
            "Mobile Game Development",
            "Unity 3D Game Development",
            "AR / VR Game Development",
            "2D and 3D Game Development",
            "Cocos2D Game Development",
            "Unreal Engine Game Development",
            "Barriers and regulatory restrictions.",
            "Barriers and regulatory restrictions.",
            "HTML5 Game Development",
            "Play2Earn Games Development",
        ]
    }

    return data;
};