import React from 'react';

const GameTypesCard = ({ title, image }) => {
    return (
        <div className='relative w-[220px] md:w-full border border-neutral-dark-600 bg-[#111111]'>
            <div className='opacity-70 md:opacity-0 md:hover:opacity-70 transition-opacity duration-700'>
                <img className='game-genre object-cover' src={image} alt={`FLLTECH-${title}`} loading="lazy" />
            </div>
            <h2 className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-base text-neutral-light-600 capitalize whitespace-nowrap font-bold'>
                {title}
            </h2>
        </div>
    );
};

export default GameTypesCard;