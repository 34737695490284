export const getWebToChooseData = () => {

    const intro = {
        title: '',
        intro: 'Being a well-known web app development company, FLLTECH is recognized for serving modern business needs and delivering the best-in-class solutions at a competitive cost across the globe. Here’s why we can be the best choice when it comes to building websites and apps:'
    };

    const data = [
        {
            title: 'Customer Satisfaction',
            description: 'We work on the latest frameworks and technologies to deliver you user-friendly, scalable, secure, and new business solutions as per your need.'
        },
        {
            title: 'Agile Procedure',
            description: 'We work on the Agile methodology and conduct frequent scrum events for optimizing the practices and methods and delivering the finest results.'
        },
        {
            title: 'Dedicated Teams',
            description: 'We have a dedicated team of UI/UX designers who are well versed in delivering high-end business solutions on several tools and technologies.'
        },
        {
            title: 'Integrity & Transparency',
            description: 'We respect your ideas and vision, provide you every small project detail regularly, and consider your worthy advice when and as needed.'
        },
        {
            title: 'Flexible Engagement Models',
            description: 'Instead of working on unwanted rigid and strict parameters, we offer flexibility to our clients to select from different engagement and hiring models.'
        },
        {
            title: 'Competitive Pricing',
            description: 'We offer an ideal combination of cost-effective rates with amazing quality to make sure the lowest pricing in our segment.'
        },
    ]
    return { intro, data };
}