import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Image = ({ src, alt, className, ...rest }) => {
    return (
        <LazyLoadImage className={className}  height={'100%'} effect='blur' src={src} alt={alt} {...rest} />
    );
};

export default Image;