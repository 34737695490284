export const getAppToDoData = () => {
    const data = [
        {
            title: "Native App Development",
            description: "Native mobile apps are developed for specific mobile OS such as iOS or Android and users can access them from dedicated app stores.",
        },
        {
            title: "Cross-platform App Development",
            description: "Cross-platform mobile apps are developed to function for multiple mobile platforms like iOS, Android, etc. without changing the codebase.",
        },
    ]
    return data;
}