import React from 'react';

// Components
import GameTypesCard from './GameTypesCard';

// Constant
import { getGameTypes } from '../../constant/game/gameTypesdata';

const GamesType = () => {

    const gameTypeData = getGameTypes();

    return (
        <div className='content-section'>
            <div className='w-full max-w-5xl'>
                <div className='grid grid-flow-col md:grid-flow-dense md:grid-cols-4 md:gap-0 md:overflow-hidden py-2 gap-x-7 overflow-x-scroll overflow-y-hidden'>
                    {
                        gameTypeData.map((game)=>{
                            return <GameTypesCard key={game.id} title={game.title} image={game.image} />
                        })  
                    }
                </div>
            </div>
        </div>
    );
};

export default GamesType;