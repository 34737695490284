import React from 'react';
import style from './YearsAnimation.module.css';

import dottedEllipse from '../../assets/imgs/about-us/dotted-ellipse.svg';

const YearsAnimation = ({ year = "2" }) => {
    return (
        <div className='relative w-[18rem] flex justify-center'>
            <svg className={`${style.svg} animate-spin `} viewBox="0 0 100 100" width="100" height="100">
                <defs>
                    <path id="circle"
                        d=" M 50, 50
                            m -37, 0
                            a 37,37 0 1,1 74,0
                            a 37,37 0 1,1 -74,0"/>
                </defs>
                <text fontSize="6.2">
                    <textPath xlinkHref="#circle">
                        {year} years successfully {year} years successfully
                    </textPath>
                </text>
            </svg>
            <section className='absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-[12rem] h-[12rem]'>
                <img className='w-full animate-spin-reverse' src={dottedEllipse} alt='ellipse' />
            </section>
            <span className='absolute text-white font-bold text-7xl -rotate-[15deg] top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                {year}
            </span>
        </div >
    );
};

export default YearsAnimation;