import React, { lazy, Suspense } from 'react';

// Components
import Title from '../shared/Title';
import Loading from '../common/Loading';

// Images
import cryptoImage from '../../assets/imgs/cryptocurrency.png';
import bigRightArr from '../../assets/icons/big-right-arrow.svg';
import ellipseImg from '../../assets/imgs/blurEllipseLeft.svg';
import { Link } from 'react-router-dom';

// Video
const PortfolioVideo = lazy(() => import('./PortfolioVideo'));

const PortfolioSection = () => {
    return (
        <div className='content-section'>
            <img className='absolute -top-1/2 -left-0 hidden lg:block' src={ellipseImg} alt='ellipse' />
            <div className='relative w-full max-w-5xl flex flex-col items-center md:flex-row md:justify-between gap-y-10 z-[1]'>
                <div>
                    <section className='mb-4'>
                        <Title type='golden' size='xs' headTagType='h2'>our work</Title>
                        <Title type='normal' size='md' headTagType='h3'>
                            portfolio
                        </Title>
                    </section>
                    <section className='w-full xs:w-72 md:w-[345px] lg:w-[447px]'>
                        <img className='mb-4' src={cryptoImage} alt="crypto network" />
                        <p className='text-white text-2xl'>
                            Building Your Own Cryptocurrency Couldn’t Be Any Easier!
                        </p>
                    </section>
                </div>
                <div>
                    <section className='relative w-[290px] h-[290px] sm:w-[380px] sm:h-[380px] lg:w-[500px] lg:h-[500px] mb-8 md:mb-20'>
                        <div className='w-full h-full rounded-full border border-neutral-dark-100 opacity-30'>
                        </div>
                        <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[92%] h-[92%] rounded-full border border-neutral-dark-100 opacity-60'>
                        </div>
                        <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[84%] h-[84%] rounded-full border border-neutral-dark-100'>
                        </div>
                        <div className='overflow-hidden absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[76%] h-[76%] rounded-full bg-neutral-dark-600'>
                            <Suspense fallback={<Loading />}>
                                <PortfolioVideo />
                            </Suspense>
                        </div>
                    </section>
                    <Link to='/portfolio'>
                        <Title type='stroke' size='md' headTagType='h3'>
                            <p className='text-stroke hover:text-white font-black'>view
                                <span className='flex items-center gap-4'>portfolio <img src={bigRightArr} alt='right arrow' /></span>
                            </p>
                        </Title>
                    </Link>
                </div>
            </div >
        </div >
    );
};

export default PortfolioSection;