export const ButtonType = {
    primary: "flex items-center justify-center gap-1 py-1 px-3 text-[14px] text-[#111111] capitalize bg-secondary-500 rounded-full",
    secondary: "flex items-center justify-center gap-1 py-1 px-3 text-[14px] text-neutral-dark-600 capitalize font-medium bg-white rounded-full",
    basic: "flex items-center justify-center gap-1 py-1 px-3 text-[14px] text-white capitalize bg-transparent border border-white rounded-full",
}

export const ButtonSize = {
    xs: "min-w-[35px] min-h-[35px]",
    sm: "w-[125px] h-[48px]",
    md: "w-[165px] h-[40px]",
    lg: "min-w-[215px] h-[40px]"
}

export const TitleType = {
    basic: "text-white font-light z-10",
    normal: "text-white font-bold first-letter:uppercase z-10",
    stroke: "text-stroke z-10 mb-8",
    golden: "text-secondary-500 uppercase font-normal tracking-[.7em] z-10",
}

export const TitleSize = {
    xs: 'text-[16px]',
    sm: 'text-[18px] md:text-[28px]',
    md: 'text-[24px] xs:text-[41px] lg:text-[64px]',
    lg: 'text-[64px] md:text-[114px]',
    xl: 'text-[41px] sm:text-[64px] md:text-[98px] lg:text-[150px] leading-10 sm:leading-[3rem] md:leading-[4.5rem] lg:leading-[6.5rem]',
    dxl: 'text-[110px] md:text-[120px] leading-[6rem] md:leading-[6.5rem]',
    fs: 'text-[34px] xs:text-57 lg:text-[64px]'
}