// Icons
import aftereffectIcon from '../../assets/icons/services/Adobe-after-effects.svg';
import illustratorIcon from '../../assets/icons/services/adobe-illustrator.svg';
import psIcon from '../../assets/icons/services/adobe-photoshop.svg';
import xsIcon from '../../assets/icons/services/adobe-xd.svg';
import diamondIcon from '../../assets/icons/services/diamond.svg';
import figmaIcon from '../../assets/icons/services/figma.svg';

export const getDesignToolsItems = () => {
    const data = {
        uiux: {
            title: 'UI/UX',
            items: [
                'Sketch',
                'Adobe XD',
                'Photoshop',
                'Illustrator',
                'Figma'
            ]
        },
        prototyping: {
            title: 'Prototyping',
            items: [
                'Adobe XD',
                'Invision Studio',
                'Sketch',
                'Figma'
            ]
        },
        motionGraphic: {
            title: 'Motion Graphics/Animations',
            items: [
                'AfterEffects',
                'Adobe Animate'
            ]
        },
        collaboration: {
            title: 'Collaboration',
            items: [
                'Zeplin',
                'Invision',
                'Adobe Cloud'
            ]
        },
        illustrator: {
            title: 'Illustrator& Graphic Design',
            items: [
                'Illustrator',
                'Photoshop',
                'Sketch'
            ]
        },
        icons: [
            {
                "label": "",
                "icon": aftereffectIcon
            },
            {
                "label": "",
                "icon": illustratorIcon
            },
            {
                "label": "",
                "icon": psIcon
            },
            {
                "label": "",
                "icon": xsIcon
            },
            {
                "label": "",
                "icon": diamondIcon
            },
            {
                "label": "",
                "icon": figmaIcon
            },
        ]
    }
    return data;
}