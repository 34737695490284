import { useState, useEffect } from "react";

export const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleUserScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleUserScroll);

        return () => {
            window.removeEventListener('scroll', handleUserScroll);
        }

    }, []);

    return scrollPosition;

}
