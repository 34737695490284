import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Hooks
import { useIsInViewport } from '../../hooks/useIsInViewport';

// Components
import Image from '../shared/Image';

const Div = styled.div`
    position: relative;
    max-height: 25rem;
    width: 70rem;
    bottom: 0;
    left: 5rem;
    margin-left: auto;
    display: none;

    @media (min-width: 1124px) {
        display: grid;
        place-items: end;
    }
`;

const ServiceImage = ({ imageSrc, scrollToLeft = false, scrollable = false, rotatable = false }) => {

    const ref = useRef();
    const isVisible = useIsInViewport(ref);
    const [scrollPosition, setScrollPosition] = useState(0);
    // eslint-disable-next-line
    const [scrollPosition2, setScrollPosition2] = useState(0);
    const [scrollPositionCheker, setScrollPositionCheker] = useState(0);

    const getCurrentOffset = () => {
        if (isVisible && (scrollable || rotatable)) {
            setScrollPosition(window.scrollY)
        }
    }

    useEffect(() => {
        if (scrollable) {
            setScrollPosition2(window.scrollY - scrollPositionCheker)
        } else {
            setScrollPositionCheker(window.scrollY)
        }
        setScrollPosition2(window.scrollY)

        // eslint-disable-next-line
    }, [scrollPosition]);

    window.addEventListener('scroll', getCurrentOffset)


    return (
        <Div ref={ref} style={{ transform: `${scrollable ? `translate(${scrollPosition / scrollToLeft ? -1 : 1}px, -${scrollPosition * 0.3}px) rotate(0deg)` : ''}` }}>
            <Image style={{ transform: `${rotatable ? `rotate(-${scrollPosition * 0.03}deg)` : ''}` }} className={`${scrollable ? 'w-full' : ''}`} src={imageSrc} alt='' />
        </Div>
    );
};

export default ServiceImage;

ServiceImage.propTypes = {
    scrollable: PropTypes.bool,
    rotatable: PropTypes.bool,
    scrollToLeft: PropTypes.bool,
    imageSrc: PropTypes.string.isRequired
}