import React from 'react';
import Button from '../shared/Button';

// Icons
import arrowUpIcon from '../../assets/icons/arrow-up.svg';
import arrowDownIcon from '../../assets/icons/arrow-down.svg';

const AccordionButton = ({ isExpanded, toggleExpand }) => {
    return (
        <Button className='min-w-max' Btype='secondary' size='xs' onClick={toggleExpand}>
            <img className='w-6 h-10' src={isExpanded ? arrowUpIcon : arrowDownIcon} alt="chevron" />
        </Button>
    );
};

export default AccordionButton;