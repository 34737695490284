import React from 'react';

// Components
import Title from '../shared/Title';
import ServiceToolsItem from '../common/service-tools/ServiceToolItem';

// Constants
import { getAppToolsItems } from '../../constant/app-dev/appToolsItemData';

const AppDevTools = () => {

    const appToolsItems = getAppToolsItems();
    const {
        ios,
        android,
        crossPlatform,
        database,
        ui_ux,
    } = appToolsItems;

    return (
        <div className='content-section'>
            <div className='w-full max-w-5xl'>
                <Title type='golden' size='xs'>
                    technologies
                </Title>
                <section className='w-full flex flex-col sm:flex-row sm:flex-wrap sm:justify-between gap-8 gap-x-12 my-10'>
                    <div className='space-y-6'>
                        <ServiceToolsItem data={ios} />
                        <ServiceToolsItem data={android} />
                    </div>
                    <ServiceToolsItem data={crossPlatform} />
                    <ServiceToolsItem data={database} />
                    <ServiceToolsItem data={ui_ux} />
                </section>
            </div>
        </div>
    );
};

export default AppDevTools;