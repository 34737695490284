import React from 'react';
import { v4 } from 'uuid';

// Icons
import ellipseIcon from '../../../assets/icons/ellipse.svg';

const ServiceToolsItem = ({data, ...rest}) => {
    return (
        <div className={`${rest.className} w-full max-w-fit`}>
            <h1 className='mb-2 text-neutral-light-600 text-sm'>
                {data.title}
            </h1>
            <ul className='flex flex-col items-start gap-y-3'>
                {
                    data.items.map(item=>{
                        return (
                            <li key={v4()} className='flex items-center gap-x-2'>
                                <img src={ellipseIcon} alt='ellipse' />
                                <span className='text-base md:text-lg text-white font-semibold whitespace-nowrap'>
                                    {item}
                                </span>
                            </li>
                        )
                    })
                }
            </ul>
        </div>  
    );
};

export default ServiceToolsItem;