export const getProductServiceData = () => {
    const data = {
        title: "Cutting-edge Web Development Services You Need for Your Business Growth.",
        description: "FLLTech offers high-quality web app development services by keeping targeted customers in mind for startups, mid-sized companies, and enterprises. As a reputed web development company in India, we have a dedicated team of web developers who can work with latest technology and you can hire web developer for your project need. We are renowned custom web app development company, offering wide range of web development services that caters to all your business needs:",
        items: [
            "Website Design and Development",
            "Web Portal Development",
            "Custom Application Development",
            "Ecommerce Development",
            "CMS Development",
            "ERP Solutions",
            "ERP Solutions",
        ]
    }

    return data;
};