import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Title from '../components/shared/Title';
import ContactSection from '../components/common/ContactSection';
import DoubleImageCard from '../components/common/DoubleImageCard';
import ServiceApproach from '../components/common/ServiceApproach';
import AboutBackground from '../components/about/AboutBackground';
import Statistics from '../components/about/Statistics';
import Achievement from '../components/about/Achievement';
import Culture from '../components/about/Culture';

// Constant
import { getAboutImageCards } from '../constant/about-us/aboutDoubleImageCardData';
import { getAboutApproachData } from '../constant/about-us/aboutApproachData';
import MarqueeGallery from '../components/about/MarqueeGallery';

const About = () => {

    const doubleImageData = getAboutImageCards();
    const aboutApproachData = getAboutApproachData();

    return (
        <>
            <Helmet>
                <title>FLLTECH | Building the Future of Digital Experiences for Businesses</title>
                <meta name='description' content='FLLTech is an IT company in the field of App, DApp, web, gaming, blockchain, web 3.0 & DeFi platform development, and digital marketing.' />
                <meta name='keywords' content='web development , Blockchain development' />
            </Helmet>
            <main className='overflow-hidden'>
                <div className='content-section mt-0'>
                    <div className="w-full max-w-fit mb-20 mt-44">
                        <Title type="stroke" size="xl" headTagType='h1'>
                            About us
                        </Title>
                    </div>
                    <div className="w-full max-w-5xl mb-14">
                        <Title type="golden" size="xs" headTagType='h2'>
                            what WE do
                        </Title>
                        <Title className='relative z-0' type="normal" size="sm" headTagType='h3'>
                            Every company has a story behind its name, and in this case, we have ours too! About 4 years ago, we found each other in a completely different situation.
                        </Title>
                        <Title className='relative z-0 my-10' type="normal" size="sm" headTagType='h3'>
                            Each of us had a dream, great goals, a strong will, with a focused and learning mindset, but there wasn’t any desirable platform to try for the bigger picture. Therefore, we decided to share our thoughts to build a bright future and find a way that leads us to where we want to be. In this regard, we gathered in a friendly meeting and planned for starting our cooperation.
                        </Title>
                        <p className='text-neutral-light-600 text-lg'>
                            We brought up the idea of FLLTech, which is a gaming, programming & blockchain development company. Our team includes programmers, game developers, designers, content creators, marketing experts, blockchain engineers, and strategists who are professionally trained in the related fields. There is one thing that each one of us believes deeply, and that’s the power of effort. We never give up and put our best into what we do because we know an excellent way to grow our business.
                            <br />
                            <br />
                            One where what’s good for the bottom line is also good for the customer, and we believe our success is inbound by helping other companies grow better every day.
                            We try to be a trusted digital partner for growing startups and enterprise companies.
                        </p>
                    </div>
                </div>
                <ServiceApproach data={aboutApproachData} />
                <AboutBackground />
                <Statistics />
                <Achievement />
                <div className='content-section'>
                    <div className='flex flex-col w-full max-w-5xl'>
                        <Title type='golden' size='xs' headTagType='h3'>
                            OUR ACHIEVEMENT
                        </Title>
                        <a href='https://dribbble.com/FLLTECH' className='text-stroke hover:text-white font-black text-6xl md:text-[92px]'>
                            dribbble
                        </a>
                        <a href='https://www.behance.net/flltech' className='text-stroke hover:text-white font-black text-6xl md:text-[92px]'>
                            behance
                        </a>
                        <a href='https://github.com/FLLTECH' className='text-stroke hover:text-white font-black text-6xl md:text-[92px]'>
                            github
                        </a>
                    </div>
                </div>
                <DoubleImageCard data={doubleImageData} />
                <Culture />
                <MarqueeGallery />
                <ContactSection />
            </main>
        </>
    );
};

export default About;