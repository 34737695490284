import React from 'react';

// Components
import Title from '../shared/Title';
import DesignService from './DesignService';

// Constant
import { getUiServicesData, getGraphicServicesData } from '../../constant/graphic/designData';

const DesignServices = () => {

    const uiData = getUiServicesData();
    const graphicData = getGraphicServicesData();

    return (
        <div className='content-section'>
            <div className='w-full max-w-5xl'>
                <Title className='mb-4' type='golden' size='xs'>
                    services
                </Title>
                <div className='flex flex-col mb-20'>
                    <DesignService data={uiData} />
                </div>
                <div className='flex flex-col'>
                    <DesignService data={graphicData} />
                </div>
            </div>
        </div>
    );
};

export default DesignServices;