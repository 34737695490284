import { Helmet } from "react-helmet"
import Title from "../components/shared/Title"
import { useState } from "react"
import ContactSection from "../components/common/ContactSection"

const FAQ = () => {

    const [ currentTab, setCurrentTab ] = useState('development')

    const handleShow = (e, elID) => {
        
        const el = document.getElementById(elID);
        if ( el.className == 'answer active') {
            el.classList.remove("active")
        }else {
            el.classList.add("active")
        }
    }

    const faqDevData = [
        {
            question: "What technologies do you guys work on?",
            answer: <>
                <li>We work on all current and trending technologies, including web and mobile app development.</li>
                <li>Web: ReactJS, NodeJS, Laravel,  PHP,  WordPress, Magento, CakePHP,  Zend, ASP.NET, Codeignitor, AngularJS.</li>
                <li>Mobile: iOS native, Android native, flutter, React Native.</li>
            </>
        },{
            question: "What is the difference between Cross-platform development and Native App development?",
            answer: <>
                <li>We use the default language and framework for iOS and Android, namely Objective C/ Swift with xCode for iOS and JAVA and Kotlin with Android Studio for Android.</li>
                <li>Cross-platform technology provides a framework in which coding is done once, but the app can be deployed on iOS, Android, and Windows phones.</li>
            </>
        },{
            question: "What is the distinction between the MEAN and LAMP stacks?",
            answer: <>
                <li>MEAN is a database, back-end coding, and front-end coding combination that stands for MongoDB, ExpressJS, AngularJS, and NodeJS.</li>
                <li>LAMP is an acronym that stands for Linux, Apache, MySQL, and PHP, which is an open-source proven technology for developing server-side coding.</li>
            </>
        },{
            question: "Which platform should we focus on? Is it better to use iOS or Android, or both?",
            answer: <>
                <li>Given the increasing popularity of both iPhones and the most recent Android smartphones, we recommend launching the product on both platforms.</li>
            </>
        },{
            question: "Should I have a website with all of the same features as the application?",
            answer: <>
                <li>It is not required to have a website with the same features as the application. You can start with a one-page website and work your way up to app development. When you gain traction, you can create a responsive website with the same features as the application.</li>
            </>
        },{
            question: "Will my website function and look good across all devices and desktop operating systems?",
            answer: <>
                <li>Yes, we will create a responsive website using the most recent CSS 3 to ensure that it looks good in all desktop browsers as well as mobile devices and operating systems.</li>
            </>
        },{
            question: "Can I review the code during the development process?",
            answer: <>
                <li>We do not provide source code before payment completion to protect our rights. We can still provide you with an image of one or two coding chunks to ensure your satisfaction. </li>
            </>
        },{
            question: "How much does it cost to open a developer account with Apple and Google?",
            answer: <>
                <li>You must pay $99 per year to open an Apple developer account.</li>
                <li>You must pay $25 once for an Android developer account.</li>
            </>
        },{
            question: "What should my server requirements be?",
            answer: <>
                <li>The server company and server parameters can be defined based on traffic expectations, with prices ranging from $10 USD to $100 USD per month.</li>
            </>
        },{
            question: "Should we support both the iOS iPad version and the Android tablet version?",
            answer: <>
                <li>Yes, if your budget allows it, you should have a specialized iPad and tablet app.</li>
            </>
        },{
            question: "Would you help us to create a developer account?",
            answer: <>
                <li>Yes, we will walk you through the process of entering account information and agreeing to payment terms to complete the developer account sign-up.</li>
            </>
        },{
            question: "What will happen to my app with new OS releases on both iOS and Android?",
            answer: <>
                <li>With new OS releases, you should first test the app in the new OS and document any issues. Once completed, you should contact the development company that created it so that they can provide a quick solution. Apple and Google do disseminate solutions for deprecated methods and functions.</li>
            </>
        }
    ]

    const faqHireData = [
        {
            question: "I am the CTO, and I want to manage the team independently. Is it possible?",
            answer: <>
                <li>Of course. You will have direct access to a development team with the "Hire a team" model. "Pick your team, assign tasks, and plan the sprint"; anything is possible. This business model best suits CTOs/technical personnel and enterprise-level firms that require constant system changes.</li>
            </>
        },{
            question: "How should I go about hiring a developer or team?",
            answer: <>
                <li>First and foremost, you should gather all of the tasks that you want the developer to complete for you.</li>
                <li>Finalize the agreement with the sales team based on the chosen developer and technology.</li>
                <li>We will set everything up and connect you and the developer once you confirm.</li>
            </>
        },{
            question: "Do you have your own development facility, or do you work on a contract basis?",
            answer: "We have our development center in Yerevan, Armenia, and we do not subcontract."
        },{
            question: "Will my hired developers be able to communicate effectively and speak English?",
            answer: "Yes, all of our developers communicate well in English."
        },{
            question: "Is it necessary for me to sign a contract during the hiring period?",
            answer: "We provide complete assurance in the hiring period contract. As a result, the contract has no set length."
        },{
            question: "Will the hired developer work solely for me?",
            answer: "Yes, the developer you hire will work for you full-time, just like any other regular employee. "
        },{
            question: "Is it possible to hire a developer on an hourly or project basis?",
            answer: <>
                <li>Yes, if you know what you want the developer to do, you can hire them on an hourly or project/task basis. We also provide the option to change the hiring model at a later stage.</li>
            </>
        },{
            question: "Do you have a price list based on the skills and experience of developers?",
            answer: <>
                <li>Yes, we have a pricing structure based on skills and experience. If you send us your requirements, we will make recommendations on how we can assist you.</li>
            </>
        },{
            question: "How do I communicate with and instruct my hired developer?",
            answer: <>
                <li>You can reach out to the developers through Skype, Telegram, WhatsApp, or any other instant messaging service. You can share the task or instructions via Instant Messenger, email, phone, or any other method that is convenient for you.</li>
            </>
        },{
            question: "Will you replace my developer if I am not satisfied with the performance?",
            answer: <>
                <li>Yes. We will definitely replace your developer if we see that there is a performance issue on the developer’s end.</li>
            </>
        },{
            question: "How is reporting structured? How frequently am I going to be up-to-date?",
            answer: <>
                <li>Hired developers send the reports via email on a daily basis. If the client desires another update, that can also be arranged on mutual agreement.</li>
            </>
        },{
            question: "What if the developer is absent due to illness or leaves the company?",
            answer: <>
                <li>No worries. If the primary developer is ill or on vacation, we can assign an alternate. If a developer wishes to leave the company, we have a three-month notice period during which we manage to transfer the knowledge base to another developer while ensuring that none of our clients' work suffers.</li>
            </>
        },{
            question: "What is the minimum size of a team that I must hire?",
            answer: <>
                <li>There are no hiring restrictions. Depending on your needs, you can hire a single developer or a team of developers. If you are unsure of what you require, please contact sales@FLLTECH.com.</li>
            </>
        },{
            question: "Can we hire a developer on a weekly basis?",
            answer: <>
                <li>Yes, we have contract flexibility, and we provide developers on a weekly and monthly basis. </li>
            </>
        },{
            question: "How can we pay?",
            answer: "You have the option of paying with cryptocurrency or wire transfer. Each invoice will include the bank or cryptocurrency wallet transfer information."
        },{
            question: "When does a developer start working after finishing the commercial process?",
            answer: "The developer will begin working on your task within two business days."
        },{
            question: "Are you willing to participate in a test task to evaluate your skill set?",
            answer: "Yes, with a minimal development charge, you can test our skills before making a final move. Please note that for such tasks, we work with 100% upfront payment only."
        }
    ]

    const faqBisunessData = [
        {
            question: "How can I make my app successful?",
            answer: <>
                <li>The success of an application is solely dependent on a targeted market and marketing to gain users. To gain traction, it is critical to have a good marketing plan and budget in place, in addition to a great product.</li>
            </>
        },{
            question: "I have a unique app idea. How can I share it with you to make sure it is secured?",
            answer: <>
                <li>FLLTECH can provide you with a signed NDA to ensure that your idea is protected and not shared with anyone other than the development team.</li>
            </>
        },{
            question: "What is the average cost of developing an application?",
            answer: <>
                <li>Because each application has its own set of features and complexity, application development can cost anywhere from $5,000 USD to $500,000 USD or more, depending on the scope and complexity.</li>
            </>
        },{
            question: "What is the payment policy?",
            answer: <>
                <li>The project payment would be defined in milestones, and upon completion of each milestone, which is approving the agreed-upon piece of work, you would complete the cost for that milestone.</li>
            </>
        },{
            question: "Will I need to test my app thoroughly?",
            answer: <>
                <li>We have an in-house testing team that will test the system alongside the developer; however, you can always check the design and provide feedback.</li>
            </>
        },{
            question: "Could you assist us in configuring our server?",
            answer: <>
                <li>We can run basic commands to configure your server at no extra charge. You can hire our server experts on a contract basis for more extensive support.</li>
            </>
        },{
            question: "Would you handle submitting the app to the app store, or would I be responsible for that?",
            answer: <>
                <li>Yes, that is a free service included in our contract. </li>
            </>
        },{
            question: "What steps would you take to ensure data security?",
            answer: <>
                <li>We use a secured authentication token system along with data storage with encryption for sensitive data.</li>
            </>
        },{
            question: "How frequently would I receive updates?",
            answer: <>
                <li>You will be given a project plan with approximate dates for deliverables at the start of the project so that you can plan your day to check work. We typically provide weekly or biweekly updates to demonstrate ongoing progress.</li>
            </>
        },{
            question: "Is it necessary for me to speak with your entire team during the project development stage?",
            answer: <>
                <li>We will assign a project manager to your project who will communicate with you on behalf of the team, provide you with all updates, and manage the project until it is deployed. This ensures that your point is communicated to a single person rather than multiple team members.</li>
            </>
        },{
            question: "Would you look after my application once it goes live?",
            answer: <>
                <li>Yes, we offer free support after the app goes live. After that, we provide paid support at very nominal rates.</li>
            </>
        },{
            question: "How can I monitor the progress of my project?",
            answer: <>
                <li>We will provide you with access to the project management tool we use, as well as email and Skype communication to track progress.</li>
            </>
        },{
            question: "What exactly is covered by the free support period?",
            answer: <>
                <li>We will fix any bug that falls within the defined scope and was not caused by an OS upgrade.</li>
            </>
        },{
            question: "Who will be the source code's owner?",
            answer: <>
                <li>Once full payment is received, you will own all source code.</li>
            </>
        },{
            question: "Who will be responsible for third-party fees? ",
            answer: <>
                <li>You would be responsible for any third-party fees.</li>
            </>
        },{
            question: "How long have you worked in the industry?",
            answer: <>
                <li>FLLTECH began its journey in December 2019 and has now completed five years in this industry.</li>
            </>
        },{
            question: "What is your project completion experience?",
            answer: <>
                <li>We have successfully completed over 250 projects with more than 98% happy clients worldwide!</li>
            </>
        },{
            question: "Do you have any issues with clients' different time zones?",
            answer: <>
                <li>No! We have worked with clients from all over the world in different time zones, and we adjust with them to get the job done quickly.</li>
            </>
        },{
            question: "What is the strength of your team?",
            answer: <>
                <li>We currently have over 56 tech experts working on various technologies.</li>
            </>
        },{
            question: "Do you have an in-house design team?",
            answer: <>
                <li>Yes, we have an in-house design team and QA and development teams.</li>
            </>
        },{
            question: "How do I choose the best business model for my project?",
            answer: <>
                <li>Based on the project size and plan, our sales team will assist you in identifying the best business model.</li>
            </>
        },{
            question: "What if I don’t like your service?",
            answer: <>
                <li>If you have come to see us, you have obviously done your homework. We are a well-known IT firm that has been in operation since 2011, with a single focus on customer satisfaction and quality work.</li>
            </>
        },{
            question: "Could you assist us in providing consultation before the start of the project?",
            answer: <>
                <li>Yes, for a small hourly fee, you can use our skills to build your plans.</li>
            </>
        },{
            question: "Do you follow international procedures?",
            answer: <>
                <li>Yes, we adhere to the industry-proven process and employ the necessary tools for project management, bug tracking, and code versioning.</li>
            </>
        },{
            question: "Are my company's business secrets and information secure?",
            answer: <>
                <li>Yes, the information shared by the client for development purposes is the client's property. The work product could not be sold, transferred, or used by the company or the appointed developer to anyone other than the client.</li>
            </>
        },{
            question: "I am a non-technical person who wishes to create an app. How can you help me?",
            answer: <>
                <li>Simply send us an email with your ideas, and our sales team will assist you in defining a system flow, which will be followed by product development.</li>
            </>
        },{
            question: "What business models do you offer?",
            answer: <>
                <li>We provide three different types of business models.</li>
                <li>1. Fix the Price model</li>
                <li>2. Hire a Team</li>
                <li>3. Hourly basis</li>
            </>
        },{
            question: "Do you employ the AGILE method?",
            answer: <>
                <li>Yes, depending on the size and type of the project, we employ both AGILE and waterfall methods.</li>
            </>
        }
    ]
    return <>
        <Helmet>
            <title>FAQs For Web and Mobile App Development Services</title>
            <meta name="description" content="Frequently Asked Questions(FAQs) contain questions related to our web and mobile app development process and services as well as hiring dedicated" />
            <meta name="keywords" content="web Development , mobile app Development" />
        </Helmet>
        <main className='overflow-hidden'>
            <div className='content-section'>
                <div className="w-full max-w-6xl px-6 text-start mb-10 md:mb-20 mt-8">
                    <Title className="-mt-4 mb-2 md:mb-8 text-3xl leading-58" type="normal" size="fs" headTagType='h1'>
                        Frequently Asked
                    </Title>
                    <Title type="stroke" size="xl" headTagType='h1'>
                        QUESTIONS
                    </Title>
                </div>
                <div className="text-white fll-tech--faq w-full max-w-6xl px-6 text-start mb-10 md:mb-20 mt-8">
                    <div className="fll-tech--faq-tabs">
                        <ul className="">
                            <li>
                                <button className={`fll-tech-faq-tabs-item ${currentTab == 'development' && 'active'}`} onClick={() => {setCurrentTab('development')}}>Development</button>
                            </li>
                            <li>
                                <button className={`fll-tech-faq-tabs-item ${currentTab == 'hire' && 'active'}`} onClick={() => {setCurrentTab('hire')}}>Hiring</button>
                            </li>
                            <li>
                                <button className={`fll-tech-faq-tabs-item ${currentTab == 'buesiness' && 'active'}`} onClick={() => {setCurrentTab('business')}}>Business</button>
                            </li>
                        </ul>
                    </div>
                    <div className={`fll-tech--faq-content ${currentTab != 'development' && 'hidden'}`}>
                        {
                            faqDevData.map( (v,i) => {
                                return <div className="fll-tech--faq-content-item" key={i}>
                                    <div className="flex flex-row">
                                        <h4 className="question" onClick={(e) => {handleShow(e,`answer-dev-${i}`)}}>{v.question}</h4>
                                        <span className="expand" onClick={(e) => {handleShow(e,`answer-dev-${i}`)}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 my-auto mx-auto">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                                            </svg>
                                        </span>
                                    </div>
                                    <p className="answer" id={`answer-dev-${i}`}>{v.answer}</p>
                                </div>
                            })
                        }
                    </div>
                    <div className={`fll-tech--faq-content ${currentTab != 'hire' && 'hidden'}`}>
                        {
                            faqHireData.map( (v,i) => {
                                return <div className="fll-tech--faq-content-item" key={i}>
                                    <div className="flex flex-row">
                                        <h4 className="question" onClick={(e) => {handleShow(e,`answer-hire-${i}`)}}>{v.question}</h4>
                                        <span className="expand" onClick={(e) => {handleShow(e,`answer-hire-${i}`)}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 my-auto mx-auto">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                                            </svg>
                                        </span>
                                    </div>
                                    <p className="answer" id={`answer-hire-${i}`}>{v.answer}</p>
                                </div>
                            })
                        }
                    </div>
                    <div className={`fll-tech--faq-content ${currentTab != 'business' && 'hidden'}`}>
                        {
                            faqBisunessData.map( (v,i) => {
                                return <div className="fll-tech--faq-content-item" key={i}>
                                    <div className="flex flex-row">
                                        <h4 className="question" onClick={(e) => {handleShow(e,`answer-business-${i}`)}}>{v.question}</h4>
                                        <span className="expand" onClick={(e) => {handleShow(e,`answer-business-${i}`)}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 my-auto mx-auto">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                                            </svg>
                                        </span>
                                    </div>
                                    <p className="answer" id={`answer-business-${i}`}>{v.answer}</p>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
            <ContactSection />
        </main>
    </>
}

export default FAQ