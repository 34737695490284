import React, { useRef, useReducer } from 'react';
import { sendForm } from '@emailjs/browser';

// Component
import Title from '../shared/Title';
import Button from '../shared/Button';

const apiKey = {
    SERVICE_ID: 'service_9prthm4',
    TEMPLATE_ID: 'template_wkbwxln',
    PUBLIC_KEY: 'DLlx47xwwn-aj347E',
}


const initialState = {
    'nameValue': '',
    'emailValue': '',
    'phoneNumValue': '',
    'serviceValue': 'select',
    'countryValue': 'select',
    'msgValue': '',
    'hasError': false,
    'errorMsg': 'An error has occured while sending Email, Please try again later!',
    'sendBtnStatus': 'Send Message'
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'NAME': {
            return {
                ...state,
                'nameValue': action.payload
            }
        }
        case 'EMAIL': {
            return {
                ...state,
                'emailValue': action.payload
            }
        }
        case 'PHONE': {
            return {
                ...state,
                'phoneNumValue': action.payload
            }
        }
        case 'SERVICE': {
            return {
                ...state,
                'serviceValue': action.payload
            }
        }
        case 'COUNTRY': {
            return {
                ...state,
                'countryValue': action.payload
            }
        }
        case 'MSG': {
            return {
                ...state,
                'msgValue': action.payload
            }
        }
        case 'SENDING': {
            return {
                ...state,
                'sendBtnStatus': action.payload
            }
        }
        case 'SUCCESS': {
            return {
                ...state,
                'sendBtnStatus': "Message Sent",
            }
        }
        case 'FAILED': {
            return {
                ...state,
                'hasError': action.payload,
                'sendBtnStatus': "Failed"
            }
        }
        case 'RESET': {
            return { ...initialState };
        }

        default:
            return state;
    }
}

const ContactForm = () => {

    const [value, dispatch] = useReducer(reducer, initialState);
    const form = useRef(null);

    const sendEmail = (event) => {
        event.preventDefault();
        dispatch({ type: 'SENDING', payload: 'Sending...' })

        sendForm(apiKey.SERVICE_ID, apiKey.TEMPLATE_ID, form.current, apiKey.PUBLIC_KEY)
            .then(res => {
                void res
                dispatch({ type: 'SUCCESS' })
                return setTimeout(() => {
                    return dispatch({ type: 'RESET' })
                }, 2000)
            }, (error) => {
                void error
                dispatch({ type: 'FAILED', payload: true })
                return setTimeout(() => {
                    return dispatch({ type: 'RESET' })
                }, 2000)
            });
    }


    return (
        <form ref={form} onSubmit={sendEmail} className='contact-form'>
            <Title type='normal' size='sm' headTagType='h4'>
                Share Your <span className='fll-tech--contact-us-tg'>Project Details</span>
            </Title>
            <section className='flex flex-col gap-y-4'>
                <input onChange={(e) => dispatch({ type: 'NAME', payload: e.target.value })} className='contact-form--input' type={'text'} name="_full_name" placeholder='Full Name' value={value.nameValue} required />
                <section className='flex flex-col md:flex-row gap-4'>
                    <input onChange={(e) => dispatch({ type: 'PHONE', payload: e.target.value })} className='contact-form--input' type={'tel'} name="_phone_num" placeholder='Contact Number' value={value.phoneNumValue} />
                    <input onChange={(e) => dispatch({ type: 'EMAIL', payload: e.target.value })} className='contact-form--input' type={'email'} name="_email" placeholder='Email' value={value.emailValue} required />
                </section>
                <section className='flex flex-col md:flex-row gap-4'>
                    <select onChange={(e) => dispatch({ type: 'COUNTRY', payload: e.target.value })} className='contact-form--input contact-form--selectmenu' name="_country" value={value.countryValue}>
                        <option>Select Country</option>
                        <option value="uk">UK(United Kingdom)</option>
                        <option value="armenia">Armenia</option>
                        <option value="dubai">Dubai</option>
                        <option value="germany">Germany</option>
                        <option value="us">US(United State)</option>
                    </select>
                    <select onChange={(e) => dispatch({ type: 'SERVICE', payload: e.target.value })} className='contact-form--input contact-form--selectmenu' name="_service" value={value.serviceValue}>
                        <option>Select Service</option>
                        <option value="ux/ui designer">UX/UI Designer</option>
                        <option value="app dev">App & dApp Development</option>
                        <option value="web dev">Web Development</option>
                        <option value="game dev">Game Development</option>
                        <option value="digital marketing dev">Digital Marketing Development</option>
                        <option value="blockchain">Blockchain/ Web3.0/ DeFi</option>
                    </select>
                </section>
                <textarea onChange={(e) => dispatch({ type: 'MSG', payload: e.target.value })} className='contact-form--input contact-form--textarea' type={'text'} name="_message" placeholder='Message' value={value.msgValue} required />
            </section>
            <Button Btype='primary' size='md' type='submit'>
                {value.sendBtnStatus}
            </Button>
        </form>
    );
};

export default ContactForm;