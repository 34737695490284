import star from '../../assets/imgs/star.png'

export default function CompanyRating({companyImage, companyRating, target, companyName}) {
    return <li className="fll-tech--company-ratings rounded-2xl px-6 py-4 mx-2">
        <a href={target} target='_blank' className='flex flex-row'>
            <img src={companyImage} className='my-auto fll-tech--company-ratings-logo' alt={companyName}/>
            <span className='fll-tech--company-ratings-rating'></span>
            <img className='my-auto mx-2' src={star} width={24} height={24} />
            <span className='my-auto text-white font-semibold text-xl'>{companyRating}</span>
        </a>
    </li>
}