export const getProductServiceData = () => {
    const data = {
        title: "Market Analysis & Consultant",
        description: "It’s essential to study the market dynamics, such as volume and value, potential customer segments, buying patterns, competition, and other vital factors. It determines the relationship between supply and demand for your product or service. Based on these insights, you can make more informed decisions about possible marketing strategies. FLLTech uses many functional tools to find the most relative targets to focus on. There are vast potential markets in the Blockchain industry that allows you to grow your business from zero to top. Our analyzers are focusing on new opportunities in the crypto market to provide critical components of a business plan such as",
        items: [
            "Industry description and outlook.",
            "Introduce your target market.",
            "Distinguish target customer characteristics.",
            "Target market size and growth.",
            "Market share percentage.",
            "Unity Pricing and gross margin targets.",
            "Competitive analysis.",
            "Barriers and regulatory restrictions.",
            "Barriers and regulatory restrictions.",
            "Insights and the result of deep, subjective data analysis.",
        ]
    }

    return data;
};