// Images
import digitalMarketingImg1 from '../../assets/imgs/digital/digital-marketing1.webp';
import digitalMarketingImg2 from '../../assets/imgs/digital/digital-marketing2.webp';

export const getDigitalImageCards = () => {
    const data = [
        {
            'title': 'Long-Term Marketing Development Strategy',
            'subtitle': '',
            'img': digitalMarketingImg1,
            'description': "Every business needs a strong vision and final goal. In order to hit the target, it requires goal-setting in short-term and long-term perspectives because short-term success is necessary for long-term growth. FLLTech helps you get to your final purpose step by step. First, our professional consultants help determine a business's marketing message and identify the target market. Once your strategies have been defined, we develop your marketing plan filled with a diverse mix of activities to assist you in accomplishing your goals."
        },
        {
            'title': 'Content Marketing, SEO & Brand Awareness',
            'img': digitalMarketingImg2,
            'subtitle': '',
            'description': 'FLLTech helps you with unique articles and brand expressions to increase your brand awareness. Our content marketing team applies the principles of engagement and brand building when creating and evaluating online content that the firm will share online. Also, we build web traffic by using the principles of search engine optimization SEO and search engine marketing to create web traffic for your brand. We are already working with various news agencies that provide you with the best news coverage on the internet.'
        },
    ]
    return data
}