import React, { useState } from 'react';
import { v4 } from 'uuid';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

// Component
import TabContent from './TabContent';
import TabItem from './TabItem';
import ServiceCard from '../home/ServiceCard';
import Image from '../shared/Image';
import Button from '../shared/Button';

// Buttons
import NextSlideBtn from '../buttons/NextSlideBtn';
import PrevSlideBtn from '../buttons/PrevSlideBtn';

const CarouselTabs = ({ contentsList }) => {

    const [activeTab, setActiveTab] = useState("1");


    const sliderSettings = {
        className: "center",
        centerMode: true,
        focusOnSelect: true,
        infinite: true,
        slidesToScroll: 1,
        centerPadding: "10px",
        slidesToShow: 5,
        speed: 500,
        nextArrow: <NextSlideBtn />,
        prevArrow: <PrevSlideBtn />,
        afterChange: index => { return changeActiveTabOnSlide(index) },
        responsive: [
            {
                breakpoint: 400,
                settings: {
                    centerPadding: "10px",
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 500,
                settings: {
                    centerPadding: "40px",
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 550,
                settings: {
                    centerPadding: "60px",
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    centerPadding: "100px",
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 700,
                settings: {
                    centerPadding: "150px",
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 800,
                settings: {
                    centerPadding: "180px",
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 850,
                settings: {
                    centerPadding: "220px",
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 900,
                settings: {
                    centerPadding: "250px",
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 1090,
                settings: {
                    centerPadding: "0px",
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1300,
                settings: {
                    centerPadding: "80px",
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    centerPadding: "200px",
                    slidesToShow: 3
                }
            },
        ]
    }

    const changeActiveTabOnSlide = (index) => {
        let id = index + 1;
        const strId = id.toString();
        setActiveTab(strId)
    }

    return (
        <div className='w-screen px-4 z-10'>
            <ul>
                <Slider {...sliderSettings} className='absolute'>
                    {
                        contentsList.map(item => {
                            return (
                                <TabItem
                                    key={v4()}
                                    id={item.id}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    className="text-start">
                                    <Link to={item.link} className={`select-none ${activeTab === item.id ? '' : 'pointer-events-none'}`}>
                                        <ServiceCard title={item.title} icon={item.icon} bgGradient={item.bgGradient} />
                                    </Link>
                                </TabItem>
                            )
                        })
                    }
                </Slider>
            </ul>
            <div>
                {
                    contentsList.map(item => {
                        return (
                            <TabContent
                                key={v4()}
                                id={item.id}
                                activeTab={activeTab}
                                className='w-full flex flex-col gap-y-6 items-center p-8'>
                                <p className='text-[18px] w-full max-w-3xl sm:font-normal text-center text-neutral-dark-100 '>
                                    {item.content.description}
                                </p>
                                <div className='max-w-5xl flex justify-center min-h-[3rem] flex-wrap gap-14'>

                                    {
                                        item.content.icons.map(icon => {
                                            return <Image key={v4()} className='w-12 h-12' src={icon} alt='icon' />
                                        })
                                    }
                                </div>
                                <Link className={`select-none ${activeTab === item.id ? '' : 'pointer-events-none'}`} to={item.link}>
                                    <Button Btype='primary' size='md'>
                                        Explore service
                                    </Button>
                                </Link>
                            </TabContent>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default CarouselTabs;