import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Components
import Navbar from './Navbar';
import Button from '../shared/Button';

// Images
import logo from '../../assets/imgs/logo.png';

// Hooks
import { useScrollDirection } from '../../hooks/useScrollDirection';
import { useScrollPosition } from '../../hooks/useScrollPosition';

const Header = () => {

    const [open, setOpen] = useState(false);
    const scrollDirection = useScrollDirection();
    const scrollPosition = useScrollPosition();
    const location = useLocation();

    const toggleHandler = () => {
        return setOpen(!open);
    }

    useEffect(() => {
        return setOpen(false);
    }, [location])

    return (
        <header className={`fixed w-full z-30 transition-all duration-500 ${scrollPosition !== 0 ? 'bg-[#111111] main-bg' : ''} ${scrollDirection === "down" ? "md:-top-40" : 'md:top-0'}`}>
            <div className='relative flex items-center gap-x-8 p-6 md:px-10 lg:px-14'>
                <Link to='/' className='mr-auto'>
                    <img className='w-20 h-auto md:w-24 object-contain' src={logo} alt='logo' />
                </Link>
                <Navbar open={open} toggler={toggleHandler} />
                <Link to='/contact'>
                    <Button Btype='primary' size='sm'>
                        contact
                        <span className='hidden xs:inline-block'>
                            us
                        </span>
                    </Button>
                </Link>
                <button onClick={toggleHandler} className='z-10 md:hidden'>
                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-8 w-8 stroke-white ${open ? 'opacity-0' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
            </div>
        </header>
    );
};

export default Header;