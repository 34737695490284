import React from 'react';

// Component
import Title from '../shared/Title';
import SideTabs from '../tabComponent/SideTabs';

// Images
import ellipseImg from '../../assets/imgs/blurEllipseRight.svg';
import pattern from '../../assets/imgs/pattern.png';

const SolutionsSection = ({ data }) => {


    return (
        <div className='content-section'>
            <img className='absolute w-full object-cover bg-[#111111] hidden lg:block' src={pattern} alt='pattern' />
            <img className='absolute -top-1/2 right-0 hidden lg:block' src={ellipseImg} alt='ellipse' />
            <div className='relative w-full max-w-7xl mt-16 z-[2]'>
                <Title type='golden' size='xs' className='mb-4' headTagType='h3'>
                    what we use
                </Title>
                <Title type='normal' className='text-[18px] md:text-[43px] uppercase' headTagType='h2'>
                    we provide you with the best
                </Title>
                <Title type='stroke' size='md' headTagType='h2'>
                    solutions
                </Title>
                <div className='relative'>
                    <SideTabs contentsList={data} />
                </div>
            </div>
        </div>
    );
};

export default SolutionsSection;