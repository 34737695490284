import React from 'react';

// Icons
import telegramIcon from '../../assets/icons/socials/telegram.svg';
import whatsapp from '../../assets/icons/socials/whatsapp.png';
import githubIcon from '../../assets/icons/socials/github.svg';
import instagramIcon from '../../assets/icons/socials/instagram.svg';
import youtubeIcon from '../../assets/icons/socials/youtube.svg';
import facebookIcon from '../../assets/icons/socials/facebook.svg';
import twitterIcon from '../../assets/icons/socials/x.svg';
import linkedin from '../../assets/icons/socials/linkedin.svg';
import googleIcon from '../../assets/icons/socials/google-icon.svg';
import mediumIcon from '../../assets/icons/socials/medium-icon.svg';

const SocialLinks = () => {
    return (
        <ul className='flex flex-wrap gap-4 overflow-x-auto py-4'>
            <li>
                <a href='https://telegram.me/flltech' target='blank'>
                    <img className='fll-tech--footer-social-icons' src={telegramIcon} alt='FLLTECH-telegramIcon' />
                </a>
            </li>
            <li>
                <a href='https://wa.me/message/PAL5YHAUKYVRB1' target='blank'>
                    <img className='fll-tech--footer-social-icons' src={whatsapp} alt='FLLTECH-whatsapp' />
                </a>
            </li>
            <li>
                <a href='https://github.com/FLLTECH' target='blank'>
                    <img className='fll-tech--footer-social-icons' src={githubIcon} alt='FLLTECH-githubIcon' />
                </a>
            </li>
            <li>
                <a href='https://instagram.com/flltech.limited' target='blank'>
                    <img className='fll-tech--footer-social-icons' src={instagramIcon} alt='FLLTECH-instagramIcon' />
                </a>
            </li>
            <li>
                <a href='https://www.youtube.com/@flltech' target='blank'>
                    <img className='fll-tech--footer-social-icons' src={youtubeIcon} alt='FLLTECH-youtubeIcon' />
                </a>
            </li>
            <li>
                <a href='https://m.facebook.com/flltech.limited' target='blank'>
                    <img className='fll-tech--footer-social-icons' src={facebookIcon} alt='FLLTECH-facebookIcon' />
                </a>
            </li>
            <li>
                <a href='https://twitter.com/FLLTECH_' target='blank'>
                    <img className='fll-tech--footer-social-icons' src={twitterIcon} alt='FLLTECH-twitterIcon' />
                </a>
            </li>
            <li>
                <a href='https://www.linkedin.com/company/flltech/' target='blank'>
                    <img className='fll-tech--footer-social-icons' src={linkedin} alt='FLLTECH-linkdeninIcon' />
                </a>
            </li>
            <li>
                <a href='https://g.page/r/Cf_plSdfIiXKEBM/review' target='blank'>
                    <img className='fll-tech--footer-social-icons' src={googleIcon} alt='FLLTECH-googleIcon' />
                </a>
            </li>
            <li>
                <a href='https://medium.com/@flltechlimit' target='blank'>
                    <img className='fll-tech--footer-social-icons' src={mediumIcon} alt='FLLTECH-mediumIcon' />
                </a>
            </li>
        </ul>
    );
};

export default SocialLinks;