import React, { Children } from 'react';
import styled from 'styled-components';

// Components
import AccordionHeader from './AccordionHeader';
import AccordionBody from './AccordionBody';

// Hooks
import { useToggle } from '../../hooks/useToggle';

const Div = styled.div``;

const getChildrenOnDisplayName = (children, displayName, state) => {
    return Children.map(children, (child) => {
        return child.type.displayName === displayName ?
            React.cloneElement(child, { state: state }) :
            null;
    })
}

const Accordion = ({ children }) => {

    const [isExpanded, setIsExpanded] = useToggle();

    const state = {
        isExpanded: isExpanded,
        setIsExpanded: setIsExpanded
    }

    const header = getChildrenOnDisplayName(children, "AccordionHeader", state);
    const body = getChildrenOnDisplayName(children, "AccordionBody", state);

    return (
        <Div>
            {header}
            {body}
        </Div>
    );
};


AccordionHeader.displayName = "AccordionHeader";
Accordion.AccordionHeader = AccordionHeader;

AccordionBody.displayName = "AccordionBody";
Accordion.AccordionBody = AccordionBody;

export default Accordion;