import React from 'react';
import Title from '../shared/Title';
import Image from '../shared/Image';

import hiringSybmol from "../../assets/imgs/about-us/achievement/hiring.webp";
import cardSybmol from "../../assets/imgs/about-us/achievement/card.webp";
import charitySybmol from "../../assets/imgs/about-us/achievement/charity.webp";
import gymSybmol from "../../assets/imgs/about-us/achievement/gym.webp";
import healthSybmol from "../../assets/imgs/about-us/achievement/health.webp";
import paymentSybmol from "../../assets/imgs/about-us/achievement/payment.webp";
import planeSybmol from "../../assets/imgs/about-us/achievement/plane.webp";
import relocationSybmol from "../../assets/imgs/about-us/achievement/relocation.webp";
import { v4 } from 'uuid';

const data = [
    {
        label: "Flexible Hiring Models",
        icon: hiringSybmol,
        alt: 'FLLTECH-hiringSybmol'
    },
    {
        label: "Paying Guest and Hostel allowence",
        icon: paymentSybmol,
        alt: 'FLLTECH-paymentSybmol'
    },
    {
        label: "Relocation Reimbursement",
        icon: relocationSybmol,
        alt: 'FLLTECH-relocationSybmol'
    },
    {
        label: "Flexible Hiring Models Performance Bonus on All Employee levels",
        icon: cardSybmol,
        alt: 'FLLTECH-cardSybmol'
    },
    {
        label: "Health Insurance for All Employee levels",
        icon: healthSybmol,
        alt: 'FLLTECH-healthSybmol'
    },
    {
        label: "Yearly Business Retreat for whole team",
        icon: planeSybmol,
        alt: 'FLLTECH-planeSybmol'
    },
    {
        label: "Gym / Wellness Reimbursement",
        icon: gymSybmol,
        alt: 'FLLTECH-gymSybmol'
    },
    {
        label: "Maternity - Paternity Leave",
        icon: charitySybmol,
        alt: 'FLLTECH-charitySybmol'
    },
]

const Box = ({ icon, label, alt }) => {
    return (
        <div className='h-fit w-full md:max-w-[270px] sm:h-auto sm:min-h-[300px] bg-neutral-dark-600'>
            <section className='flex flex-col justify-start items-start gap-6 p-8'>
                <Image src={icon} className='w-16' alt={alt} />
                <Title type='normal' className='text-2xl' headTagType='h3'>
                    {label}
                </Title>
            </section>
        </div>
    );
};


const Achievement = () => {
    return (
        <div className='content-section'>
            <div className='w-full max-w-5xl'>
                <>
                    <Title type='golden' size='xs' headTagType='h2'>
                        OUR ACHIEVEMENT
                    </Title>
                    <Title type='normal' size='sm' headTagType='h2'>
                        Awards & Industry Recognition
                    </Title>
                </>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-8'>
                    {
                        data.map(item => {
                            return (
                                <Box key={v4()} label={item.label} icon={item.icon} alt={item.alt} />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default Achievement;