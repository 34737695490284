import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Title from '../components/shared/Title';
import Portfolios from '../components/portfolio/Portfolios';
import GetInTouch from '../components/common/GetInTouch';
import ContactSection from '../components/common/ContactSection';

// Constant
import { getDesignPorfolioData } from '../constant/portfolio/pDesginData';
import wave from '../assets/imgs/portfolio/Topology-patterns/R-waves.svg';
import dWave from '../assets/imgs/portfolio/Topology-patterns/D-waves.svg';
import nWave from '../assets/imgs/portfolio/Topology-patterns/nested-waves.svg';
import fTopology from '../assets/imgs/portfolio/Topology-patterns/f-topology.svg';
import sTopology from '../assets/imgs/portfolio/Topology-patterns/s-topology.svg';
import { getAppPorfolioData } from '../constant/portfolio/pAppData';
import { getWebPorfolioData } from '../constant/portfolio/pWebData';
import { getBlockchainPorfolioData } from '../constant/portfolio/pBlockchainData';
import { getMarketingPorfolioData } from '../constant/portfolio/pMarketingData';
import { getGamenPorfolioData } from '../constant/portfolio/pGameData';


const Portfolio = () => {

    const designData = getDesignPorfolioData();
    const appData = getAppPorfolioData();
    const webData = getWebPorfolioData();
    const blockchainData = getBlockchainPorfolioData();
    const marketingData = getMarketingPorfolioData();
    const gameData = getGamenPorfolioData();

    return (
        <>
            <Helmet>
                <title>FLL Tech | Portfolio</title>
                <meta name='description' content='FLLTECH | Exploring Our Software Development Portfolio' />
                <meta name='keywords' content='Software Development , Portfolio' />
            </Helmet>
            <div className='content-section mt-0'>
                <div className="w-full max-w-6xl px-6 text-start mb-10 md:mb-20 mt-44">
                    <Title type="stroke" size="xl" headTagType='h1'>
                        portfolio
                    </Title>
                    <Title className="-mt-4 text-xl" type="normal" size="md" headTagType='h1'>
                        our work
                    </Title>
                </div>
                <Portfolios items={designData} title="UI&UX" subtitle="design" bgGradeint='portfolio-design-bg' marginSide='right' pattern={wave} />
                <Portfolios items={gameData} title="game" subtitle="development" bgGradeint="portfolio-game-bg" pattern={dWave} />
                <Portfolios items={webData} title="web" subtitle="development" bgGradeint="portfolio-web-bg" marginSide='right' pattern={nWave} />
                <Portfolios items={marketingData} title="digital" subtitle="marketing" bgGradeint="portfolio-digitalmarketing-bg" pattern={sTopology} />
                <Portfolios items={blockchainData} title="blockchain" subtitle="development" bgGradeint="portfolio-blockchain-bg" marginSide='right' pattern={fTopology} />
                <Portfolios items={appData} title="app" subtitle="development" bgGradeint="portfolio-app-bg" pattern={wave} />
                <GetInTouch />
                <ContactSection />
            </div>
        </>
    );
};

export default Portfolio;