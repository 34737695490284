import Totolottery from '../../assets/imgs/portfolio/game/totolottery.webp';
import keno from '../../assets/imgs/portfolio/game/keno.webp';
import snakerider from '../../assets/imgs/portfolio/game/snakerider.webp';
import luckyb from '../../assets/imgs/portfolio/game/luckyb.webp';
import race from '../../assets/imgs/portfolio/game/race.webp';
import warheros from '../../assets/imgs/portfolio/game/warheros.webp';
import sotlr from '../../assets/imgs/portfolio/game/sotlr.webp';

import figmaIcon from '../../assets/icons/services/figma-2.svg';
import htmlIcon from '../../assets/icons/services/html.svg';
import jsIcon from '../../assets/icons/services/js.svg';
import phpIcon from '../../assets/icons/services/php.png';
import unityIcon from '../../assets/icons/services/unity.svg';
import androidIcon from '../../assets/icons/services/android-logo.svg';
import appleIcon from '../../assets/icons/services/apple-logo.svg';
import blenderIcon from '../../assets/icons/services/blender.svg';
import d3maxIcon from '../../assets/icons/services/max3d.png';

export const getGamenPorfolioData = () => {
    const data = [
        {
            title: "Totolottery",
            tags: ["Game Development", "Backend", "Frontend",],
            description: "This project has been done by FLLTech developers for client who was looking for a platform to provide different kind of Lotteries to their community . The website provides daily, weekly, monthly lotteries and also lotteries with big reward. The website is very friendly user and also contains bright colors which can attract clients. Users are able to check their lottery status and results easily.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": htmlIcon, },
                { "icon": jsIcon, },
                { "icon": phpIcon, },
            ],
            image: Totolottery,
            link: "https://totolottery.live/"
        },
        {
            title: "KenoB",
            tags: ["Backend", "Frontend", "Design"],
            description: "KenoB is based on Classic Keno game but we changed the classic one according to client  requests. It's fun to play and also users are able to choose different lucky numbers in the same time. In the website users can see the real-time users which is so encouraging to play for website's users.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": htmlIcon, },
                { "icon": jsIcon, },
                { "icon": phpIcon, },
            ],
            image: keno,
            link: "https://kenob.io/"
        },
        {
            title: "LuckyB",
            tags: ["Backend", "Frontend", "Design"],
            description: "LuckyB interface is a 3D Wheel of Fortune which is going on and on during the play for users. In the same time whenever time for choosing  starts users are able to choose their lucky numbers and wait to see if their numbers are the winner ones.LuckyB is a live fortune game which randomly picks up winner numbers.In the same time users can see lucky winners down in their screen and it makes the game more fun and exciting.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": htmlIcon, },
                { "icon": jsIcon, },
                { "icon": phpIcon, },
            ],
            image: luckyb,
            link: "https://luckyb.io/"
        },
        {
            title: "Snakerider",
            tags: ["Design", "Unity", "Frontend",],
            description: "Snakerider is a game developed by FLLTech team which is a easy and in the same time very exited game. In this online game users are playing all together on a land which has different kind of obstacles which users should avoid them when they are riding their snakes.There are lots of rewards too for users to take and make their snake rid faster or longer. This game is very popular among players because it's easy to learn and it needs focus in the same time  and they are compete with all online users in the same time, which makes it very exciting to participate.",
            icons: [
                { "icon": unityIcon, },
                { "icon": figmaIcon, },
                { "icon": htmlIcon, },
                { "icon": androidIcon, },
                { "icon": appleIcon, },
                { "icon": phpIcon, },
            ],
            image: snakerider,
            link: "https://play.snakerider.io"
        },
        {
            title: "War Heros",
            tags: ["Mobile Development", "Design", "Unity",],
            description: "War Heroes is a multiplayer first person shooter mobile game that has been created By the game development department of FLLTech Company.In this game We Tried To give our Players a variation of Personalization as well as competition.Players have the opportunity to create their unique load-out and compete with other players.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": androidIcon, },
                { "icon": unityIcon, },
                { "icon": blenderIcon, }
            ],
            image: warheros,
            link: "https://play.google.com/store/apps/details?id=com.BIXBGame.War_Heroes"
        },
        {
            title: "On The Road",
            tags: ["Design", "Unity"],
            description: "On The Road is a Racing Open World Game Created By FllTECH Company. In this Project Our Development Team Tried To Give the Players A Real Time Experience of Driving in city and Intercity road.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": unityIcon, },
            ],
            image: race,
            link: ""
        },
        {
            title: "Secret of the last room",
            tags: ["Design", "Unity"],
            description: "The secret of the last room is a board game that has been developed by our game developers, we tried to make a good experience for our players by creating various challenges during the different levels of the game. High-quality rendering of the Environment in the game has created a great visual experience for the players.",
            icons: [
                { "icon": figmaIcon, },
                { "icon": unityIcon, },
                { "icon": d3maxIcon, }
            ],
            image: sotlr,
            link: "https://play.google.com/store/apps/details?id=com.bixbgame.secretofthelastroom"
        },
    ];
    return data;
}