import React from 'react';

// Icons
import arrowRight from '../../assets/icons/arrow-right.svg';

const ServiceCard = ({ icon, title, bgGradient }) => {

    return (
        <div className={`mx-4 2xl:max-w-[385px] h-[120px] p-[1px] rounded-2xl ${bgGradient}`}>
            <section className={`w-full h-full rounded-2xl p-1 xs:px-3 py-6 md:px-6 ${bgGradient}`}>
                <span className='flex h-full items-center justify-start'>
                    <img className='w-6' src={icon} alt={`FLLTECH-${title}`} />
                    <p className='whitespace-pre-wrap mx-4 max-w-[12rem] text-white text-base lg:text-lg font-semibold'>
                        {title}
                    </p>
                    <img className='w-6 ml-auto' src={arrowRight} alt='arrow' />
                </span>
            </section>
        </div>
    );
};

export default ServiceCard;