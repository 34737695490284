import React from 'react';

// Components
import Title from '../../shared/Title';
import Container from './Container';

const Categories = ({ data, categoryTitle }) => {
    return (
        <div className='content-section'>
            <div className='realtive w-full max-w-5xl z-10'>
                <Title type='golden' size='xs'>
                    {categoryTitle}
                </Title>
                <Container optionsData={data} />
            </div>
        </div>
    );
};

export default Categories;