import React from 'react';

// Components
import Title from '../../shared/Title';
import Container from './Container';

// Images
import pattern from '../../../assets/imgs/pattern.png';
import blurEllipse from '../../../assets/imgs/blurEllipseRight.svg';

const HelpToChoose = ({ data, serviceTitle }) => {
    return (
        <div className='content-section'>
            <div className='realtive w-full max-w-5xl z-10'>
                <Title type='golden' size='xs'>
                    WHY CHOOSE US
                </Title>
                <Title className='mb-10' type='normal' size='md' headTagType='h2'>
                    Why Choose FLLTECH for
                    <p className='capitalize'>
                        {serviceTitle}? {/* This should set by passed constant data */}
                    </p>
                </Title>
                {
                    data.intro.title &&
                    <Title className='mb-8' type='normal' size='sm' headTagType='h3'>
                        {data.intro.title}
                    </Title>
                }
                <article className='w-full max-w-xl text-neutral-light-600 mb-20'>
                    {data.intro.intro}
                </article>
                <Container optionsData={data.data} />
            </div>
            <img className='absolute left-0 w-full object-cover bg-[#111111] hidden lg:block' src={pattern} alt='pattern' />
            <img className='absolute -top-1/2 -right-0 hidden lg:block' src={blurEllipse} alt='ellipse' />
        </div>
    );
};

export default HelpToChoose;