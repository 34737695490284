export const getDesignFAQData = () => {
    const data = [
        {
            headerTitle: "What kind of UI/UX design services do you offer?",
            content: {
                title: 'Here are some of the key UI/UX design services we offer at FLLTECH',
                items: ['app design', 'web design', 'Graphic design', 'Logo and Brand Identity Design',]
            }
        },
        {
            headerTitle: "What's the difference between UI and UX design?",
            content: {
                title: 'UI stands for User Interface. It determines how users are able to experience your mobile app or website. It includes how the things like content, colors, buttons, images, etc. are organized. On the other hand, UX is the acronym for User Experience. It identifies if the app creates an enjoyable user experience that ultimately turns users into loyal customers.',
                items: []
            }
        },
        {
            headerTitle: "How much do you charge for UI/UX design?",
            content: {
                title: 'The cost of UI/UX design depends on a bunch of factors like the category of your product, the complexity of the design, the number of features it has, how many screens need to design, need animation or not, and so on.',
                items: []
            }
        },
        {
            headerTitle: "What is your design approach?",
            content: {
                title: 'At Flltech, every project gets assigned a manager experienced in design to understand the requirements of your app or website. As per the requirements, we divide it into different milestones like UI/UX research, wireframing, prototyping, and visual mockups. We keep our clients involved in each step of the design process. The project ends when our client is happy with the final delivery of the designs.',
                items: []
            }
        },
        {
            headerTitle: "Which tools and technologies do you use for UI/UX design?",
            content: {
                title: 'Here are some of the tools we use for UI/UX design:',
                items: ['sketch', 'adobe Xd', 'photoshop', 'illustrator', 'figma', 'after effects', 'zeplin']
            }
        },
    ]
    return data;
}