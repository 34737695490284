// Icons
import pentoolIcon from '../../../assets/icons/pen-tool.svg'
import blockchaingIcon from '../../../assets/icons/blockchain.svg'
import codeIcon from '../../../assets/icons/code.svg'
import mobileIcon from '../../../assets/icons/mobile.svg'

export const getServiceExploreData = () => {
    const data = [
        {
            "id": "1",
            "title": "UI/UX Design",
            "icon": pentoolIcon,
            "link": '/ui-design'
        },
        {
            "id": "2",
            "title": "Mobile App Development",
            "icon": mobileIcon,
            "link": '/app-development'
        },
        {
            "id": "3",
            "title": "Web Development",
            "icon": codeIcon,
            "link": '/web-development'
        },
        {
            "id": "4",
            "title": "Blockchain & Web3.0",
            "icon": blockchaingIcon,
            "link": '/blockchain'
        },
    ]
    return data
}