import gngBranding from '../../assets/imgs/portfolio/marketing/gng-branding.webp';
import woodlockBranding from '../../assets/imgs/portfolio/marketing/woodlock-branding.webp';
import nairyanBranding from '../../assets/imgs/portfolio/marketing/nairyan-branding.webp';
import gngSocial from '../../assets/imgs/portfolio/marketing/gng-social.webp';
import woodlockSocial from '../../assets/imgs/portfolio/marketing/woodlock-social.webp';
import nairyanSocial from '../../assets/imgs/portfolio/marketing/nairyan-social.webp';
import cryptizi from '../../assets/imgs/portfolio/marketing/cryptizi.webp';
import vexpo from '../../assets/imgs/portfolio/marketing/vexpo.webp';
import mock from '../../assets/imgs/portfolio/marketing/mock.webp';
import mock2 from '../../assets/imgs/portfolio/marketing/mock-2.webp';
import mock4 from '../../assets/imgs/portfolio/marketing/mock-4.webp';

import lifttech from '../../assets/imgs/portfolio/marketing/lifttech.webp';
import aiotoken from '../../assets/imgs/portfolio/marketing/aio.webp';
import gnggames from '../../assets/imgs/portfolio/marketing/gnggames.webp';
import snakerider from '../../assets/imgs/portfolio/marketing/snakerider.webp';
import rvcomfort from '../../assets/imgs/portfolio/marketing/rv-comfort.webp';

import photoshopIcon from '../../assets/icons/services/adobe-photoshop.svg';
import illustratorIcon from '../../assets/icons/services/adobe-illustrator.svg';
import blenderIcon from '../../assets/icons/services/blender.svg';

export const getMarketingPorfolioData = () => {
    const data = [
        {
            title: "Woodlock Branding",
            tags: ["Adobe illustrator", "Adobe photoshop"],
            description: "This branding has been done for a Home furniture company. They use wood as main material in their design, that's why the color for branding has been used is wood color and even in some part the wooden texture is also bold.In woodlock logo the 'W' has been design bigger to emphasize on the  first letter as client asked for it. ",
            icons: [
                { "icon": illustratorIcon, },
                { "icon": photoshopIcon, },
            ],
            image: woodlockBranding,
            link: ""
        },
        {
            title: "GNG Branding",
            tags: ["Adobe illustrator", "Adobe photoshop"],
            description: "GNG logo and branding has been designed for game production crypto coin which use as main currency in online games, that's why in the logo design is look like game console and the square used in the logo is not closed, one corner is open in order to show that they are in a business that is beyond normal limits and they are kind of limitless. The square is also round around corners and it also shows their modern game styles.",
            icons: [
                { "icon": illustratorIcon, },
                { "icon": photoshopIcon, },
            ],
            image: gngBranding,
            link: ""
        },
        {
            title: "Nairyan Branding",
            tags: ["Adobe illustrator", "Adobe photoshop"],
            description: "Nairyan is a hand craft produce local  company. They design and  produce hand craft product such as Houseware. Fashion, Paper crafts, ...As this brand want to continue producing new staff and have more product on the market we used infinity sign in the logo. Golden color is symbol of worthy product of theirsare handmade. Typography is simple and modern which matching their output very well.",
            icons: [
                { "icon": illustratorIcon, },
                { "icon": photoshopIcon, },
            ],
            image: nairyanBranding,
            link: ""
        },
        {
            title: "Woodlock Social",
            tags: ["Adobe illustrator", "Adobe photoshop"],
            description: "in social media posts we tried to keep the design simple and used some of furniture which has been designed by WoodLock. Those posts has been design to interduce product on social medias and also for marketing in the interior design market.",
            icons: [
                { "icon": illustratorIcon, },
                { "icon": photoshopIcon, },
            ],
            image: woodlockSocial,
            link: ""
        },
        {
            title: "GNG Social",
            tags: ["Adobe illustrator", "Adobe photoshop"],
            description: "These social media posts has been designed with bold and special colors to show how amazing their work is. In they posts the attention is more on cryptocurrency world and the uses that they have in our modern world.",
            icons: [
                { "icon": illustratorIcon, },
                { "icon": photoshopIcon, },
            ],
            image: gngSocial,
            link: ""
        },
        {
            title: "AE construction Social",
            tags: ["Adobe illustrator", "Adobe photoshop"],
            description: "AE construction as their name interduce itself, is a building construction company.  They are designing and building different kind of building such as houses, offices, ... They are popular for their concept and creation and that's why it has been used in some posts. They also prepare and deliver high quality work to their clients, and all of these facts has been considered in designing their social media posts.",
            icons: [
                { "icon": illustratorIcon, },
                { "icon": photoshopIcon, },
            ],
            image: nairyanSocial,
            link: ""
        },
        {
            title: "cryptizi",
            tags: ["Adobe illustrator", "Adobe photoshop"],
            description: "Cryptizi is a Exchange for crypto currency. It is easy to use and safe for users. It gives the users opportunity to keep their crypto in the wallet on the exchange too.Cryptizi exchange is a platform for buying and selling cryptocurrencies.In addition to trading services, Cryptizi exchanges also offer price discovery through trading activity, as well as storage for crypto.",
            icons: [
                { "icon": illustratorIcon, },
                { "icon": photoshopIcon, },
            ],
            image: cryptizi,
            link: "https://instagram.com/cryptizi.official?igshid=NzAzN2Q1NTE="
        },
        {
            title: "VExpo",
            tags: ["Adobe illustrator", "Adobe photoshop"],
            description: "VExpo is a company which focuses on holding expos for many years. In the new technological world, our customers decided to lunch new website and social media to make access to their expos easier for people all around Armenia and even other countries.To achieve this goal we provided the idea of a virtual expo so there could attract more customers all around the world and provide easy access wherever they are.Here are some of the social media posts designed by the FLL team.",
            icons: [
                { "icon": illustratorIcon, },
                { "icon": photoshopIcon, },
            ],
            image: vexpo,
            link: "https://instagram.com/vexpo.am?igshid=YmMyMTA2M2Y="
        },
        {
            title: "Aghajanyan Hair studio",
            tags: ["Adobe illustrator", "Adobe photoshop"],
            description: "We had the opportunity to work with a nature-friendly product Aghajanyan eco-friendly hair studio, with their open-minded and amazing team we came to the idea of a new logo that resonates with nature and their line of work. We used green color and some feminine elements to introduce their brand clearly. Aghajanyan eco-friendly hair studio working on all hair needs in Yerevan! Their salon is committed to providing exceptional hair services while minimizing our environmental impact.",
            icons: [
                { "icon": illustratorIcon, },
                { "icon": photoshopIcon, },
            ],
            image: mock,
            link: "https://www.instagram.com/aghajanyans_beauty_centre/"
        },
        {
            title: "Aghajanyan Hair studio",
            tags: ["Adobe illustrator", "Adobe photoshop"],
            description: "We had the opportunity to work with a nature-friendly product Aghajanyan eco-friendly hair studio, with their open-minded and amazing team we came to the idea of a new logo that resonates with nature and their line of work. We used green color and some feminine elements to introduce their brand clearly. Aghajanyan eco-friendly hair studio working on all hair needs in Yerevan! Their salon is committed to providing exceptional hair services while minimizing our environmental impact.",
            icons: [
                { "icon": illustratorIcon, },
                { "icon": photoshopIcon, },
            ],
            image: mock2,
            link: "https://www.instagram.com/aghajanyans_beauty_centre/"
        },
        {
            title: "Drone Hub",
            tags: ["Adobe illustrator", "Adobe photoshop"],
            description: "In Drone Hub, they believe that drone automation should be accessible to everyone, not just experts. With their drag-and-drop software solution, you can pilot a drone as easily as fly a kite. We worked with their team closely to come to the idea of their new logo and also a booklet that represents their work. The orange color is their main color and we kept on to that and also added some elements to be more precise about their purpose in the market on their logo design. At the end, their team was satisfied with the result. ",
            icons: [
                { "icon": illustratorIcon, },
                { "icon": photoshopIcon, },
            ],
            image: mock4,
            link: "https://drone-hub.netlify.app/"
        },
        {
            title: "Snakerider",
            tags: ["Adobe illustrator", "Adobe photoshop", "Blender"],
            description: "SnakeRider is a Play-to-Earn 2D multiplayer game, with FLL TECH LLC managing its advertising and social media presence. Join the thrilling adventure of Snake Rider, where skill and strategy meet in the exciting world of blockchain gaming.",
            icons: [
                { "icon": illustratorIcon, },
                { "icon": photoshopIcon, },
                { "icon": blenderIcon, }
            ],
            image: snakerider,
            link: "https://drone-hub.netlify.app/"
        },{
            title: "GNG Games",
            tags: ["Adobe illustrator", "Adobe photoshop", "Blender"],
            description: "GNG Games- GNG is a digital asset on the Polygon network, offering P2E games, a Swap platform, and an exclusive NFT Marketplace. Managed by FLLTECH Team, it's your gateway to a vibrant ecosystem where you can trade NFTs, play games, and gain rewards in the Polygon network.",
            icons: [
                { "icon": illustratorIcon, },
                { "icon": photoshopIcon, },
                { "icon": blenderIcon, }
            ],
            image: gnggames,
            link: "https://instagram.com/gnggames.official?igshid=MzRlODBiNWFlZA=="
        },{
            title: "R&V Comfort",
            tags: ["Adobe illustrator", "Adobe photoshop"],
            description: "R&V Comfort specializes in importing construction materials, offering design services, and executing construction projects. With expertise in materials and design, they provide comprehensive solutions for construction needs, ensuring comfort and quality in every project, with advertising and social media management by FLL TECH LLC.",
            icons: [
                { "icon": illustratorIcon, },
                { "icon": photoshopIcon, },
            ],
            image: rvcomfort,
            link: "https://instagram.com/rv_comfort?igshid=MzRlODBiNWFlZA=="
        },{
            title: "AIO TOKEN",
            tags: ["Adobe illustrator", "Adobe photoshop", "Blender"],
            description: "All-In-One token (AIO) - AIO is a cryptocurrency token benefiting from FLLTECH Team advertisement and social media management. This all-in-one token offers various utility within the crypto space, making it an attractive choice for crypto enthusiasts and investors.",
            icons: [
                { "icon": illustratorIcon, },
                { "icon": photoshopIcon, },
                { "icon": blenderIcon, }
            ],
            image: aiotoken,
            link: "https://www.allinonetoken.io/"
        },{
            title: "LiftTech",
            tags: ["Adobe illustrator", "Adobe photoshop"],
            description: "LiftTech relies on FLLTECH Team for expert advertising and social media management services. As the entrusted digital partner, we enhance LiftTech's brand visibility and market impact. Specializing in the import of premium Japanese forklifts, including DOOSAN and TOYOTA, LiftTech focuses on top-quality solutions, while we concentrate on elevating their online presence and promotional strategies. Join us for a synergistic partnership in driving LiftTech's success.",
            icons: [
                { "icon": illustratorIcon, },
                { "icon": photoshopIcon, },
            ],
            image: lifttech,
            link: "https://instagram.com/lifttecharmenia?igshid=MzRlODBiNWFlZA=="
        },
    ];
    return data;
}