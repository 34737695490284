export const getGameToChooseData = () => {

    const intro = {
        title: 'Trusted Partner for All Your Game Development Needs',
        intro: 'With a team of certified and experienced game developers, we have created mobile games for almost all sectors. Each and every project is important for us. This is what helps us become a reliable partner of our clients when it comes to game development. Here’s what made us a perfect choice as a mobile game development company'
    };

    const data = [
        {
            title: 'AR/VR Experts',
            description: 'We have a devoted team of AR and VR game development experts who use robust tools and apply the best strategies to create dynamic AR/VR games with superb gameplay.'
        },
        {
            title: 'Unity Certified Developers',
            description: 'Our team of unity certified developers has extensive experience in creating games for multiple platforms. They are proficient with Unity’s all new robust features.'
        },
        {
            title: 'Huge Experience',
            description: 'When it comes to designing or developing a game, we know how to do it perfectly. We have dedicated and experienced game developers who are experts in the industry.'
        },
        {
            title: 'End-to-End Game Development',
            description: 'FLLTech provides full-cycle game development services that include all phases from designing a game, creating animations to 2D/3D modeling, coding, and post-release maintenance.'
        },
        {
            title: 'Assured Quality',
            description: 'Our devoted team tests every game thoroughly for compatibility, performance, errors, functionality issues, and bugs. After conducting multiple tests on a specific platform, we release the final game.'
        },
        {
            title: 'Long-Term Association',
            description: 'We believe in making long-term partnerships with our clients. Hence, we assess game mechanics, concepts, and target audience to ensure that the game get huge success over the platforms.'
        },
    ]
    return { intro, data };
}