import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Button from '../components/shared/Button';

// Icons 
import bigRightArr from '../assets/icons/big-right-arrow.svg';

// Components
import Circles from '../components/animations/Circles';
import ServicesSection from '../components/home/ServicesSection';
import TrendTech from '../components/home/TrendTech';
import Title from '../components/shared/Title';
import GetInTouch from '../components/common/GetInTouch';
import PortfolioSection from '../components/home/PortfolioSection';
import BusinessSection from '../components/home/BusinessSection';
import SolutionSection from '../components/home/SolutionsSection';
import ContactSection from '../components/common/ContactSection';

// Constant
import { solutionData } from '../constant/solutionData';

const Home = () => {

    const solutionsData = solutionData();

    return (
        <>
            <Helmet>
                <title>FLLTECH | Top App & Web Development Company Armenia</title>
                <meta name='description' content='FLLTECH | Top Blockchain , App & Web development company in Armenia , Transform Your Ideas to Reality' />
                <meta name='keywords' content='Web Development, App development' />
            </Helmet>
            <main className='overflow-x-hidden'>
                <div className='overflow-hidden flex flex-col'>  {/*  Slider component translate-y (./home/ServicesSection) is depends on Gap, Margin here, so if gap changed in any situation, translate should increase or decrease respectively */}
                    <section className='content-section'>
                        <Circles />
                        <Title className='w-full max-w-5xl mt-14' type='normal' size='md' headTagType='h2' >
                            Transform Your Ideas Reality
                            <Title type='basic' size='md' isHeadTag={false}>
                                with FLLTech's Full-Service Design
                            </Title>
                            to Development Team
                        </Title>
                    </section>
                    <ServicesSection />
                    <div className='content-section'>
                        <section className='w-full max-w-5xl py-16 px-10 flex flex-col  rounded-lg bg-neutral-dark-600 bg-pattern'>
                            <Title className='' type='golden' size='xs' headTagType='h3'>
                                WHO WE ARE
                            </Title>
                            <Title className='my-4' type='normal' size='sm' headTagType='h3'>
                                We are your digital partner to create inspirational design and robust solution for Web, Mobile, Game & Cloud.
                            </Title>
                            <p className='text-white text-left text-xl'>
                                FLLTech is a web and mobile app development company dedicated to providing innovative and custom solutions to businesses of all sizes. We are a team of creative designers and expert developers who design, develop, and deliver digital products to enhance user engagement, improve brand awareness, and drive business growth. From digital product design to development, our on-demand Agile product team will ensure that your digital product stands out in today's competitive market.
                                <br></br>
                                <br></br>
                                Our marketing capability extends beyond just development – we employ data-driven strategies to boost brand visibility and reach the target audience effectively. Whether app development or game creation, FLLTech Collects innovation to produce captivating, user-centric digital experiences, making us a top-notch choice for businesses seeking comprehensive technological solutions.
                            </p>
                            
                            <section className='flex flex-col items-center sm:flex-row gap-4 mt-8'>
                                <Link to='/contact'>
                                    <Button Btype='primary' size='md'>
                                        Get in touch
                                    </Button>
                                </Link>
                                <Link to='/aboutus'>
                                    <Button Btype='basic' size='md'>
                                        about us <img className='w-6 ml-2' src={bigRightArr} alt='right arrow' />
                                    </Button>
                                </Link>
                            </section>
                        </section>
                    </div>
                    <TrendTech />
                    <GetInTouch />
                    <PortfolioSection />
                    <BusinessSection />
                    <SolutionSection data={solutionsData} />
                    <ContactSection />
                </div>
            </main>
        </>
    );
};

export default Home;