// Images
import projectAppImg1 from '../../assets/imgs/app-dev/project-app-1.webp';
import projectAppImg2 from '../../assets/imgs/app-dev/project-app-2.webp';

export const getAppImageCards = () => {
    const data = [
        {
            'title': '',
            'subtitle': 'Weather And News App',
            'img': projectAppImg1,
            'description': 'UI/UX Design, Mobile App Development'
        },
        {
            'title': '',
            'img': projectAppImg2,
            'subtitle': 'Meditation And Fitness App',
            'description': 'UI/UX Design, Mobile App Development'
        },
    ]
    return data
}