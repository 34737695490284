import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .video-thumb {
        height: 100%;
        width: 100%;
        transition: opacity 400ms ease 0ms;
        }
    .tiny {
        filter: blur(3px);
        transform: scale(1.1);
        transition: visibility 0ms ease 400ms;
        }
`;


const Video = ({ videoSrc, videoThumbSrc, cover = false }) => {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    const onLoadedData = () => {
        setIsVideoLoaded(true);
    };

    return (
        <Container>
            <img
                src={videoThumbSrc}
                className={`video-thumb tiny  ${cover ? 'object-cover' : 'object-contain'}`}
                alt="thumb"
                style={{ display: isVideoLoaded ? 'none' : 'block' }}
            />
            <video
                autoPlay
                playsInline
                muted
                loop
                className={`w-full h-full ${cover ? 'object-cover' : 'object-contain'}`}
                preload='auto'
                onLoadedData={onLoadedData}
                src={videoSrc} />
        </Container>
    );
};

export default Video;