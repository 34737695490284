import reactIcon from '../../assets/icons/services/react.svg';
import pythoIcon from '../../assets/icons/services/python.svg';
import laravelIcon from '../../assets/icons/services/laravel.svg';
import jsIcon from '../../assets/icons/services/js.svg';

export const getWebPlatformLogos = () => {
    const logos = [
        {
            "icon": pythoIcon,
            "subtitle": "python",
        },
        {
            "icon": reactIcon,
            "subtitle": "react",
        },
        {
            "icon": laravelIcon,
            "subtitle": "laravel",
        },
        {
            "icon": jsIcon,
            "subtitle": "javascript",
        },
    ]
    return logos;
}