import React, { useState } from 'react';
import { v4 } from 'uuid';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../shared/Button';
import Title from '../shared/Title';
import TabContent from '../tabComponent/TabContent';
import TabItem from '../tabComponent/TabItem';
import Logos from '../common/Logos';

const Div = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    @media (min-width: 768px) {
        max-width: 70%;
    }
`;

const Section = styled.section`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
`;

const Ul = styled.ul`
    display: flex;
    border-bottom: 1px solid whitesmoke;
    overflow-x: auto;
    width: 100%;
    
    .tab-item {
        text-align: center;
        flex: auto;
        padding: .5rem 1rem;
        background-color: transparent;
        text-transform: capitalize;
        font-weight: 600;
        max-width: fit-content;
        min-width: max-content;
        cursor: pointer;
        color: white;
    }

    .tab-active {
        border-bottom: 2px solid white;
    }

`;

const TabView = ({ data }) => {

    const { title, link, tabData } = data;

    const [activeTab, setActiveTab] = useState("t1");

    return (
        <Div>
            <Section>
                <Title type='normal' className='text-[24px] sm:text-[34px]' headTagType='h2'>
                    {title}
                </Title>
                <Link to={`/${link.url}`}>
                    <Button Btype='basic' size='lg'>
                        {link.text}
                    </Button>
                </Link>
            </Section>
            <Ul className='border-neutral-light-600'>
                {
                    tabData.map(data => {
                        return (
                            <TabItem
                                key={v4()}
                                id={data.tabId}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                className='tab-item'
                                activeClass='tab-active'>
                                {data.title}
                            </TabItem>
                        )
                    })
                }
            </Ul>
            <section className='w-full'>
                {
                    tabData.map(data => {
                        return (
                            <TabContent
                                key={v4()}
                                id={data.tabId}
                                activeTab={activeTab}
                                className='w-full mt-12'>
                                <Logos logosList={data.content} isFlex boldText logoSize='w-14 h-14' />
                            </TabContent>
                        )
                    })
                }
            </section>
        </Div>
    );
};

export default TabView;