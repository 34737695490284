import React, { useRef } from 'react';
import styled from 'styled-components';
import Logos from '../common/Logos';
import Title from '../shared/Title';
import Image from '../shared/Image';
import { useIsInViewport } from '../../hooks/useIsInViewport';
import { v4 } from 'uuid';

const TagLi = styled.li`
  position: relative;
  padding: 0.75rem 1em;
  box-sizing: border-box;
  color: #FFF;
  background: #111111;
  background-clip: padding-box;
  border: solid 5px transparent;
  border-radius: 999px;
  white-space: nowrap;
    
    &::before {
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: -1;
        margin: -3px;
        border-radius: inherit;
        background: linear-gradient(to right,#FFFFFF35, #FFFFFF8A, #FFFFFF4D, #7A7A7A3F);
    }
`;

const PortfolioCard = ({ content, marginSide }) => {

    const ref = useRef(null);
    const isVisible = useIsInViewport(ref);

    return (
        <div ref={ref} className={`w-full max-w-[450px] sm:max-w-full h-auto transition-all duration-[1.5s] ${isVisible ? 'translate-y-0 opacity-100' : 'opacity-0 -translate-y-[150px]'} ${marginSide === 'left' ? 'md:odd:mt-44' : ''} ${marginSide === 'right' ? 'md:even:mt-44' : ''}`}>
            <Image className='w-full object-contain mb-6' src={content.image} alt={content.title} />
            <div className='flex flex-col gap-y-6 px-4 md:px-0'>
                <Title className='text-4xl' type='normal' headTagType='h3'>
                    {content.title}
                </Title>
                <ul className='flex flex-nowrap sm:flex-wrap overflow-x-auto gap-6 '>
                    {
                        content.tags.map(tag => {
                            return (
                                <TagLi key={v4()}>
                                    {tag}
                                </TagLi>
                            )
                        })
                    }
                </ul>
                <Logos logosList={content.icons} isFlex logoSize='w-8' />
                <article className='text-white text-lg'>
                    {content.description}
                </article>
                <a href={content.link} target="blank" className='w-fit py-1 underline text-neutral-light-600 first-letter:capitalize'>
                    project link
                </a>
            </div>
        </div>
    );
};

export default PortfolioCard;