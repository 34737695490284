import iosIcon from '../../assets/icons/services/apple-logo.svg';
import androidIcon from '../../assets/icons/services/android-logo.svg';
import swiftIcon from '../../assets/icons/services/swift.png';
import flutterIcon from '../../assets/icons/services/flutter.svg';
import reactIcon from '../../assets/icons/services/react.svg';
import lonicIcon from '../../assets/icons/services/lonic.png';

export const getAppLogos = () => {
    const logos = [
        {
            "icon": iosIcon,
            "label": "iPhone App Development",
        },
        {
            "icon": androidIcon,
            "label": "Android App Development",
        },
        {
            "icon": swiftIcon,
            "label": "Swift App Development"
        },
        {
            "icon": flutterIcon,
            "label": "Flutter App Development",
        },
        {
            "icon": reactIcon,
            "label": "React Native Development",
        },
        {
            "icon": lonicIcon,
            "label": "Ionic App Development"
        }
    ]
    return logos;
}