// Images
import carImg from '../../assets/imgs/game/car.webp';
import casual from '../../assets/imgs/game/genres/casual.webp';
import arcade from '../../assets/imgs/game/genres/arcade.webp';
import puzzle from '../../assets/imgs/game/genres/puzzle.webp';
import crypto from '../../assets/imgs/game/genres/crypto.webp';
import educational from '../../assets/imgs/game/genres/educational.webp';
import kids from '../../assets/imgs/game/genres/kids.webp';
import multiplayer from '../../assets/imgs/game/genres/multiplayer.webp';
import card from '../../assets/imgs/game/genres/card.webp';
import sports from '../../assets/imgs/game/genres/sports.webp';
import board from '../../assets/imgs/game/genres/board.webp';
import arvr from '../../assets/imgs/game/genres/ar-vr.webp';

export const getGameTypes = () => {
    const data = [
        {
            'id': 1,
            'title': 'casual games',
            'image': casual
        },
        {
            'id': 2,
            'title': 'arcade games',
            'image': arcade
        },
        {
            'id': 3,
            'title': 'puzzle games',
            'image': puzzle
        },
        {
            'id': 4,
            'title': 'crypto games',
            'image': crypto
        },
        {
            'id': 5,
            'title': 'educational games',
            'image': educational
        },
        {
            'id': 6,
            'title': 'kids games',
            'image': kids
        },
        {
            'id': 7,
            'title': 'multiplayer games',
            'image': multiplayer
        },
        {
            'id': 8,
            'title': 'card games',
            'image': card
        },
        {
            'id': 9,
            'title': 'sports games',
            'image': sports
        },
        {
            'id': 10,
            'title': 'racing games',
            'image': carImg
        },
        {
            'id': 11,
            'title': 'board games',
            'image': board
        },
        {
            'id': 12,
            'title': 'AR / VR games',
            'image': arvr
        },
    ]
    return data;
}