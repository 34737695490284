import React, { useEffect, useRef } from 'react';
import { v4 } from 'uuid';

import { aboutGalleryData } from '../../constant/about-us/aboutGalleryData';

const MarqueeGallery = () => {

    const images = aboutGalleryData();
    const ref = useRef(null);

    const removeAnimation = () => {
        return ref.current.classList.remove('animate-marquee');

    }
    const addAnimation = () => {
        return ref.current.classList.add('animate-marquee');
    }

    return (
        <div className='w-screen overflow-x-scroll overflow-y-hidden scrollbar-hide md:overflow-hidden'>
            <div className='w-full animate-marquee' ref={ref} onTouchStart={removeAnimation} onTouchEnd={addAnimation}>
                <div className='flex'>
                    {
                        images.map((img) => {
                            return (
                                <figure key={v4()} className='w-full'>
                                    {
                                        img.size === 'big' ?
                                            <div className='w-[370px] sm:w-[460px] mx-3'>
                                                <img src={img.src} alt={img.alt} loading="lazy" className='object-cover rounded-xl h-[358px]' />
                                            </div> :
                                            <div className='w-[244px] flex flex-col'>
                                                <section className=''>
                                                    <img src={img.src} alt={img.alt} loading="lazy" className='object-cover mb-2 rounded-xl aspect-auto w-full h-[175px] ' />
                                                    <img src={img.src2} alt={img.alt} loading="lazy" className='object-cover mt-2 rounded-xl aspect-auto w-full h-[175px] ' />
                                                </section>
                                            </div>
                                    }
                                </figure>
                            )

                        })
                    }
                </div>
            </div>
        </div >
    );
};

export default MarqueeGallery;