export const getAppToolsItems = () => {
    const data = {
        ios: {
            title: 'IOS',
            items: [
                'swift',
                'objective-c',
                'X-code',
            ]
        },
        android: {
            title: 'android',
            items: [
                'java',
                'kotlin',
                'android studio',
            ]
        },
        crossPlatform: {
            title: 'cross platform',
            items: [
                'flutter',
                'react native',
                'lonic',
                'javaScript',
                'HTML5',
            ]
        },
        database: {
            title: 'database',
            items: [
                'firebase',
                'redis',
                'postgreSQL',
                'mongoDB',
                'mySQL',
                'dynamocDB',
                'oracle',
                'SQLite',
            ]
        },
        ui_ux: {
            title: 'UI/UX',
            items: [
                'sketch',
                'adobe XD',
                'photoshop',
                'illustrator',
                'figma',
                'after effects',
                'inVision',
                'zeplin',
            ]
        },
    }
    return data;
}