import React from 'react';

const Circles = () => {
    return (
        <div className='absolute -right-20 w-max md:-translate-y-[26rem] md:-right-80'>
            <div className='relative circle-animation-pos w-72 h-72 md:w-[800px] md:h-[800px] border-2 border-secondary-500'>
                <span className='circle-animation-pos w-2 h-2 md:w-4 md:h-4 bg-secondary-500 overflow-hidden animate-circle-spin1'>
                </span>
            </div>
            <div className='circle-animation-pos w-52 h-52 md:w-[550px] md:h-[550px] border-2 border-secondary-500'>
                <span className='circle-animation-pos w-2 h-2 md:w-4 md:h-4 bg-secondary-500 overflow-hidden animate-circle-spin2'>
                </span>
            </div>
            <div className='circle-animation-pos w-32 h-32 md:w-[300px] md:h-[300px] border-2 border-secondary-500'>
                <span className='circle-animation-pos w-2 h-2 md:w-4 md:h-4 bg-secondary-500 overflow-hidden animate-circle-spin3'>
                </span>
            </div>
        </div>
    );
};

export default Circles;