export const getProductServiceData = () => {
    const data = {
        title: "End-to-End Mobile App ,dApp Development Services for iOS, Android and Cross-platform",
        description: "We have been providing world-class mobile app development services for Startups, and enterprises since 2020. Our highly skilled mobile app developers will work on transforming your app idea into an innovative and secure mobile application. Whether you require native mobile app or cross- platform apps, our superior mobile app development services are designed to deliver cutting-edge mobility solutions tailored to your business needs.As a leading mobile app development company in UK and Armenia, FLLTECH offers a complete range of mobile application development services to the clients worldwide:",
        items: [
            "Mobile App UI/UX Design",
            "Native App Development",
            "dApp Development",
            "Cross-platform App Development",
            "Progressive Web App Development",
            "Enterprise Mobility Solutions",
            "Wearable App Development",
            "IoT App Development",
            "Maintenance and Support",
        ]
    }

    return data;
};