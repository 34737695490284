import React from 'react';
import { Helmet } from 'react-helmet';

import Title from '../components/shared/Title';

// Images
import joystickImg from '../assets/imgs/game/full-joystick.webp';
import ccexpressImg from '../assets/imgs/blockchain/ccexpress.webp';

const PageNotFound404 = () => {
    return (
        <>
            <Helmet>
                <title>
                    FLLTECH | Page Not Found
                </title>
                <meta name='description' content='FLLTECH | The page you are looking for not found.' />
            </Helmet>
            <main className='overflow-hidden flex justify-center'>
                <section className='content-section max-w-7xl justify-center min-h-[80vh]'>
                    <img className='hidden xl:block w-[200px] absolute left-20 top-1/2' src={ccexpressImg} alt='express' />
                    <img className='hidden xl:block w-[200px] absolute right-20 top-0 rotate-12' src={joystickImg} alt='joystick' />
                    <Title className='w-full text-center text-[92px] md:text-[244px] xl:text-[344px] leading-[0.9]' type='stroke' >
                        404
                    </Title>
                    <Title className='text-center' type='normal'>
                        The page you are looking for not found.
                    </Title>
                </section >
            </main >
        </>
    );
};

export default PageNotFound404;