// Images
import coinImg from "../../assets/imgs/blockchain/coin.webp";
import roomImg from "../../assets/imgs/blockchain/room.webp";
import dappsImg from "../../assets/imgs/blockchain/dAPPs.webp";
import listingImg from "../../assets/imgs/blockchain/listing.webp";
import metaverseImg from "../../assets/imgs/blockchain/metaverse.webp";
import web3Img from "../../assets/imgs/blockchain/web3.webp";

export const cardCoinIdea = () => {
    const data = [
        {
            'title': 'Coin, Tokens, and NFTs',
            'img': coinImg,
            'description': 'FLLTech creates either a crypto coin or a token and assists you in developing your own cryptocurrency. We define customized properties for your token, develop smart contracts, run QA on a test chain and deploy to the blockchain. Our technical experts support you continuously from ideation to development.'
        },
        {
            'title': 'Idea & Storyline',
            'img': roomImg,
            'description': 'Our world is shaped by those who innovate leading products. Nowadays, a successful business is significantly tied to presenting robust solutions, new ideas, and creating a solid story. FLLTech creates customized solutions for clients and helps innovate new digital products, markets, and services. Ideation is the essential part of our everyday work, which encompasses the conception of an idea, and leads to innovation, then to development, and finally to actualization.'
        },
    ]
    return data
}

export const cardCoinIdea2 = () => {
    const data = [
        {
            'title': 'Web 3.0 Services',
            'img': web3Img,
            'description': "Are you tired of the limitations and centralization of the current internet? Look no further than our Web3 Services, the cutting-edge platform that utilizes blockchain technology to offer a more decentralized and user-centric online experience. With our Web3 Services, you can enjoy a range of exciting features and benefits that simply aren't available on traditional websites. Our platforms offer greater security and privacy thanks to the immutable nature of blockchain technology. Plus, you'll have more control over your data and online identity without the need for third-party intermediaries. But that's not all - Web3 Services also offers exciting new opportunities for creators and entrepreneurs. Our decentralized marketplace allows for peer-to-peer transactions and smart contracts."
        },
        {
            'title': 'Metaverse',
            'img': metaverseImg,
            'description': 'Since metaverse is a game-changing technology that allows individuals to engage with digital content in an immersive and interactive way, we offer you our Metaverse solutions that let you enter a virtual world that blends seamlessly with the physical world, offering unparalleled opportunities for collaboration, learning, and entertainment. Our Metaverse solutions provide a range of benefits for individuals, businesses, and organizations alike. They offer new and exciting ways to engage with customers, enhance brand awareness, and create unforgettable experiences. Our solutions are also perfect for training, education, and team-building, allowing participants to work together in a virtual environment that feels just like the real thing.'
        },
    ]
    return data
}

export const cardDappsListing = () => {
    const data = [
        {
            'title': 'dAPPs',
            'img': dappsImg,
            'description': 'DAPPs (decentralized applications) are open-source applications that interact with smart contracts that run on a peer-to-peer network of servers. These applications can be based on various blockchain platforms such as Ethereum, EOS, Hyperledger, Polkadot, etc. One of the most challenging hits to a stagnant financial sector was the one made by Blockchain technology is changing the way payments are processed on a larger scale. Although most transactions are under the authority of centralized financial institutions, blockchain developers are working on creating dAPPs that enable cross-border payments straightforwardly without the unnecessary interference of big finance names. Thanks to the technology of smart contracts, worldwide industries can process payments via blockchain-powered financial applications. From token integration to frictionless UI/UX design, FLLTech provides end-to-end dAPPs development services powered by a diligent team of blockchain experts, full-stack developers, and UI/UX designers.'
        },
        {
            'title': 'listing',
            'img': listingImg,
            'description': 'Our world is shaped by those who innovate leading products. Nowadays, a successful business is significantly tied to presenting robust solutions, new ideas, and creating a solid story. FLLTech creates customized solutions for clients and helps innovate new digital products, markets, and services. Ideation is the essential part of our everyday work, which encompasses the conception of an idea, and leads to innovation, then to development, and finally to actualization.'
        },
    ]
    return data
}