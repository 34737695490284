import React from 'react';

// Components
import Title from '../shared/Title';
import Logos from '../common/Logos';
import ServiceToolsItem from '../common/service-tools/ServiceToolItem';

// Constants
import { getDesignToolsItems } from '../../constant/design/designToolsItemsData';

const DesignTools = () => {

    const designToolsItems = getDesignToolsItems();
    const {
        uiux,
        prototyping,
        motionGraphic,
        collaboration,
        illustrator,
        icons,
    } = designToolsItems;

    console.log(designToolsItems.length);
    return (
        <div className='content-section'>
            <div className='w-full max-w-5xl'>
                <Title type='golden' size='xs'>
                    DESIGNING
                </Title>
                <Title type='normal' size='sm'>
                    Tools we use
                </Title>
                <section className='w-full flex flex-col sm:flex-row sm:flex-wrap sm:justify-between gap-8 gap-x-12 my-10'>
                    <ServiceToolsItem data={uiux} />
                    <ServiceToolsItem data={prototyping} />
                    <div className='space-y-6'>
                        <ServiceToolsItem data={motionGraphic} />
                        <ServiceToolsItem data={collaboration} />
                    </div>
                    <ServiceToolsItem data={illustrator} />
                </section>
                <Logos logosList={icons} />
            </div>
        </div>
    );
};

export default DesignTools;