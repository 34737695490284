import React from 'react';
import { Link } from 'react-router-dom';

// Components
import Button from '../shared/Button';
import Title from '../shared/Title';
import CarouselTabs from '../tabComponent/CarouselTabs';

// Constant
import { serviceData } from '../../constant/serviceData';

// Icons
import arrowRight from '../../assets/icons/arrow-right.svg'


const ServicesSection = () => {

    const data = serviceData();

    return (
        <>
            <div className='content-section'>
                <div className='relative w-full max-w-5xl h-auto flex flex-col items-center gap-5 bg-neutral-dark-600 p-6 rounded-lg'>
                    <CarouselTabs contentsList={data} />
                    <Title type='golden' size='xs' className='absolute -top-9 left-0'>Services</Title>
                </div>
                <Link to='/services'>
                    <Button className="mt-10" Btype='basic' size='md'>
                        All Services
                        <img className='w-4' src={arrowRight} alt='arrow right' />
                    </Button>
                </Link>
            </div>
        </>
    );
};

export default ServicesSection;