import React from 'react';

// Components
import Title from '../shared/Title';
import Image from '../shared/Image';
import ProvidedItemsToHelp from './ProvidedItemsToHelp';

// Images
import tabletImg from '../../assets/imgs/design/devices/tablet.webp';
import mobileImg from '../../assets/imgs/design/devices/mobile.webp';
import laptopImg from '../../assets/imgs/design/devices/laptop.webp';
import watchImg from '../../assets/imgs/design/devices/watch.webp';
import blurEllipse from '../../assets/imgs/blurEllipseLeft.svg';

const Help = () => {

    const itemsNotProvided = [
        'Template design',
        'Outsourcing of the work',
        'Compromised quality',
        'Endless revisions',
        'Extended timeline',
        'Late delivery'
    ]
    const itemsProvided = [
        'Custom-tailored design',
        'Creative solutions',
        'Quality over quantity',
        'Streamlined process',
        'Transparent collaboration',
        'Delivery on time'
    ]

    return (
        <div className='content-section'>
            <div className='w-full max-w-5xl'>
                <div className='relative flex flex-col items-start md:flex-row gap-14 z-10'>
                    <section className='flex flex-col sticky top-24'>
                        <Title className='text-[136px] -space-y-20' type='stroke'>
                            <p>we</p>
                            <p>can</p>
                            <p>help!</p>
                        </Title>
                        <div className='flex items-center gap-x-4'>
                            <Image className='flex-1' src={laptopImg} alt='laptop' />
                            <Image className='flex-1' src={mobileImg} alt='mobile' />
                            <Image className='flex-1' src={tabletImg} alt='tablet' />
                            <Image className='flex-1' src={watchImg} alt='watch' />
                        </div>
                    </section>
                    <section className='flex flex-col gap-y-8'>
                        <Title type="normal" size="md" >
                            Is your product boring?
                        </Title>
                        <Title type="normal" size="md" >
                            Is your product hard to use?
                        </Title>
                        <Title type="normal" size="md" >
                            Can’t entertain your users?
                        </Title>
                        <Title type="normal" size="md" >
                            Can’t entertain your users?
                        </Title>
                        <Title type="normal" size="md" >
                            Does your product face high bounce rate?
                        </Title>
                        <Title type="normal" size="md" >
                            Is your user’s productivity low?
                        </Title>
                    </section>
                </div>
                <div className='flex flex-col items-center md:flex-row md:-space-x-8 justify-center mt-20'>
                    <img className='absolute bottom-1/4 -left-0 hidden lg:block' src={blurEllipse} alt='ellipse' />
                    <ProvidedItemsToHelp className='flex-1' title='don`t expect' items={itemsNotProvided} isProvided={false} />
                    <ProvidedItemsToHelp className='flex-1 mt-8 md:mt-16' title='what to expect' items={itemsProvided} isProvided />
                </div>
            </div>
        </div>
    );
};

export default Help;