import React from 'react';
import { v4 } from 'uuid';
import Title from '../shared/Title';
import PortfolioCard from './PortfolioCard';

const Portfolios = ({ items, title, subtitle, bgGradeint, marginSide = 'left', pattern }) => {
    return (
        <main className={`overflow-hidden relative w-full flex justify-center mb-64 ${bgGradeint}`}>
            <img className='hidden md:block absolute top-64 -right-0' src={pattern} alt='' />
            <img className='hidden md:block absolute top-[36.66666%] -left-0 -scale-x-100' src={pattern} alt='' />
            <img className='hidden md:block absolute top-[71.11111%] -right-0' src={pattern} alt='' />
            <div className='w-full max-w-6xl'>
                <div className='w-fit p-6'>
                    <Title className='shadow-white text-2xl sm:text-6xl drop-shadow-[0_0px_8px_rgba(255,255,255,0.25)]' type='normal' headTagType='h2'>
                        {title}
                    </Title>
                    <Title className='text-5xl md:text-8xl' type='stroke' headTagType='h2'>
                        {subtitle}
                    </Title>
                </div>
                <div className='px-8 grid grid-cols-1 sm:grid-cols-2 md:justify-between gap-12 place-items-center'>
                    {
                        items.map(item => {
                            return <PortfolioCard key={v4()} content={item} marginSide={marginSide} />
                        })
                    }
                </div>
            </div>
        </main>
    );
};

export default Portfolios;